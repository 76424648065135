//hooks
import { useNavigate } from 'react-router-dom';

//language
import useLanguageLayout from '../../../../language/useLanguageLayout';

//components
import LoginContainer from './LoginContainer';
import ShowCard from '../../../../components/ShowCard/ShowCard';

//utils
import {
  image_explore_myndfull,
  image_translations,
} from '../../../../assets/images';

const LoginDesktop = () => {
  //hooks
  const navigate = useNavigate();

  //language
  const { LandingPageShowCards } = useLanguageLayout();

  function handleTranslationsShowCard() {
    navigate('/tr');
  }

  function handleExploreShowCard() {
    navigate('/explore');
  }

  return (
    <div className="flex-row full-width flex-center mrg-auto-lr mrg-b24">
      <ShowCard
        mainText={LandingPageShowCards.translations}
        imageAlt={LandingPageShowCards.translationsImgAlt}
        showImage={image_translations}
        customLayoutCardWrapper={'landing-show-card show-card--left'}
        customAnimationStart={'show-card--left--animationStart'}
        customAnimationReverse={'show-card--left--animationReverse'}
        customMainShowImage={'landing-show-card-main-image'}
        customMainText={'mrg-t24 mrg-b6'}
        handler={handleTranslationsShowCard}
      />
      <LoginContainer />
      <ShowCard
        mainText={LandingPageShowCards.explore}
        imageAlt={LandingPageShowCards.exploreImgAlt}
        showImage={image_explore_myndfull}
        customLayoutCardWrapper={'landing-show-card show-card--right'}
        customAnimationStart={'show-card--right--animationStart'}
        customMainText={'mrg-t24'}
        customAnimationReverse={'show-card--right--animationReverse'}
        customMainShowImage={'landing-show-card-main-image'}
        handler={handleExploreShowCard}
      />
    </div>
  );
};

export default LoginDesktop;
