import { useEffect } from 'react';
//components
import ScrollToTop from '../../../components/ScrollToTop/ScrollToTop';

//utils
import ScrollTo from '../../../utils/UI/ScrollTo';

const PublicTerms = () => {
  useEffect(() => {
    ScrollTo({ scrollToTop: true, focusId: 'termsOfService' });
  }, []); //auto scroll to top

  return (
    <div className="public__wrapper public-font bg-cream">
      <h1 tabIndex="0" className="access-ob access-o6" id="termsOfService">
        Terms of Service
      </h1>

      <p tabIndex="0" className="access-ob access-o6">
        Last updated on: April 3, 2025
      </p>

      <p tabIndex="0" className="access-ob access-o6">
        These Terms of Service (<span className="fwb">“Terms”</span>) govern
        your access to and use of our services, including our various websites,
        SMS, APIs, email notifications, applications, buttons, widgets, ads,
        commerce services that link to these Terms (collectively, the{' '}
        <span className="fwb">“Services”</span>), and any information, text,
        links, graphics, photos, audio, videos, or other materials or
        arrangements of materials uploaded, downloaded or appearing on the
        Services (collectively referred to as{' '}
        <span className="fwb">“Content”</span>). By using the Services you agree
        to be bound by these Terms.
      </p>

      <p tabIndex="0" className="access-ob access-o6">
        These Terms are an agreement between you and MyndFull Platforms Inc. The
        words “MyndFull”, “we,” “us,” and “our” mean MyndFull Platforms Inc.{' '}
      </p>

      <ul className="numbered-list--large">
        <li>
          <h1 tabIndex="0" className="access-ob access-o6">
            Who May Use the Services{' '}
          </h1>
          <p tabIndex="0" className="access-ob access-o6">
            Only residents of Canada are eligible to register for a MyndFull
            account. You may use the Services only if you agree to form a
            binding contract with us and are not a person barred from receiving
            services under the laws of the applicable jurisdiction. In any case,
            you must be at least 16 years old to use the services. If you are
            accepting these Terms and using the Services on behalf of a company,
            organization, government, or other legal entity, you represent and
            warrant that you are authorized to do so and have the authority to
            bind such entity to these Terms, in which case the words “you” and
            “your” as used in these terms shall refer to such entity.{' '}
          </p>
        </li>

        <li>
          <h1 tabIndex="0" className="access-ob access-o6">
            Privacy
          </h1>
          <p tabIndex="0" className="access-ob access-o6">
            <a href="https://www.myndfull.com/privacy">Our Privacy Policy</a>{' '}
            describes how we handle the information you provide to us when you
            use our Services. You understand that through your use of the
            Services you consent to the collection and use (as set forth in the
            Privacy Policy) of this information, including the transfer of this
            information to the United States and/or other countries for storage,
            processing and use by us.{' '}
          </p>
        </li>

        <li>
          <h1 tabIndex="0" className="access-ob access-o6">
            Content on the Services
          </h1>
          <p tabIndex="0" className="access-ob access-o6">
            You are responsible for your use of the Services and for any Content
            you provide, including compliance with applicable laws, rules, and
            regulations. You should only provide Content that you are
            comfortable sharing with others.
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            Any use or reliance on any Content or materials posted via the
            Services or obtained by you through the Services is at your own
            risk. We do not endorse, support, represent or guarantee the
            completeness, truthfulness, accuracy, or reliability of any Content
            or communications posted via the Services or endorse any opinions
            expressed via the Services. You understand that by using the
            Services, you may be exposed to Content that might be offensive,
            harmful, inaccurate or otherwise inappropriate. All Content is the
            sole responsibility of the person who originated such Content. We
            may not monitor or control the Content posted via the Services and
            we cannot take responsibility for such Content.
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            You may not transmit any Content that is unlawful, offensive,
            upsetting, intended to disgust, threatening, libelous, defamatory,
            obscene or otherwise objectionable. Examples of such objectionable
            Content include, but are not limited to, the following:{' '}
          </p>
          <ul className="list-reg">
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                unlawful or promoting unlawful activity;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                defamatory, discriminatory, or mean-spirited content;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                spam, machine, or randomly generated, constituting unauthorized
                or unsolicited advertising, chain letters, any other form of
                unauthorized solicitation, or any form of lottery or gambling;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                containing or installing any viruses, worms, malware, trojan
                horses, or other content that is designed or intended to
                disrupt, damage, or limit the functioning of any software,
                hardware or telecommunications equipment or to damage or obtain
                unauthorized access to any data or other information of a third
                person;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                infringing on any proprietary rights of any party, including
                patent, trademark, trade secret, copyright, right of publicity
                or other rights;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                impersonating any person or entity including MyndFull and its
                employees or representatives;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                violating the privacy of any third person; and
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                false information and features.
              </p>
            </li>
          </ul>
          <p tabIndex="0" className="access-ob access-o6">
            We have the right to remove Content that violates the Terms.
          </p>

          <h3 tabIndex="0" className="access-ob access-o6">
            Your Rights and Grant of Rights in the Content
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            You retain your rights to any Content you submit, post or display on
            or through the Services.
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            You represent and warrant that you have, or have obtained, all
            rights, licenses, consents, permissions, power and/or authority
            necessary to grant the rights granted herein for any Content that
            you submit, post or display on or through the Services. You agree
            that such Content will not contain material subject to copyright or
            other proprietary rights, unless you have necessary permission or
            are otherwise legally entitled to post the material and to grant us
            the license described above.
          </p>
        </li>

        <li>
          <h1 tabIndex="0" className="access-ob access-o6">
            Privacy
          </h1>
          <p tabIndex="0" className="access-ob access-o6">
            You may use the Services only in compliance with these Terms and all
            applicable laws, rules and regulations. The Services may change from
            time to time, at our discretion. We may stop (permanently or
            temporarily) providing the Services or any features within the
            Services to you or to users generally. We also retain the right to
            create limits on use and storage at our sole discretion at any time.
            We may also remove or refuse to distribute any Content on the
            Services, limit distribution or visibility of any Content on the
            service, and suspend or terminate users without liability to you.
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            In consideration for our granting you access to and use of the
            Services, you agree that we and our third-party providers and
            partners may place advertising on the Services or in connection with
            the display of Content or information from the Services whether
            submitted by you or others. You also agree not to misuse our
            Services, for example, by interfering with them or accessing them
            using a method other than the interface and the instructions that we
            provide. You agree that you will not work around any technical
            limitations in the software provided to you as part of the Services,
            or reverse engineer, decompile or disassemble the software, except
            and only to the extent that applicable law expressly permits. You
            may not do any of the following while accessing or using the
            Services:{' '}
          </p>
          <ul className="list-roman">
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                access, tamper with, or use non-public areas of the Services,
                our computer systems, or the technical delivery systems of our
                providers;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                probe, scan, or test the vulnerability of any system or network
                or breach or circumvent any security or authentication measures;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                access or search or attempt to access or search the Services by
                any means (automated or otherwise) other than through our
                currently available, published interfaces that are provided by
                us (and only pursuant to the applicable terms and conditions),
                unless you have been specifically allowed to do so in a separate
                agreement with us;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                forge any TCP/IP packet header or any part of the header
                information in any email or posting, or in any way use the
                Services to send altered, deceptive or false source-identifying
                information; or
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                interfere with, or disrupt, (or attempt to do so), the access of
                any user, host or network, including, without limitation,
                sending a virus, overloading, flooding, spamming, mail-bombing
                the Services, or by scripting the creation of Content in such a
                manner as to interfere with or create an undue burden on the
                Services.
              </p>
            </li>
          </ul>
          <p tabIndex="0" className="access-ob access-o6">
            We also reserve the right to access, read, preserve, and disclose
            any information as we reasonably believe is necessary to:
          </p>
          <ul className="list-roman">
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                satisfy any applicable law, regulation, legal process or
                governmental request;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                enforce the Terms, including investigation of potential
                violations hereof;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                detect, prevent, or otherwise address fraud, security or
                technical issues;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                respond to user support requests; or
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                protect the rights, property or safety of MyndFull, its users
                and the public.{' '}
              </p>
            </li>
          </ul>
          <p tabIndex="0" className="access-ob access-o6">
            We do not disclose personally-identifying information to third
            parties except in accordance with our{' '}
            <a href="https:// myndfull.com/privacy">Privacy Policy</a>
          </p>

          <h3 tabIndex="0" className="access-ob access-o6">
            Your Account
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            You may need to create an account to use some of our Services. You
            are responsible for safeguarding your account, so use a strong
            password and limit its use to this account. We cannot and will not
            be liable for any loss or damage arising from your failure to comply
            with the above.
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            You can control most communications from the Services. We may need
            to provide you with certain communications, such as service
            announcements and administrative messages. These communications are
            considered part of the Services and your account, and you may not be
            able to opt-out from receiving them. If you added your phone number
            to your account and you later change or deactivate that phone
            number, you must update your account information to help prevent us
            from communicating with anyone who acquires your old number.
          </p>

          <h3 tabIndex="0" className="access-ob access-o6">
            Your License to Use the Services
          </h3>

          <p tabIndex="0" className="access-ob access-o6">
            We give you a personal, worldwide, royalty-free, non-assignable and
            non-exclusive license to use the software provided to you as part of
            the Services. This license has the sole purpose of enabling you to
            use and enjoy the benefit of the Services as provided on MyndFull,
            in the manner permitted by these Terms.
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            The Services are protected by copyright, trademark, and other laws
            of both Canada and other countries. Nothing in the Terms gives you a
            right to use the MyndFull name or any of the MyndFull trademarks,
            logos, domain names, other distinctive brand features, and other
            proprietary rights. All right, title, and interest in and to the
            Services (excluding Content provided by users) are and will remain
            our and our licensors' exclusive property. Any feedback, comments,
            or suggestions you may provide regarding MyndFull, or the Services
            is entirely voluntary and we will be free to use such feedback,
            comments or suggestions as we see fit and without any obligation to
            you.
          </p>

          <h3 tabIndex="0" className="access-ob access-o6">
            Ending These Terms
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            You may end your legal agreement with us at any time by deactivating
            your accounts and discontinuing your use of the Services.{' '}
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            We may suspend or terminate your account or cease providing you with
            all or part of the Services at any time for any or no reason,
            including, but not limited to, if we reasonably believe:{' '}
          </p>
          <ul className="list-roman">
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                you have violated these Terms;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                you create risk or possible legal exposure for us;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                your account should be removed due to unlawful conduct;{' '}
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                your account should be removed due to prolonged inactivity; or
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                our provision of the Services to you is no longer commercially
                viable. We will make reasonable efforts to notify you by the
                email address associated with your account or the next time you
                attempt to access your account, depending on the circumstances.
              </p>
            </li>
          </ul>
          <p tabIndex="0" className="access-ob access-o6">
            In all such cases, the Terms shall terminate, including, without
            limitation, your license to use the Services, except that the
            following sections shall continue to apply: 2, 3, 5, 6, 7, 8 and 9.
            For the avoidance of doubt, these Terms survive the deactivation or
            termination of your account.
          </p>
        </li>
        <li>
          <h1 tabIndex="0" className="access-ob access-o6">
            Service-Specific Terms
          </h1>
          <p tabIndex="0" className="access-ob access-o6">
            The terms before and after this section 5 apply generally to all
            Services. This section contains service-specific terms that apply in
            addition to the general terms. If there is any conflict with the
            general terms, these service-specific terms will govern.{' '}
          </p>

          <h3 tabIndex="0" className="access-ob access-o6">
            MyndFull Events
          </h3>

          <h4 tabIndex="0" className="access-ob access-o6">
            Prohibited Events
          </h4>
          <p tabIndex="0" className="access-ob access-o6">
            You may not use the Services to organize, promote, or host any
            events that take place on public property. This includes, but is not
            limited to, parks, sidewalks, streets, plazas, or any other spaces
            generally accessible to the public and not designated for private
            gatherings. Hosting or attempting to host such events constitutes a
            violation of the Terms. Events held on private property must be
            authorized by the property owner or have appropriate permission
            based on the context of use. We reserve the right to remove any
            event listings in violation of this policy and to suspend or
            terminate the accounts responsible, at our sole discretion.{' '}
          </p>

          <h4 tabIndex="0" className="access-ob access-o6">
            Content on the Services
          </h4>
          <p tabIndex="0" className="access-ob access-o6">
            If you believe that your Content has been copied in a way that
            constitutes copyright infringement, please report this by submitting
            a detailed notice in writing to our attention via email
            (copyright@myndfull.com) and include in your notice a detailed
            description of the alleged infringement. This written notice must
            include the following information:
          </p>
          <ul className="list-reg">
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                an electronic or physical signature of the person authorized to
                act on behalf of the owner of the copyright's interest;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                a description of the copyrighted work that you claim has been
                infringed, including the URL (i.e., web page address) of the
                location where the copyrighted work exists or a copy of the
                copyrighted work;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                identification of the URL or other specific location on the
                Service where the material that you claim is infringing is
                located;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                your address, telephone number, and email address;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                a statement by you that you have a good faith belief that the
                disputed use is not authorized by the copyright owner, its
                agent, or the law; and
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                a statement by you, made under penalty of perjury, that the
                above information in your notice is accurate and that you are
                the copyright owner or authorized to act on the copyright
                owner's behalf.
              </p>
            </li>
          </ul>
          <p tabIndex="0" className="access-ob access-o6">
            You may be held accountable for damages (including costs and
            attorneys' fees) for misrepresenting that any Content is infringing
            your copyright.
          </p>
          <h4 tabIndex="0" className="access-ob access-o6">
            Your Rights and Grant of Rights in the Content
          </h4>
          <p tabIndex="0" className="access-ob access-o6">
            By submitting, posting or displaying Content on or through the
            Services, you grant us a worldwide, non-exclusive, royalty-free
            license (with the right to sublicense) to use, copy, reproduce,
            process, adapt, modify, publish, transmit, display and distribute
            such Content in any and all media or distribution methods now known
            or later developed (for clarity, these rights include, for example,
            curating, transforming, and translating). This license authorizes us
            to make your Content available to the rest of the world and to let
            others do the same. You agree that this license includes the right
            for us to provide, promote, and improve the Services and to make
            Content submitted to or through the Services available to other
            companies, organizations or individuals for the syndication,
            broadcast, distribution, promotion or publication of such Content on
            other media and services, subject to our terms and conditions for
            such Content use. Such additional uses by us, or other companies,
            organizations or individuals, is made with no compensation paid to
            you with respect to the Content that you submit, post, transmit or
            otherwise make available through the Services as the use of the
            Services by you is hereby agreed as being sufficient compensation
            for the Content and grant of rights herein.
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            We have an evolving set of rules for how ecosystem partners can
            interact with your Content on the Services. These rules exist to
            enable an open ecosystem with your rights in mind. You understand
            that we may modify or adapt your Content as it is distributed,
            syndicated, published, or broadcast by us and our partners and/or
            make changes to your Content in order to adapt the Content to
            different media.
          </p>
        </li>
        <li>
          <h1 tabIndex="0" className="access-ob access-o6">
            Payment Terms
          </h1>

          <h3 tabIndex="0" className="access-ob access-o6">
            Charges
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            The price for services rendered by us excludes all taxes, levies,
            and duties imposed by relevant taxation authorities. You are
            obligated to make full payment to us by the due date specified on
            the invoice provided. Failure to meet payment obligations may result
            in the suspension or cancellation of services and the suspension or
            termination of your account. In the event of delinquency, you shall
            be responsible for all reasonable expenses, including attorneys'
            fees, incurred by us in the pursuit of collecting amounts owed.
            Payment shall be rendered in the currency specified on the invoice.
            You shall bear all costs associated with credit card, currency
            exchange, and bank fees. Our determination and measurement of your
            use of the Services is final.
          </p>
          <h3 tabIndex="0" className="access-ob access-o6">
            Billing
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            Upon signing up to any of our paid Services, you shall provide a
            credit card as the primary method of payment. You hereby represent
            and warrant that you are authorized to utilize the provided payment
            method and that all payment information supplied to us is complete,
            accurate and truthful. Furthermore, you grant us authorization to
            automatically charge you for Services utilized through the selected
            payment method. It is your responsibility to ensure that all account
            and billing information remains current and is updated promptly
            following any changes. Adjustments to account and billing
            information will not retroactively affect charges made by us prior
            to our action in a reasonable amount of time to reflect such
            changes. Billing may occur at the time of purchase, in advance,
            shortly after purchase, or on a recurring basis for subscriptions.
          </p>
          <h3 tabIndex="0" className="access-ob access-o6">
            Recurring Payments
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            You acknowledge and agree that by subscribing to our Services on a
            subscription basis, you authorize automatic, recurring payments to
            us. To avoid further charges for the Services, subscriptions must be
            cancelled prior to the commencement of the next billing cycle.
            Subscription fees shall be billed in advance of the corresponding
            subscription period.
          </p>
          <h3 tabIndex="0" className="access-ob access-o6">
            Usage Payments
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            Use of some of our Services on a 'pay-as-you-go' basis constitutes
            your authorization for us to automatically bill for these services
            in the subsequent invoice for that billing period.
          </p>
          <h3 tabIndex="0" className="access-ob access-o6">
            Taxes
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            We calculate taxes based on the address associated with your billing
            information, unless otherwise mandated by law. You are responsible
            for the payment of all relevant taxes associated with the Services.
            These taxes will be included in your invoice for payment. You shall
            provide us with any tax identification information that may be
            required under applicable law to ensure compliance with applicable
            tax regulations. You shall bear sole responsibility for any taxes,
            interest, penalties, or fines that arise from a mis-declaration of
            your account and billing information.
          </p>
          <h3 tabIndex="0" className="access-ob access-o6">
            Price Changes
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            We reserve the right to modify the price of the Services at any
            time. Accounts subscribing to the Services on a recurring basis will
            be notified of any price changes via email at least 45 days prior to
            the effective date of such changes. Should you disagree with the
            price changes, you must cancel the Services and cease usage before
            the adjustments take effect. Prices agreed upon for a fixed term
            shall remain in effect for the duration of that term.
          </p>
          <h3 tabIndex="0" className="access-ob access-o6">
            Errors and Refunds
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            All purchases are deemed final and non-refundable, except as
            mandated by law. The issuance of refunds or credits beyond what is
            required by law shall be at our sole discretion. Claims of billing
            errors must be brought to our attention by you within 90 days of the
            charge, otherwise we will be released by you from all liability and
            claims of loss resulting from the charge. All claims of billing
            errors will be promptly investigated, and any billing errors we
            identify will be corrected within 90 days of identification.{' '}
          </p>
          <h3 tabIndex="0" className="access-ob access-o6">
            Cancellation
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            You may cancel the Services at any time, with or without cause. Such
            cancellation will prevent future charges for the continuation of the
            Services but shall not waive your obligation to pay for charges
            incurred prior to cancellation. Following cancellation, the Services
            shall continue until the end of the current billing period.
          </p>
          <h3 tabIndex="0" className="access-ob access-o6">
            Payment Processing Services
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            Our payment processing services are provided by Stripe Payments
            Canada, Ltd. (<span className="fwb">“Stripe”</span>) and are subject
            to the{' '}
            <a href="https://stripe.com/en-ca/legal/ssa">Stripe Services</a>{' '}
            Agreement. By agreeing to the Terms and using the Services, you
            agree to be bound by the Stripe Services Agreement, as may be
            modified from time to time. As a condition of the Terms, you agree
            to provide us accurate and complete information regarding your
            account and billing information, and you authorize us to share it
            and transaction information related to your use of the payment
            processing services provided by Stripe.{' '}
          </p>
        </li>
        <li>
          <h1 tabIndex="0" className="access-ob access-o6">
            Limitations of Liability
          </h1>
          <p tabIndex="0" className="access-ob access-o6">
            To the maximum extent permitted by applicable law, the MyndFull
            Entities shall not be liable for any indirect, incidental, special,
            consequential or punitive damages, or any loss of profits or
            revenues, whether incurred directly or indirectly, or any loss of
            data, use, goodwill, or other intangible losses, resulting from:
          </p>
          <ul className="list-roman">
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                your access to or use of or inability to access or use the
                Services;{' '}
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                any conduct or content of any third party on the Services,
                including without limitation, any defamatory, offensive or
                illegal conduct of other users or third parties;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                any content obtained from the Services; or
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                unauthorized access, use or alteration of your transmissions or
                content.
              </p>
            </li>
          </ul>
          <p tabIndex="0" className="access-ob access-o6">
            In no event shall the aggregate liability of the MyndFull Entities
            exceed the greater of one hundred Canadian dollars (CAD $100.00) or
            the amount you paid us, if any, in the past six months for the
            Services giving rise to the claim. The limitations of this
            subsection shall apply to any theory of liability, whether based on
            warranty, contract, statute, tort (including negligence) or
            otherwise, and whether or not the MyndFull Entities have been
            informed of the possibility of any such damage, and even if a remedy
            set forth herein is found to have failed of its essential purpose.
          </p>
        </li>

        <li>
          <h1 tabIndex="0" className="access-ob access-o6">
            Third-Party Services
          </h1>
          <p tabIndex="0" className="access-ob access-o6">
            Our Services may contain links to third-party websites,
            applications, services, or resources (
            <span className="fwb">“Third-Party Services”</span>) that are
            subject to different terms and privacy practices. We are not
            responsible or liable for any aspect of such Third-Party Services,
            and links to such Third-Party Services are not an endorsement.
          </p>
          <h3 tabIndex="0" className="access-ob access-o6">
            Google Terms
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            Certain features of the Services make use of Google products
            including reCAPTCHA, Google Maps API, Google Translation API and
            Google Text-To-Speech API (collectively, the{' '}
            <span className="fwb">“Google Services”</span>). By using the
            Services, you agree to comply with the terms and conditions
            applicable to the Google Services, as listed below:{' '}
          </p>
          <ul className="list-reg list-shrink--ph">
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                Google terms:{' '}
                <a
                  className="underline"
                  href="https://policies.google.com/terms"
                >
                  https://policies.google.com/terms
                </a>
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                Google privacy policy:{' '}
                <a
                  className="underline"
                  href="https://policies.google.com/privacy"
                >
                  https://policies.google.com/privacy
                </a>
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                Google Maps terms:{' '}
                <a
                  className="underline"
                  href="https://www.google.com/help/terms_maps"
                >
                  https://www.google.com/help/terms_maps
                </a>
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                Google API terms:{' '}
                <a
                  className="underline"
                  href="https://developers.google.com/terms"
                >
                  https://developers.google.com/terms
                </a>
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                Google Cloud terms:{' '}
                <a className="underline" href="https://cloud.google.com/terms">
                  https://cloud.google.com/terms{' '}
                </a>
              </p>
            </li>
          </ul>

          <h3 tabIndex="0" className="access-ob access-o6">
            OpenAI Terms
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            Certain features of the Services make use of OpenAI API (the{' '}
            <span className="fwb">“OpenAI Services”</span>). By using the
            Services, you agree to comply with the terms and conditions
            applicable to the OpenAI Services, as listed below:
          </p>
          <ul className="list-reg">
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                Open AI terms and policies:{' '}
                <a className="underline" href="https://openai.com/policies">
                  https://openai.com/policies
                </a>
              </p>
            </li>
          </ul>
        </li>
        <li>
          <h1 tabIndex="0" className="access-ob access-o6">
            General
          </h1>
          <p tabIndex="0" className="access-ob access-o6">
            We may revise these Terms from time to time. The changes will not be
            retroactive, and the most current version of the Terms, which will
            always be at https://myndfull.com/terms, will govern our
            relationship with you. We will try to notify you of material
            revisions, for example via a service notification or an email to the
            email associated with your account. By continuing to access or use
            the Services after those revisions become effective, you agree to be
            bound by the revised Terms.
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            The laws of the province of Ontario and the federal laws of Canada
            will govern these Terms and any dispute that arises between you and
            us. All disputes related to these Terms or the Services will be
            brought solely in the federal or provincial courts located in
            Toronto, Ontario, and you consent to personal jurisdiction and waive
            any objection as to inconvenient forum.
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            In the event that any provision of these Terms is held to be invalid
            or unenforceable, then that provision will be limited or eliminated
            to the minimum extent necessary, and the remaining provisions of
            these Terms will remain in full force and effect. Our failure to
            enforce any right or provision of these Terms will not be deemed a
            waiver of such right or provision.
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            If you have any questions about these Terms, please contact us at
            support@myndfull.com.
          </p>
        </li>
      </ul>
      <ScrollToTop customWrapper={'mrg-t48 bg-color-white'} />
    </div>
  );
};

export default PublicTerms;
