import { useEffect, useState } from 'react';

//Hooks
import { useApiStatus } from '../../context/ApiStatusProvider';
import { useSettings } from '../../context/SettingsProvider';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//Components
import ItemButton from '../ItemButton/ItemButton';

//Utility
import {
  arrow_circular_hallow,
  cancel,
  checkmark,
  dropdown_arrow,
  edit,
  folder,
  trash,
} from '../../assets/icons';
const TinyTagGroups = ({
  tinyTags,
  setTinyTags,
  tinyTagGroups,
  setTinyTagGroups,
  modifyTagGroup,
  setModifyTagGroup,
  setTinyTagGroupsModifyActive,
  transferTag,
  updateAccountIsSuccess,
}) => {
  //Hooks
  const [openedTagGroups, setOpenedTagGroups] = useState([]);
  const { handleError } = useApiStatus();
  const { handleMobileTap, dismissKeypad } = useSettings();

  //language
  const { CustomTagsList, Generic, IconAlts } = useLanguageComponents();

  //Component State
  const [initialTags, setInitialTags] = useState(tinyTagGroups || []);

  //UI state
  const [tapHighlightTGBorder, setTapHighlightTGBorder] = useState(false);
  const [tapHighlightModify, setTapHighlightModify] = useState();
  const [tapHighlightRemoveTagGroup, setTapHighlightRemoveTagGroup] =
    useState();
  const [tapHighlightTagRemove, setTapHighlightTagRemove] = useState();

  //Component Variables
  let tagsGroupComponent;

  //Initialize functions
  useEffect(() => {
    setInitialTags(tinyTagGroups);
  }, [updateAccountIsSuccess]);

  useEffect(() => {
    if (setTinyTagGroupsModifyActive) {
      if (modifyTagGroup) {
        setTinyTagGroupsModifyActive(true);
      } else {
        setTinyTagGroupsModifyActive(false);
      }
    }
  }, [modifyTagGroup]); // used to prevent account save while modifications are active

  //UI functions
  function handleToggleTagGroupOpenedClick(e, tGid, index) {
    e.stopPropagation();

    handleMobileTap(
      [() => setTapHighlightTGBorder(tGid), () => setTapHighlightTGBorder()],
      [() => handleToggleTagGroupOpened(tGid, index)]
    );
  }

  function handleRemoveTagGroupClick(e, index, tG) {
    e.stopPropagation();

    handleMobileTap(
      [
        () => setTapHighlightRemoveTagGroup(true),
        () => setTapHighlightRemoveTagGroup(false),
      ],
      [() => handleRemoveTagGroup(index, tG)]
    );
  }

  function handleToggleModifyGroupTagStateClick(e, tG) {
    e.stopPropagation();
    handleMobileTap(
      [() => setTapHighlightModify(true), () => setTapHighlightModify(false)],
      [() => toggleModifyGroupTagState(tG)]
    );
  }

  function handleRemoveTagInTagGroupClick(tag, id) {
    handleMobileTap(
      [() => setTapHighlightTagRemove('tag'), () => setTapHighlightTagRemove()],
      [() => handleRemoveTagInTagGroup(tag, id)]
    );
  }

  //Component functions

  function handleToggleTagGroupOpened(tGid, index) {
    if (modifyTagGroup === tGid) {
      return null;
    }

    if (openedTagGroups.includes(index)) {
      let newOpenedTagGroups = [...openedTagGroups].filter((i) => i !== index);
      setOpenedTagGroups(newOpenedTagGroups);
    } else {
      let newOpenedTagGroups = [...openedTagGroups, index];
      setOpenedTagGroups(newOpenedTagGroups);
    }
  }

  //functions
  function handleCreateTagGroup() {
    setTinyTagGroups([
      ...tinyTagGroups,
      {
        id: Date.now(),
        label: `Group ${tinyTagGroups.length + 1}`,
        tags: [],
      },
    ]);
  }

  function handleRemoveTagGroup(index, tG) {
    if (!modifyTagGroup) {
      const newTagGroup = [...tinyTagGroups];
      const returnTags = tG.tags;
      const oldArr = [...tinyTags];
      let newArr = oldArr.concat(returnTags);
      if (setTinyTags) {
        setTinyTags(newArr);
      }

      newTagGroup.splice(index, 1);
      setTinyTagGroups(newTagGroup);
    }
  }

  function toggleModifyGroupTagState(tG) {
    if (modifyTagGroup === tG.id) {
      //set autogen group name if finishing group tag modify
      let tGLabel = tG.label.trim();
      if (!tGLabel) {
        let autoGen = `Group ${tinyTagGroups.length}`;
        handleTagGroupLabelChange(autoGen, tG.id);
      }
      if (tG.label.length > 25) {
        return handleError(
          {
            message: 'tinyTagGroupsLabelLimit',
          },
          'TinyTagGroups/toggleModifyGroupTagState'
        );
      }
      setModifyTagGroup();
      document.getElementById(`tag-group-${tG.id}`).focus();
    } else if (modifyTagGroup) {
      return null;
    } else {
      setModifyTagGroup(tG.id);
    }
  }

  function handleTagGroupLabelChange(value, tGid) {
    const newTagGroup = [...tinyTagGroups];
    const adjustedTagGroup = newTagGroup?.map((tG) => {
      if (tGid === tG.id) {
        return {
          ...tG,
          label: value,
        };
      } else {
        return tG;
      }
    });
    setTinyTagGroups(adjustedTagGroup);
  }

  function handleRemoveTagInTagGroup(tag, id) {
    if (modifyTagGroup) {
      const newTagGroup = [...tinyTagGroups];
      const adjustedTagGroup = newTagGroup?.map((tG) => {
        if (id === tG.id) {
          const updatedTags = tG.tags.filter((t) => t !== tag);
          return {
            ...tG,
            tags: updatedTags,
          };
        } else {
          return tG;
        }
      });

      transferTag(tag);
      setTinyTagGroups(adjustedTagGroup);
    }
  }

  //JSX Conditional
  if (tinyTagGroups) {
    tagsGroupComponent = (
      <>
        {/* Tag Groups Section */}
        <div className="flex-row full-width mrg-tb24">
          <ItemButton
            fullWidth={true}
            handler={handleCreateTagGroup}
            text={CustomTagsList.tinyTagGroup}
            addText={true}
            id={'tG-add-btn'}
          />
        </div>
        <ul className="tg full-width ">
          {tinyTagGroups
            ?.map((tG, index) => {
              return (
                <li
                  key={`${tG.id}-${index}`}
                  role="button"
                  className={`tg__wrapper highlight-bthin-theme access-ob access-o6 ${
                    modifyTagGroup === tG.id ? 'tg__wrapper--active' : ''
                  } 
                  ${
                    tapHighlightTGBorder === tG.id
                      ? 'highlight-b-theme--tap'
                      : ''
                  }
                  `}
                  onClick={(e) =>
                    handleToggleTagGroupOpenedClick(e, tG.id, index)
                  }
                  onKeyDown={(e) =>
                    e.key === 'Enter' &&
                    handleToggleTagGroupOpenedClick(e, tG.id, index)
                  }
                  id={`tag-group-${tG.id}`}
                  tabIndex="0"
                  aria-label={`${CustomTagsList.tinyTagGroup}. ${tG.label}. ${tG.tags?.length} ${Generic.tags}.`}
                  aria-expanded={openedTagGroups.includes(index)}
                >
                  <div
                    className={`tg-heading ${
                      modifyTagGroup === tG.id ? 'tg-heading--active' : ''
                    } 
                    ${
                      openedTagGroups.includes(index)
                        ? 'tg-heading--opened mrg-b12'
                        : 'tg-heading--inactive'
                    }
                    `}
                  >
                    {modifyTagGroup === tG.id ? (
                      <label
                        htmlFor={`tg-input-${index}`}
                        className="fs18 fwn mrg-r12 no-wrap align-center"
                      >
                        {CustomTagsList.tagGroup}
                        <span className="color-theme--bold">*</span>
                        &nbsp;:
                      </label>
                    ) : (
                      <div className="flex-row">
                        <img
                          alt={IconAlts.iconFolder}
                          src={folder}
                          className="filter-theme svg"
                        />
                        <p className="group-name-ph fs18 fwsb">{tG.label}</p>
                      </div>
                    )}

                    {modifyTagGroup === tG.id ? (
                      <>
                        <input
                          type="text"
                          id={`tg-input-${index}`}
                          className="fs18"
                          onChange={(e) =>
                            handleTagGroupLabelChange(e.target.value, tG.id)
                          }
                          onKeyDown={(e) => dismissKeypad(e)}
                          value={tG.label}
                          maxLength="25"
                          enterKeyHint="done"
                        />
                        <p className="fs14 fwn">{tG.label.length}/25</p>
                      </>
                    ) : (
                      <div className="title-tags-row">
                        <p className="group-name fs18 fwsb">{tG.label}</p>
                        <div className="flex-row space-between--ph full-width--ph">
                          <p className="fs18 fwsb">
                            {tG.tags?.length} {Generic.tags}
                          </p>
                          <img
                            src={dropdown_arrow}
                            alt={IconAlts.iconArrow}
                            className={`heading-arrow svg ${
                              !openedTagGroups.includes(index)
                                ? 'rotate-180'
                                : ''
                            } mrg-t4`}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {/* render tags */}
                  {openedTagGroups.includes(index) && (
                    <div className="tg__container">
                      {modifyTagGroup === tG.id && (
                        <>
                          <div className="h12" />
                          {tG?.tags?.map((tag) => (
                            <button
                              className={`tg__tag highlight-bthin-theme highlight-i-lgr fwsb fs16 fs14-phn no-select access-ob access-o6 ${
                                !initialTags.find((tagGroup) =>
                                  tagGroup.tags.some(
                                    (t) => t === tag && tG.id === tagGroup.id
                                  )
                                )
                                  ? 'new-tag'
                                  : ''
                              } ${
                                tapHighlightTagRemove === tag
                                  ? 'filter-red'
                                  : ''
                              }`}
                              key={`${tag}-${tG.id}`}
                              tabIndex="0"
                              aria-label={`${Generic.remove} ${tag}`}
                              name={index} //keep for removal
                              id={tag}
                              onClick={() =>
                                handleRemoveTagInTagGroupClick(tag, tG.id)
                              }
                            >
                              <p>{tag}</p>
                              {modifyTagGroup && (
                                <img
                                  alt={IconAlts.iconClose}
                                  className="disable-select svg"
                                  src={cancel}
                                />
                              )}
                            </button>
                          ))}
                        </>
                      )}

                      {tinyTags?.length > 0 &&
                        tG.tags?.length === 0 &&
                        modifyTagGroup === tG.id && (
                          <div className="flex-column flex-center mrg-auto-left mrg-auto-right">
                            <img
                              src={arrow_circular_hallow}
                              alt={IconAlts.iconArrow}
                              className="tg-arrow svg"
                            />
                            <p className="fs18 fwn mrg-b12 mrg-auto-lr mrg-t24 text-center">
                              {CustomTagsList.clickTagsToAdd}
                            </p>
                          </div>
                        )}

                      {tinyTags?.length === 0 &&
                        tG.tags?.length === 0 &&
                        modifyTagGroup === tG.id && (
                          <div className="flex-column flex-center mrg-auto-left mrg-auto-right">
                            <img
                              src={arrow_circular_hallow}
                              alt={IconAlts.iconArrow}
                              className="tg-arrow svg"
                            />
                            <p className="fs18 fwn mrg-b12 mrg-auto-lr mrg-t24 text-center">
                              {CustomTagsList.createTagsToAdd}
                            </p>
                          </div>
                        )}

                      {modifyTagGroup !== tG.id && (
                        <ul
                          className="tG-summary-tags__container access-ob access-o6"
                          tabIndex={modifyTagGroup?.length > 1 ? '0' : '-1'}
                          aria-label={`${Generic.tags}. ${tG.tags}`}
                        >
                          {tG?.tags?.map((tag) => (
                            <li
                              className="summary-tag fs18 fwn"
                              key={`tG-tag-li-${tag}`}
                            >
                              <p>{tag}</p>
                            </li>
                          ))}
                        </ul>
                      )}
                      {/* tag group controls */}
                      <div className="flex-center full-width space-evenly mrg-t12 mrg-b12">
                        <button
                          onClick={(e) =>
                            handleToggleModifyGroupTagStateClick(e, tG)
                          }
                          className="tg-button highlight-i-lgt access-ob access-o6"
                          id="tg-button-modify"
                          tabIndex="0"
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              handleToggleModifyGroupTagStateClick(e, tG);
                            }
                          }}
                        >
                          {modifyTagGroup === tG.id ? (
                            <img
                              src={checkmark}
                              alt={IconAlts.iconCheckmark}
                              className={`edit svg ${
                                tapHighlightModify ? 'filter-theme' : ''
                              }`}
                              title={CustomTagsList.finish}
                              id="tg-button-modify-icon"
                            />
                          ) : (
                            <img
                              src={edit}
                              alt={CustomTagsList.modifyTinyTagGroup}
                              className={`edit svg ${
                                tapHighlightModify ? 'filter-theme' : ''
                              }`}
                              title={CustomTagsList.modifyTinyTagGroup}
                              id="tg-button-modify-edit"
                              aria-label={`${CustomTagsList.modifyTinyTagGroup} ${tG.label}`}
                            />
                          )}
                        </button>

                        {modifyTagGroup !== tG.id && (
                          <button
                            className="tg-button highlight-i-lgr access-ob access-o6"
                            title={Generic.remove}
                            id="tg-button-remove"
                            tabIndex="0"
                            aria-label={`${CustomTagsList.removeTinyTagGroup} ${tG.label}`}
                            onKeyDown={(e) =>
                              e.key === 'Enter'
                                ? handleRemoveTagGroupClick(e, index, tG)
                                : null
                            }
                            onClick={(e) =>
                              handleRemoveTagGroupClick(e, index, tG)
                            }
                          >
                            <img
                              src={trash}
                              alt={CustomTagsList.removeTinyTagGroup}
                              className={`trash svg ${
                                tapHighlightRemoveTagGroup ? 'filter-red' : ''
                              }`}
                              title={CustomTagsList.removeTinyTagGroup}
                              id="tg-button-remove-icon"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </li>
              );
            })
            .reverse()}
        </ul>
      </>
    );
  }
  return tagsGroupComponent;
};

export default TinyTagGroups;
