//language
import useLanguagePublic from '../../../language/features/useLanguagePublic';

const ExploreMyndFullIntroduction = () => {
  //language
  const { ExploreMyndFullIntroduction } = useLanguagePublic();

  return (
    <div className="explore-myndfull-introduction explore-base-text bg-cream">
      <h2 tabIndex="0" className="access-ob access-o6">
        {ExploreMyndFullIntroduction.whatIsMyndFull}
      </h2>
      <p className="mrg-t48 mrg-t24--ph access-ob access-o6" tabIndex="0">
        {ExploreMyndFullIntroduction.part1}
      </p>
      <p className="mrg-t24 access-ob access-o6" tabIndex="0">
        {ExploreMyndFullIntroduction.part2}
      </p>
      <p className="fwb italic mrg-t36 access-ob access-o6" tabIndex="0">
        {ExploreMyndFullIntroduction.part3}
      </p>
    </div>
  );
};

export default ExploreMyndFullIntroduction;
