import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//hooks
import { useSettings } from '../../../context/SettingsProvider';
import useReCaptcha from '../../../hooks/useReCaptcha';
import { useApiStatus } from '../../../context/ApiStatusProvider';

//language
import useLanguageComponents from '../../../language/useLanguageComponents';
import useLanguageLayout from '../../../language/useLanguageLayout';
import useLanguagePublic from '../../../language/features/useLanguagePublic';

//components
import EmailInput from '../../../components/TextArea/EmailInput';
import Spinner from '../../../components/Spinner/Spinner';

//utils
import {
  checkmark_green,
  img_add_account,
  img_bullseye,
  img_messages,
  linkedin_color,
} from '../../../assets/icons';
import { logo_cbg } from '../../../assets/images/explore';
import { axiosLimited } from '../../../axios/axios';

const ExploreMore = () => {
  //hooks
  const { width } = useSettings();
  const navigate = useNavigate();
  const { handleError } = useApiStatus();
  const { handleReCaptchaVerify } = useReCaptcha();

  //language
  const { ExploreMyndFullMore } = useLanguagePublic();

  //state
  const [newsletterEmail, setNewsletterEmail] = useState('');

  const [emailValid, setEmailValid] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [submissionSuccessful, setSubmissionSuccessful] = useState();

  //language
  const { IconAlts } = useLanguageComponents();
  const { Footer } = useLanguageLayout();

  useEffect(() => {
    isValidEmail(newsletterEmail);
  }, [newsletterEmail]);

  function isValidEmail(email) {
    const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const check = EMAIL_REGEX.test(email);

    setEmailValid(check);
    return check;
  }

  async function submitNewsletterEmail() {
    if (!isLoading && emailValid) {
      try {
        setIsLoading(true);
        const newEmail = newsletterEmail;
        const validationCheck = isValidEmail(newEmail);

        if (!validationCheck) {
          setIsLoading(false);
          return handleError({
            message: 'invalidData',
            id: Date.now(),
            origin: 'ExploreMore/submitNewsletterEmail',
          });
        }

        const recaptchaVerified = await handleReCaptchaVerify(
          'NEWSLETTER_EMAIL',
          false
        );

        if (recaptchaVerified !== true) {
          setIsLoading(false);
          return handleError({
            message: 'tryAgain',
            id: Date.now(),
            origin: 'ExploreMore/submitNewsletterEmail',
          });
        }

        await axiosLimited.post('/main/register/newsletter', {
          newEmail,
        });

        setSubmissionSuccessful(true);
        const submissionSuccessfulEl = document.getElementById(
          'submissionSuccessful'
        );
        if (submissionSuccessfulEl) submissionSuccessfulEl.focus();
        setIsLoading(false);
      } catch (error) {
        console.log('submitNewsletter error', error);

        setIsLoading(false);
        handleError({
          message: error?.message,
          id: Date.now(),
          origin: 'ExploreMore/submitNewsletterEmail',
        });
      }
    }
  }

  return (
    <div
      className="explore-myndfull-introduction  bg-cream"
      id="myndfull-exploreMore"
    >
      <div className="explore-base-text">
        <div className="flex-row align-center full-width mrg-b24">
          <img
            src={logo_cbg}
            alt="MyndFull logo"
            className="explore-logo explore-logo--launch"
          />
          <h2 className="access-ob access-o6" tabIndex="0">
            {ExploreMyndFullMore.title}
          </h2>
        </div>

        {width > 390 ? (
          <div
            className="flex-row align-center explore-base-text--small access-ob"
            tabIndex="0"
            aria-label={ExploreMyndFullMore.myndFullLaunchAria}
          >
            <div className="flex-column">
              <h3 className="align-center">
                {ExploreMyndFullMore.myndFullTranslations} &mdash;{' '}
                <img
                  src={img_bullseye}
                  style={{ height: '42px' }}
                  alt={IconAlts.iconTarget}
                />
                2025
              </h3>
              <h3 className="align-center mrg-t12">
                {ExploreMyndFullMore.myndFullEvents} &mdash;{' '}
                <img
                  src={img_bullseye}
                  style={{ height: '42px' }}
                  alt={IconAlts.iconTarget}
                />
                Q4 2026
              </h3>
            </div>
          </div>
        ) : (
          <div
            className="flex-column explore-base-text--small "
            tabIndex="0"
            aria-label={ExploreMyndFullMore.myndFullLaunchAria}
          >
            <div className="flex-column">
              <h3 className="align-center">
                {ExploreMyndFullMore.myndFullTranslations}
              </h3>
              <div className="flex-row align-center">
                <img
                  src={img_bullseye}
                  style={{ height: '42px', width: '42px' }}
                  alt={IconAlts.iconTarget}
                />{' '}
                <h3>2025</h3>
              </div>

              <h3 className="align-center mrg-t12">
                {ExploreMyndFullMore.myndFullEvents}
              </h3>
              <div className="flex-row align-center">
                <img
                  src={img_bullseye}
                  style={{ height: '42px', width: '42px' }}
                  alt={IconAlts.iconTarget}
                />{' '}
                <h3> Q4 2026</h3>
              </div>
            </div>
          </div>
        )}

        <p className="mrg-t48 mrg-t24--ph access-ob access-o6" tabIndex="0">
          {ExploreMyndFullMore.part1}
        </p>

        <p className="mrg-t48 mrg-t24--ph access-ob access-o6" tabIndex="0">
          {ExploreMyndFullMore.part2}
        </p>

        <div
          className="divider mrg-tb48 mrg-auto-lr"
          style={{ width: '80%' }}
        />

        <p className="mrg-t36 access-ob access-o6" tabIndex="0">
          {ExploreMyndFullMore.part3}
        </p>
        <div className="flex-row mrg-t48 mrg-t24--ph flex-center">
          <button
            className="explore-button align-center mrg-tb36 access-ob access-o6"
            tabIndex="0"
            onClick={() => navigate('/register')}
            aria-label={ExploreMyndFullMore.navigateToRegister}
          >
            <img
              src={img_add_account}
              className="filter-white mrg-r12"
              alt={IconAlts.iconAccount}
            />
            {ExploreMyndFullMore.signUp}
          </button>
        </div>
      </div>

      <div className="explore-myndfull-more-links full-width">
        <div className="explore-myndfull-more-email">
          <div className=" explore-base-text flex-row ">
            <img
              src={img_messages}
              className="filter-theme mrg-r12"
              alt={IconAlts.iconEmail}
            />
            <h2 className="access-ob access-o6" tabIndex="0">
              {ExploreMyndFullMore.joinNewsletter}
            </h2>
          </div>
          <p className="mrg-tb24 fs21 access-ob access-o6" tabIndex="0">
            {ExploreMyndFullMore.joinNewsletterDescription}
          </p>

          {!submissionSuccessful ? (
            <>
              <div className="fs18">
                <EmailInput
                  handleInput={setNewsletterEmail}
                  id={'newsletterEmailInput'}
                  componentAccountValidationOff={true}
                />
              </div>

              <button
                className={`mrg-t24 bg-color-white access-ob access-o6 ${
                  emailValid && !isLoading
                    ? 'button-save--active'
                    : 'button-save'
                } `}
                onClick={emailValid ? submitNewsletterEmail : null}
                aria-label={
                  emailValid
                    ? `${ExploreMyndFullMore.submit} ${ExploreMyndFullMore.submitAria}.`
                    : `${ExploreMyndFullMore.ariaSaveUnavailable}`
                }
              >
                {emailValid && !isLoading ? (
                  <p className="pad-t2">{ExploreMyndFullMore.submit}</p>
                ) : isLoading ? (
                  <Spinner smallDots={true} />
                ) : (
                  <p className="pad-t2">&mdash;</p>
                )}
              </button>
            </>
          ) : (
            <div
              className="flex-row flex-center access-ob access-o6"
              tabIndex="0"
              aria-label={`${ExploreMyndFullMore.submissionSuccessful}.`}
              id={'submissionSuccessful'}
            >
              <p className="fs18">{ExploreMyndFullMore.submissionSuccessful}</p>
              <img
                src={checkmark_green}
                alt={IconAlts.iconCheckmark}
                style={{ height: '21px' }}
                className="mrg-l24"
              />
            </div>
          )}
        </div>

        <div className="follow-us">
          <div className="explore-base-text mrg-b24">
            <h2 className="access-ob access-o6" tabIndex="0">
              {ExploreMyndFullMore.followLinkedIn}
            </h2>
          </div>
          <a
            href="https://www.linkedin.com/search/results/all/?heroEntityKey=urn%3Ali%3Aorganization%3A89784148&keywords=myndfull&origin=RICH_QUERY_SUGGESTION&position=0&searchId=353b33b8-d1f3-478a-a051-a844ab85140a&sid=*cc"
            aria-label={Footer.visitOurLinkedInPage}
            className="flex-row fit-content align-center access-ob access-o6"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin_color} alt="LinkedIn" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ExploreMore;
