//Hooks

//langage
import useLanguageSettings from '../../../../../../language/features/useLanguageSettings';

//Utility
import {
  date,
  distance,
  direction,
  globe,
  building,
} from '../../../../../../assets/icons';

const DemoFilters = ({
  view,
  searchDirection,
  searchCategory,
  searchFormat,
  setSearchFormat,
  setSearchDirection,
  setSearchCategory,
}) => {
  //lanugage
  const { SettingsEventsLanguage } = useLanguageSettings();

  //Component functions
  function handleDirection() {
    if (searchDirection === 'ascending') {
      setSearchDirection('descending');
    } else {
      setSearchDirection('ascending');
    }
  }

  function handleSearchCategory() {
    if (searchCategory === 'date') {
      if (searchFormat === 'inPerson') {
        setSearchCategory('distance');
      }
    } else {
      setSearchCategory('date');
    }
  }

  function handleSearchFormat() {
    if (searchFormat === 'inPerson') {
      setSearchFormat('online');
      setSearchCategory('date');
    } else if (searchFormat === 'online') {
      setSearchFormat('inPerson');
    }
  }

  return (
    <div className="filters flex-end">
      <ul className="flex-row">
        {view !== 'myevents' && (
          <li className="flex-column">
            <button
              className="access-ob access-o6"
              onClick={handleSearchFormat}
              tabIndex="0"
              title={`${
                searchFormat === 'inPerson'
                  ? SettingsEventsLanguage.searchFormatInPerson
                  : SettingsEventsLanguage.searchFormatOnline
              }`}
              aria-label={`${
                searchFormat === 'inPerson'
                  ? SettingsEventsLanguage.searchFormatInPerson
                  : SettingsEventsLanguage.searchFormatOnline
              }`}
            >
              <img
                src={`${searchFormat === 'inPerson' ? building : globe}`}
                alt={
                  searchFormat === 'inPerson'
                    ? SettingsEventsLanguage.buildingImg
                    : SettingsEventsLanguage.globeImg
                }
              />
            </button>
            <div className="flex-row flex-center mrg-t12">
              <span
                className={`dot mrg-r6 ${
                  searchFormat === 'inPerson'
                    ? 'bg-color-theme'
                    : 'bg-color-lightgray'
                }`}
              />
              <span
                className={`dot  ${
                  searchFormat === 'online'
                    ? 'bg-color-theme'
                    : 'bg-color-lightgray'
                }`}
              />
            </div>
          </li>
        )}
        <li className="flex-column">
          <button
            className="access-ob access-o6"
            onClick={handleDirection}
            tabIndex="0"
            title={`${
              searchDirection === 'ascending'
                ? SettingsEventsLanguage.searchAscending
                : SettingsEventsLanguage.searchDescending
            }`}
            aria-label={`${
              searchDirection === 'ascending'
                ? SettingsEventsLanguage.ariaAscending
                : SettingsEventsLanguage.ariaDescending
            }`}
          >
            <img
              src={direction}
              alt={SettingsEventsLanguage.ascending}
              className={`${searchDirection === 'ascending' ? 'reverse' : ''}`}
            />
          </button>
          <div className="flex-row flex-center mrg-t12">
            <span
              className={`dot mrg-r6 ${
                searchDirection === 'ascending'
                  ? 'bg-color-theme'
                  : 'bg-color-lightgray'
              }`}
            />
            <span
              className={`dot  ${
                searchDirection === 'descending'
                  ? 'bg-color-theme'
                  : 'bg-color-lightgray'
              }`}
            />
          </div>
        </li>
        <li className="flex-column">
          <button
            className="access-ob access-o6"
            onClick={handleSearchCategory}
            tabIndex="0"
            title={`${
              searchCategory === 'date'
                ? SettingsEventsLanguage.searchDate
                : SettingsEventsLanguage.searchDistance
            }`}
            aria-label={`${
              searchCategory === 'date'
                ? SettingsEventsLanguage.ariaDate
                : SettingsEventsLanguage.ariaDistance
            }`}
          >
            <img
              src={`${searchCategory === 'date' ? date : distance}`}
              alt={SettingsEventsLanguage.date}
            />
          </button>
          <div className="flex-row flex-center mrg-t12">
            <span
              className={`dot ${searchFormat === 'inPerson' ? 'mrg-r6' : ''} ${
                searchCategory === 'date'
                  ? 'bg-color-theme'
                  : 'bg-color-lightgray'
              }`}
            />
            {searchFormat === 'inPerson' && (
              <span
                className={`dot  ${
                  searchCategory === 'distance'
                    ? 'bg-color-theme'
                    : 'bg-color-lightgray'
                }`}
              />
            )}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default DemoFilters;
