import {
  image_accessibility_card_en,
  image_accessibility_card_fr,
  image_demo_desktop_en,
  image_demo_desktop_fr,
  image_demo_mobile_en,
  image_demo_mobile_fr,
  image_preview_banner_enCA,
  image_preview_banner_frCA,
  image_thanks_en,
  image_thanks_fr,
} from '../assets/language_images';
import { useSettings } from '../context/SettingsProvider';

const useLanguageData = () => {
  const { contentLanguage } = useSettings();

  const PreviewBanner = () => {
    let imageSrc;

    if (contentLanguage === 'en-CA') {
      imageSrc = image_preview_banner_enCA;
    }
    if (contentLanguage === 'fr-CA') {
      imageSrc = image_preview_banner_frCA;
    }
    return imageSrc;
  };

  const Thanks = () => {
    let imageSrc;

    if (contentLanguage === 'en-CA') {
      imageSrc = image_thanks_en;
    }
    if (contentLanguage === 'fr-CA') {
      imageSrc = image_thanks_fr;
    }
    return imageSrc;
  };

  const DemoFindEventsDesktop = () => {
    let imageSrc;

    if (contentLanguage === 'en-CA') {
      imageSrc = image_demo_desktop_en;
    }
    if (contentLanguage === 'fr-CA') {
      imageSrc = image_demo_desktop_fr;
    }
    return imageSrc;
  };

  const DemoFindEventsMobile = () => {
    let imageSrc;

    if (contentLanguage === 'en-CA') {
      imageSrc = image_demo_mobile_en;
    }
    if (contentLanguage === 'fr-CA') {
      imageSrc = image_demo_mobile_fr;
    }
    return imageSrc;
  };

  const AccessibilityCard = () => {
    let imageSrc;

    if (contentLanguage === 'en-CA') {
      imageSrc = image_accessibility_card_en;
    }
    if (contentLanguage === 'fr-CA') {
      imageSrc = image_accessibility_card_fr;
    }
    return imageSrc;
  };

  return {
    PreviewBanner: PreviewBanner(),
    Thanks: Thanks(),
    DemoFindEventsDesktop: DemoFindEventsDesktop(),
    DemoFindEventsMobile: DemoFindEventsMobile(),
    AccessibilityCard: AccessibilityCard(),
  };
};

export default useLanguageData;
