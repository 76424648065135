//hooks
import { useSettings } from '../../../context/SettingsProvider';

//language
import useLanguagePublic from '../../../language/features/useLanguagePublic';

//utils
import {
  image_community,
  image_community_mobile,
} from '../../../assets/images/explore';

const ExploreCommunity = () => {
  //hooks
  const { width } = useSettings();

  //language
  const { ExploreMyndFullCommunity } = useLanguagePublic();

  return (
    <div className="explore-community bg-color-white">
      {width > 480 ? (
        <img
          src={image_community}
          alt={ExploreMyndFullCommunity.imageAlt}
          className="explore-community-img access-ob"
          tabIndex="0"
          loading="lazy"
        />
      ) : (
        <img
          src={image_community_mobile}
          alt={ExploreMyndFullCommunity.imageAlt}
          className="explore-community-img access-ob"
          tabIndex="0"
          loading="lazy"
        />
      )}

      <div className="explore-community__container">
        <p
          className="explore-community-text-box access-ob access-o-6"
          tabIndex="0"
        >
          {ExploreMyndFullCommunity.text}
        </p>
      </div>
    </div>
  );
};

export default ExploreCommunity;
