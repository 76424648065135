//components
import FindEventsCommunityAndEmail from './FindEventsCommunityAndEmail';
import FindEventsEventType from './FindEventsEventType';
import FindEventsTags from './FindEventsTags';

//does not include find events toolbar which is positioned separately in parent
const ExploreFeaturesFindEvents = ({
  currentIndex,
  ExploreMyndFullFeatures,
}) => {
  return (
    <div className="feature__wrapper" id="features-wrapper">
      <FindEventsEventType
        currentIndex={currentIndex}
        ExploreMyndFullFeatures={ExploreMyndFullFeatures}
      />
      <FindEventsTags
        currentIndex={currentIndex}
        ExploreMyndFullFeatures={ExploreMyndFullFeatures}
      />
      <FindEventsCommunityAndEmail
        currentIndex={currentIndex}
        ExploreMyndFullFeatures={ExploreMyndFullFeatures}
      />
    </div>
  );
};

export default ExploreFeaturesFindEvents;
