import ExploreOrganizeS1 from './ExploreOrganizeS1';
import ExploreOrganizeS2 from './ExploreOrganizeS2';
import ExploreOrganizeS3 from './ExploreOrganizeS3';

const ExploreFeaturesOrganize = ({ currentIndex, ExploreMyndFullFeatures }) => {
  //hooks
  return (
    <div className="feature__wrapper" id="features-wrapper">
      <ExploreOrganizeS1
        currentIndex={currentIndex}
        ExploreMyndFullFeatures={ExploreMyndFullFeatures}
      />
      <ExploreOrganizeS2
        currentIndex={currentIndex}
        ExploreMyndFullFeatures={ExploreMyndFullFeatures}
      />
      <ExploreOrganizeS3
        currentIndex={currentIndex}
        ExploreMyndFullFeatures={ExploreMyndFullFeatures}
      />
    </div>
  );
};

export default ExploreFeaturesOrganize;
