//hooks
import { useSettings } from '../../../../../context/SettingsProvider';

//utils
import { img_social, star } from '../../../../../assets/icons';

const ExploreOrganizeS3 = ({ currentIndex, ExploreMyndFullFeatures }) => {
  //hooks
  const { width } = useSettings();

  return (
    <>
      {((width > 1280 && currentIndex === 2) ||
        (width <= 1280 && currentIndex === 2)) && (
        <div className="features__container">
          <div className="pos-rel flex-row align-center mrg-t24">
            <img
              src={img_social}
              alt=""
              className="filter-theme mrg-r12"
              style={{ height: '32px' }}
            />
            <h4 className="features-title  access-ob access-o6" tabIndex="0">
              {ExploreMyndFullFeatures.buildCommunity}
            </h4>
          </div>
          <p
            className="features-description mrg-tb24  access-ob access-o6"
            tabIndex="0"
          >
            {ExploreMyndFullFeatures.buildCommunityDescription}
          </p>
        </div>
      )}

      {((width > 1280 && currentIndex === 2) ||
        (width <= 1280 && currentIndex === 3)) && (
        <div className="features__container">
          <div className="flex-row align-center mrg-t24">
            <img
              src={star}
              alt=""
              className="filter-theme mrg-r12"
              style={{ height: '32px' }}
            />
            <h4 className="features-title  access-ob access-o6" tabIndex="0">
              {ExploreMyndFullFeatures.premiumOrganizers}
            </h4>
          </div>
          <p
            className="features-description mrg-tb24  access-ob access-o6"
            tabIndex="0"
          >
            {ExploreMyndFullFeatures.premiumOrganizersDescription}
          </p>
        </div>
      )}
    </>
  );
};
export default ExploreOrganizeS3;
