import { useState } from 'react';

//language
import useLanguageData from '../../../../../language/useLanguageData';
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//components
import SelectionsModal from '../../../../../components/Selections/SelectionsModal';
import CustomTagsList from '../../../../../components/CustomTagsList/CustomTagsList';

//utils
import { bigtag_nobg } from '../../../../../assets/icons';
import { useSettings } from '../../../../../context/SettingsProvider';

const FindEventsTags = ({ currentIndex, ExploreMyndFullFeatures }) => {
  //hooks
  const { width } = useSettings();

  //language
  const { BigTagIcon, TinyTagIcon, BigTagsOpts, BigTagsOptsWithSubText } =
    useLanguageData();
  const { Generic, IconAlts } = useLanguageComponents();

  //demo state
  const [bigTags, setBigTags] = useState([]);
  const [tinyTags, setTinyTags] = useState([]);
  const [tinyTagGroups, setTinyTagGroups] = useState([]);
  const [tinyTagGroupsModifyActive, setTinyTagGroupsModifyActive] =
    useState(false);

  return (
    <>
      {((width > 1280 && currentIndex === 2) ||
        (width <= 1280 && currentIndex === 3)) && (
        <div className="features__container">
          <div className="flex-row align-center mrg-t24">
            <img
              src={BigTagIcon}
              alt={IconAlts.bigTags}
              className=" mrg-r12"
              style={{ height: '28px' }}
            />
            <h4 className="features-title access-ob access-o6" tabIndex="0">
              {ExploreMyndFullFeatures.bigTags}
            </h4>
          </div>
          <p
            className="features-description mrg-tb24 access-ob access-o6"
            tabIndex="0"
          >
            {ExploreMyndFullFeatures.bigTagsDescription}
          </p>

          <SelectionsModal
            addButtonText={`${Generic.add} ${Generic.bigTags}`}
            DataOpts={BigTagsOptsWithSubText}
            DataOptsSubTextTranslations={BigTagsOpts}
            fullWidth={true}
            iconModal={bigtag_nobg}
            iconOff={true}
            limit={20}
            modalTitle={Generic.bigTags}
            selectionState={bigTags}
            setSelectionState={setBigTags}
            iconModalClass={'bT'}
            subTextOn={true}
            id={'bigTagSelections'}
            itemButtonCustomWrapper={'item-button-full'}
          />
        </div>
      )}

      {((width > 1280 && currentIndex === 2) ||
        (width <= 1280 && currentIndex === 4)) && (
        <div className="features__container">
          <div className="flex-row align-center mrg-t24">
            <img
              src={TinyTagIcon}
              alt={IconAlts.tinyTags}
              style={{ height: '42px' }}
              className="mrg-r12"
            />
            <h4 className="features-title access-ob access-o6" tabIndex="0">
              {ExploreMyndFullFeatures.tinyTags}
            </h4>
          </div>
          <p
            className="features-description mrg-tb24 access-ob access-o6"
            tabIndex="0"
          >
            {ExploreMyndFullFeatures.tinyTagsDescription}
          </p>

          <CustomTagsList
            limit={200}
            id={'tinyTags'}
            tinyTags={tinyTags}
            setTinyTags={setTinyTags}
            twoTagRows={true}
            tinyTagGroups={tinyTagGroups}
            setTinyTagGroups={setTinyTagGroups}
            setTinyTagGroupsModifyActive={setTinyTagGroupsModifyActive}
          />
        </div>
      )}
    </>
  );
};

export default FindEventsTags;
