import { useState } from 'react';

//Hooks
import { useSettings } from '../../context/SettingsProvider';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//Components
import Spinner from '../Spinner/Spinner';

//Utility
import { nav_arrow } from '../../assets/icons';

const NavigationArrows = ({
  handlePageDecrease,
  handlePageIncrease,
  continueBtnCheck,
  finalContinue,
  HOFunction,
  firstArrow,
  fullWidth,
  isLoading,
  skip,
  skipOn,
  previousIsExit,
  customFinalContinue,
  customWrapper,
}) => {
  //Hooks
  const { handleMobileTap, width } = useSettings();

  //language
  const { NavigationArrows, IconAlts } = useLanguageComponents();

  //UI state
  const [tapHighlightNavIncrease, setTapHighlightNavIncrease] = useState(false);
  const [tapHighlightNavDecrease, setTapHighlightNavDecrease] = useState(false);

  return (
    <div className={`nav-arrows ${customWrapper ? customWrapper : ''}`}>
      {!firstArrow && !previousIsExit && (
        <button
          className={`${
            isLoading ? 'opacity-05 unavailable' : 'highlight-it-theme'
          } ${
            tapHighlightNavDecrease ? 'highlight-it-theme--tap' : ''
          } access-ob access-o6`}
          onClick={
            isLoading
              ? null
              : () =>
                  handleMobileTap(
                    [
                      () => setTapHighlightNavDecrease(true),
                      () => setTapHighlightNavDecrease(false),
                    ],
                    [() => handlePageDecrease()]
                  )
          }
          tabIndex="0"
          aria-label={`${NavigationArrows.previous}.`}
        >
          <div className="arrow rotate-180 mrg-r12 svg">
            <img src={nav_arrow} alt={IconAlts.iconArrow} />
          </div>
          {width > 480 && `${NavigationArrows.previous}`}
        </button>
      )}
      {!firstArrow && previousIsExit && (
        <button
          className={`${
            isLoading ? 'opacity-05 unavailable' : 'highlight-it-theme'
          } ${
            tapHighlightNavDecrease ? 'highlight-it-theme--tap' : ''
          } access-ob access-o6 `}
          onClick={
            isLoading
              ? null
              : () =>
                  handleMobileTap(
                    [
                      () => setTapHighlightNavDecrease(true),
                      () => setTapHighlightNavDecrease(false),
                    ],
                    [() => handlePageDecrease()]
                  )
          }
          tabIndex="0"
          aria-label={`${NavigationArrows.exit}.`}
        >
          <div className="arrow rotate-180 mrg-r12 svg">
            <img src={nav_arrow} alt={IconAlts.iconArrow} />
          </div>
          {width > 480 && `${NavigationArrows.exit}`}
        </button>
      )}
      {finalContinue ? (
        <button
          className={`${
            continueBtnCheck || skipOn
              ? 'highlight-it-theme'
              : 'unavailable color-lightgray'
          } ${isLoading ? 'pointer-default' : ''} ${
            customFinalContinue ? customFinalContinue : ''
          } ${
            tapHighlightNavIncrease ? 'highlight-it-theme--tap' : ''
          } access-ob access-o6`}
          onClick={
            (continueBtnCheck || skipOn) && !isLoading
              ? () =>
                  handleMobileTap(
                    [
                      () => setTapHighlightNavIncrease(true),
                      () => setTapHighlightNavIncrease(false),
                    ],
                    [(e) => HOFunction(e)]
                  )
              : null
          }
          tabIndex="0"
        >
          {isLoading ? (
            <Spinner maxHeight={'40px'} />
          ) : (
            `${NavigationArrows.finish}`
          )}
        </button>
      ) : (
        <button
          className={`${firstArrow ? 'first-arrow' : ''} ${
            continueBtnCheck || skipOn
              ? 'highlight-it-theme'
              : 'opacity-05 unavailable'
          } ${
            tapHighlightNavIncrease ? 'highlight-it-theme--tap' : ''
          } access-ob access-o6`}
          onClick={
            (continueBtnCheck || skipOn) && !isLoading
              ? () =>
                  handleMobileTap(
                    [
                      () => setTapHighlightNavIncrease(true),
                      () => setTapHighlightNavIncrease(false),
                    ],
                    [() => handlePageIncrease()]
                  )
              : null
          }
          tabIndex="0"
          aria-label={`${
            continueBtnCheck || skipOn
              ? `${NavigationArrows.continue}.`
              : `${NavigationArrows.minimumRequirementsNotMet}.`
          }`}
        >
          {skipOn && skip
            ? NavigationArrows.skip
            : width > 480 && `${NavigationArrows.continue}`}
          <div className="arrow svg mrg-l12">
            <img src={nav_arrow} alt={IconAlts.iconArrow} />
          </div>
        </button>
      )}
    </div>
  );
};

export default NavigationArrows;
