//language
import useLanguageData from '../../../../../../language/useLanguageData';
import useLanguageSettings from '../../../../../../language/features/useLanguageSettings';

//utils
import { saved, social } from '../../../../../../assets/icons';

const DemoViews = ({ view, setView, width, setToolbarActiveHighlight }) => {
  //language
  const { SettingsEventsLanguage } = useLanguageSettings();
  const { BigTagIcon, TinyTagIcon } = useLanguageData();

  return (
    <ul className="view__container">
      <li>
        <button
          tabIndex="0"
          className="access-ob access-o6"
          onClick={() => {
            if (width < 1285) {
              setToolbarActiveHighlight('view');
            }
            setView('myevents');
          }}
          title={SettingsEventsLanguage.myEvents}
          aria-label={SettingsEventsLanguage.myEvents}
          id="view-myevents"
          aria-pressed={view === 'myevents'}
        >
          <div
            className={`view-img__container ${
              view === 'myevents' ? 'selected' : ''
            }`}
          >
            <img src={saved} alt={SettingsEventsLanguage.myEvents} />
          </div>
        </button>
      </li>
      <li>
        <button
          tabIndex="0"
          className={`bg-color-white access-ob access-o6`}
          onClick={() => {
            if (width < 1285) {
              setToolbarActiveHighlight('view');
            }
            setView('bigtags');
          }}
          title={SettingsEventsLanguage.bigTags}
          aria-label={SettingsEventsLanguage.bigTags}
          id="view-bigtags"
          aria-pressed={view === 'bigtags'}
        >
          <div
            className={`view-img__container ${
              view === 'bigtags' ? 'selected' : ''
            }`}
          >
            <img
              src={BigTagIcon}
              alt={SettingsEventsLanguage.bigTags}
              style={{ height: '21px' }}
            />
          </div>
        </button>
      </li>
      <li>
        <button
          tabIndex="0"
          className="bg-color-white access-ob access-o6"
          onClick={() => {
            if (width < 1285) {
              setToolbarActiveHighlight('view');
            }
            setView('tinytags');
          }}
          title={SettingsEventsLanguage.tinyTags}
          aria-label={SettingsEventsLanguage.tinyTags}
          id="view-tinytags"
          aria-pressed={view === 'tinytags'}
        >
          <div
            className={`view-img__container ${
              view === 'tinytags' ? 'selected' : ''
            }`}
          >
            <img
              src={TinyTagIcon}
              alt={SettingsEventsLanguage.tinyTags}
              style={{ height: '30px' }}
            />
          </div>
        </button>
      </li>
      <li>
        <button
          tabIndex="0"
          className="access-ob access-o6"
          onClick={() => {
            if (width < 1285) {
              setToolbarActiveHighlight('view');
            }
            setView('community');
          }}
          title={SettingsEventsLanguage.community}
          aria-label={SettingsEventsLanguage.community}
          id="view-community"
          aria-pressed={view === 'community'}
        >
          <div
            className={`view-img__container ${
              view === 'community' ? 'selected' : ''
            }`}
          >
            <img src={social} alt={SettingsEventsLanguage.community} />
          </div>
        </button>
      </li>
    </ul>
  );
};

export default DemoViews;
