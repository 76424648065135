import { useSettings } from '../context/SettingsProvider';

const useLanguageApiStatus = () => {
  const { contentLanguage } = useSettings();

  const ErrorTranslations = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        error: 'Error.',
        invalidToken: 'Invalid token.',
        expiredToken: 'Expired token.',
        castError: 'Cast Error.',
        duplicateFieldError: 'Duplicate field error.',
        validationError: 'Invalid data.',
        tryAgain: 'Please try again.',
        unauthorized: 'Unauthorized.',
        badRequest: 'Bad request.',
        dataNotFound: 'Data not found.',
        incompleteInformation: 'Please provide all required fields.',
        updateFailed: 'Update failed.',
        internalServerError: 'Internal server error.',
        serverError: 'Server error.',
        wrongPassword: 'Wrong password.',
        imageFailedToLoad: 'Image failed to load.',
        forbidden: 'Forbidden.',
        failedRecaptcha:
          'ReCAPTCHA failed. Please try again or contact support.',
        notFound: 'Not Found',
        alreadyAssigned: 'Already Assigned',
        fileNotFound: 'File not found.',
        networkError: 'Network error',
        requestUnavailable: 'Request unavailable.',
        invalidData: 'Invalid data',
        emailAlreadyRegistered: 'Email is already registered.',

        //auth
        pleaseWaitOneMinute: 'Please wait one minute to make another attempt.',
        pleaseContactSupport: 'Please contact support.',

        //account
        accountNotFound: 'Account not found.',
        roomCodeNotFound: 'Room Code not found.',
        resetTokenError: `Oops, the link for resetting the password has expired or isn't working properly.`,
        passwordsDontMatch: 'Password and Password Confirm do not match.',
        newPasswordMatchesPreviousPassword:
          'New password cannot match your old password.',
        tooManyFailedAttemptsAccountLocked:
          'Too many failed attempts. Please wait 15 minutes before trying again.',
        cannotRemovePrimaryCardWithActiveSubscriptions:
          'Cannot remove primary card with active subscriptions.',
        tagLimitReached: 'Tag limit reached.',
        cannotChangeRolesActiveEvents:
          'You cannot deactivate the organizer role with active events.',

        //registration
        emailRegistered: 'Email is already registered.',
        accessCodeRegistered:
          'Access code is already registered with an account.',
        accessCodeRequired: 'Access code is required.',
        incorrectAccessCode: 'Incorrect access code.',
        registrationFailed: 'Registration failed.',
        accountRegistrationTokenHasExpired:
          'Your new account registration token has expired.',
        invalidAccountRegistrationToken: 'Invalid account registration token.',
        invalidRoomCode: 'Invalid Room Code',
        roomCodeRegistered: 'Room code is currently registered.',

        //events
        eventNotFound: 'Event not found.',
        eventsNotFound: 'Events not found.',
        cannotPerformActionWhenRegisteredForThisEvent:
          'Cannot perform action when registered for this event.',

        //multer
        imageSizeTooLarge: `Please use an image below 5 MB. (Note: Using the crop tool to zoom in will decrease the image size).`,
        differentFileType: 'A different file type is required.',
        resizeFailed: 'File failed to resize. Server error.',
        imageUploadFailure: 'Image upload failure.',
        unsupportedFileType: 'Unsupported File Type.',
        noSupportHEICHEIF: 'HEIC/HEIF files are not currently supported on iOS',

        //components
        selectionLimitReached: 'Selection Limit reached.',
        tinyTagGroupsLabelLimit: 'Tag Group Label exceeds character limit.',
        characterLimitReached: 'Character limit reached.',
        //geolocation
        geocodeUnsuccessful: 'Geocode was unsuccessful.',

        //sockets
        connectionError: 'Connection Error.',
        authenticationError: 'Authentication Expired/Invalid.',
        //admin
        archivePathExists: 'Archive path already exists.',

        //translations
        chromeIsRequired: 'Chrome is required',
        roomCodeNotAvailable:
          'Room Code is not available. Please contact customer support.',
        saveNameAlreadyUsed: 'Save name is already in use.',

        //stripe
        card_declined: 'Card declined.',
        expired_card: 'Expired card.',
        incorrect_cvc: 'Incorrect card CVC.',
        processing_error: 'Card processing error.',
        paymentRequired: 'Payment required.',

        //find speakers
        cannotAddYourOwnAccount: 'Cannot add your own account.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        error: 'Erreur',
        invalidToken: `Jeton invalide.`,
        expiredToken: `Jeton expiré.`,
        castError: 'Cast Erreur.',
        duplicateFieldError: 'Duplicate field erreur.',
        validationError: `Données invalides.`,
        tryAgain: 'Veuillez réessayer.',
        unauthorized: 'Non autorisé.',
        badRequest: 'Requête incorrecte.',
        dataNotFound: 'Données introuvables.',
        incompleteInformation: 'Veuillez remplir tous les champs obligatoires.',
        updateFailed: 'Mise à jour a échoué.',
        internalServerError: 'Erreur du serveur.',
        serverError: 'Server error.',
        wrongPassword: `Mauvais mot de passe.`,
        imageFailedToLoad: `Échec du chargement de l'image.`,
        forbidden: 'Interdit',
        failedRecaptcha: `ReCAPTCHA a échoué. Veuillez réessayer ou contacter l'assistance.`,
        notFound: 'Pas trouvé',
        alreadyAssigned: `Déjà Attribué`,
        fileNotFound: 'Fichier introuvable.',
        networkError: 'Erreur réseau',
        requestUnavailable: 'Demande indisponible.',
        invalidData: 'Données invalides',
        emailAlreadyRegistered: 'E-mail est déjà enregistrée.',
        //auth
        pleaseWaitOneMinute:
          'Veuillez patienter une minute avant de faire une autre tentative.',
        pleaseContactSupport: `Veuillez contacter l'assistance.`,

        //account
        accountNotFound: 'Compte non trouvé.',
        roomCodeNotFound: 'Code de la salle introuvable.',
        resetTokenError: `Oups, le lien pour réinitialiser le mot de passe a expiré ou ne fonctionne pas correctement.`,
        passwordsDontMatch:
          'Le mot de passe et la confirmation du mot de passe ne correspondent pas.',
        newPasswordMatchesPreviousPassword:
          'Le nouveau mot de passe ne peut pas correspondre à votre ancien mot de passe.',
        tooManyFailedAttemptsAccountLocked:
          'Trop de tentatives échouées. Veuillez attendre 15 minutes avant de réessayer.',
        cannotRemovePrimaryCardWithActiveSubscriptions:
          'Impossible de supprimer la carte principale avec des abonnements actifs.',
        tagLimitReached: 'Limite de tags atteinte.',
        cannotChangeRolesActiveEvents: `Vous ne pouvez pas désactiver le rôle d'organisateur avec des événements actifs.`,

        //registration
        emailRegistered: `L'adresse e-mail est déjà utilisée.`,
        accessCodeRegistered: `Le code d'accès est déjà enregistré avec un compte.`,
        accessCodeRequired: `Un code d'accès est requis.`,
        incorrectAccessCode: `Code d'accès incorrect.`,
        registrationFailed: `Échec de l'inscription.`,
        accountRegistrationTokenHasExpired: `Votre nouveau jeton d'enregistrement de compte a expiré.`,
        invalidAccountRegistrationToken: `Jeton d'enregistrement de compte invalide.`,
        invalidRoomCode: 'Code de la salle invalide',
        roomCodeRegistered: 'Le code de la salle est actuellement enregistré.',

        //events
        eventNotFound: 'Evénement introuvable.',
        eventsNotFound: 'Événements introuvables.',
        cannotPerformActionWhenRegisteredForThisEvent: `Impossible d'effectuer une action lorsque vous êtes inscrit à cet événement.`,

        //multer
        imageSizeTooLarge: `Veuillez utiliser une image inférieure à 5 Mo. (Remarque : l'utilisation de l'outil de recadrage pour zoomer réduira la taille de l'image).`,
        differentFileType: `
        Un type de fichier différent est requis.`,
        unsupportedFileType: 'Type de fichier non pris en charge.',
        noSupportHEICHEIF:
          'Les fichiers HEIC/HEIF ne sont actuellement pas pris en charge sur iOS',
        resizeFailed: `Échec du redimensionnement du fichier. Erreur du serveur.`,
        imageUploadFailure: `Échec du téléchargement de l'image.`,

        //components
        selectionLimitReached: `Limite de sélection atteinte.`,
        tinyTagGroupsLabelLimit: `L'étiquette du groupe de balises dépasse la limite de caractères`,
        characterLimitReached: 'Limite de caractères atteinte.',

        //geolocation
        geocodeUnsuccessful: 'Le géocodage a échoué.',

        //sockets
        connectionError: 'Erreur de connexion.',
        authenticationError: 'Authentification expirée/invalide.',

        //admin
        archivePathExists: `Le chemin d'archive existe déjà.`,

        //translations
        chromeIsRequired: `Chrome est requis pour l'hébergement.`,
        roomCodeNotAvailable: `Le code de la salle n'est pas disponible. Veuillez contacter le service client.`,
        saveNameAlreadyUsed: 'Le nom de sauvegarde est déjà utilisé.',

        //stripe
        card_declined: 'Carte refusée.',
        expired_card: 'Carte expirée.',
        incorrect_cvc: 'CVC de carte incorrect.',
        processing_error: 'Erreur de traitement de la carte.',
        paymentRequired: 'Paiement requis.',

        //find speakers
        cannotAddYourOwnAccount: `Impossible d'ajouter votre propre compte.`,
      };
    }

    return translation;
  };

  const SuccessTranslations = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        success: 'Success',
        accountUpdated: 'Account Updated',
        emailUpdated: 'Email Updated',
        passwordUpdated: 'Password Updated',
        photoUpdated: 'Photo Updated',
        imageUpdated: 'Image Updated',
        emailSent: 'Email Sent',
        emailVerificationSuccess: 'Account email verification successful',
        draftSaved: 'Draft Saved',
        eventCreated: 'Event Created',
        eventRemoved: 'Event Removed',
        eventUpdated: 'Event Updated',
        draftsRemoved: 'Drafts Removed',
        transcriptsRemoved: 'Transcripts Removed',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        success: 'Succés',
        accountUpdated: 'Compte mis à jour',
        emailUpdated: 'E-mail mis à jour',
        passwordUpdated: 'Mot de passe mis à jour',
        photoUpdated: 'Photo mise à jour',
        imageUpdated: 'Image mise à jour',
        emailSent: 'Email envoyé',
        emailVerificationSuccess: `Vérification de l'adresse e-mail du compte réussie`,
        draftSaved: 'Brouillon enregistré',
        eventCreated: 'Événement créé',
        eventRemoved: 'Événement supprimer',
        eventUpdated: 'Événement mis à jour',
        draftsRemoved: 'Brouillons supprimés',
        transcriptsRemoved: 'Transcriptions supprimées',
      };
    }

    return translation;
  };

  const WarningTranslations = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        warning: 'Warning',
        accountRequiresEmailVerification: `Your account requires email verification. Please check your email for a new confirmation email.`,
        cannotPerformActionWhenRegisteredForThisEvent:
          'Cannot perform action when registered for this event.',
        tagLimitReached: 'Tag limit reached.',
        tagAlreadySelected: 'Tag already selected.',
        unavailbeOnScreenSize: 'Unavailabe on this screen size.',
        hostingOptionRequired: 'Hosting Option required.',
        incompatibleHostingOption: 'Incompatible Hosting Option.',
        requiresTranslations: 'Requires Translations',
        requiresContact: 'Requires Contact',
        invalidTinyTag: 'Invalid Tiny Tag.',
        incompleteInformation: 'Incomplete information.',
        alreadySelected: 'Already Selected.',
        selectStartTime: 'Please select a Start Time.',
        speakerLanguageSelectionRequired: 'Please select a Speaker Language.',
        languageSelectionRequired: 'Please select a Language.',
        cannotSelectWhileRecording: 'Cannot select while recording.',
        roomCodeAlreadyHosted: 'Room code is already hosted.',
        tryAgain: 'Please try again.',
        voiceSelectionRequired: 'Please select a Voice.',
        textTranslationRequired: 'Please select a Text Language.',
        incorrectAccessCode: 'Incorrect access code.',
        microphoneNotAllowed: 'Microphone permission is required.',
        keywordLengthInvalid: 'Keyword must be under 100 characters.',
        keywordAlreadySelected: 'Keyword already selected.',
        keywordLimitReached: 'Keyword limit reached.',
        wrongPassword: 'Wrong password.',
        unsupportedFileType: 'Unsupported File Type.',
        fileSizeTooLarge: 'File size exceeds the limit.',
        currentSpeakerRequired: 'Current Speaker is required.',
        chromeIsRequired: 'Chrome is required for hosting.',
        roomHasNewHost: 'Room has a new host.',
        roomCodeRegistered: 'Room code is currently registered.',
        roomCodeAlreadyAdded: 'Room Code already added.',
        invalidRoomCode: 'Invalid Room Code',
        newCardAlreadyCreated: 'New card already created.',
        cannotRemovePrimaryCardWithActiveSubscriptions:
          'Cannot remove primary card with active subscriptions.',
        roomCodeNotFound: 'Room code not found.',
        browserAlreadyConnected: 'Browser is already connected.',
        waitForConnection: 'Please wait for a connection.',
        requiresArchivePath: 'Requires Archive Path',

        roomCodeNotAvailable:
          'Room Code is not available. Please contact customer support.',
        limitReached: 'Limit Reached',

        locationIsIncomplete: 'Location is incomplete.',
        tagsIsIncomplete: '1 Tag is Required',
        languageIsIncomplete: 'Language is incomplete.',
        cannotInteractWithYourOwnAccount:
          'You cannot interact with your own account.',
        cannotInteractWithBlockedAccount:
          'You cannot interact with a blocked account.',
        draftLimit: 'You have reached the maximum number of event drafts.',
        roomCodeSubscriptionRequired:
          'MyndFull Room Code Subscription is required.',
        completeRoomLanguages: 'Please complete Room Languages.',
        cannotOverwriteFile: 'Cannot overwrite existing file',
        selectionRequired: 'Selection Required',
        participantDataIncomplete: 'Participant data is incomplete.',
        unavailableDemo: 'Unavailable in Demo',
        emailAlreadyRegistered: 'Email is already registered.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        warning: 'Avertissement',
        accountRequiresEmailVerification: `Votre compte nécessite une vérification par e-mail. Veuillez vérifier votre courrier électronique pour un nouvel e-mail de confirmation.`,
        cannotPerformActionWhenRegisteredForThisEvent: `Impossible d'effectuer une action lorsque vous êtes inscrit à cet événement.`,
        tagLimitReached: 'Limite de tags atteinte.',
        tagAlreadySelected: `Tag déjà sélectionné.`,
        hostingOptionRequired: `Option d'organisation requise.`,
        incompatibleHostingOption: `Option d'organisation incompatible.`,
        requiresTranslations: 'Nécessite des traductions',
        requiresContact: 'Nécessite un contact',
        invalidTinyTag: 'Petite balise invalide.',
        incompleteInformation: 'Information incomplète.',
        alreadySelected: 'Déjà sélectionné.',
        selectStartTime: 'Veuillez sélectionner une heure de début.',
        speakerLanguageSelectionRequired:
          'Veuillez sélectionner une langue de locuteur.',
        languageSelectionRequired: 'Veuillez sélectionner une langue.',
        cannotSelectWhileRecording: `Impossible de sélectionner pendant l'enregistrement.`,
        roomCodeAlreadyHosted: 'Le code de la salle est déjà hébergé.',
        tryAgain: 'Veuillez réessayer.',
        voiceSelectionRequired: 'Veuillez sélectionner une voix.',
        textTranslationRequired: 'Veuillez sélectionner une langue de texte.',
        incorrectAccessCode: 'Incorrect access code.',
        microphoneNotAllowed: `L'autorisation du microphone est requise.`,
        keywordLengthInvalid:
          'Le mot clé doit comporter moins de 100 caractères.',
        keywordAlreadySelected: 'Mot clé déjà sélectionné.',
        keywordLimitReached: 'Limite de mots clés atteinte.',
        wrongPassword: `Mauvais mot de passe.`,
        unsupportedFileType: 'Type de fichier non pris en charge.',
        fileSizeTooLarge: 'La taille du fichier dépasse la limite.',
        currentSpeakerRequired: `L'intervenant actuel est requis.`,
        chromeIsRequired: `Chrome est requis pour l'hébergement.`,
        roomHasNewHost: 'La salle a un nouvel hôte.',
        roomCodeRegistered: 'Le code de la salle est actuellement enregistré.',
        roomCodeAlreadyAdded: 'Code de la salle déjà ajouté.',
        invalidRoomCode: 'Code de la salle invalide.',
        newCardAlreadyCreated: 'Nouvelle carte déjà créée.',
        cannotRemovePrimaryCardWithActiveSubscriptions:
          'Impossible de supprimer la carte principale avec des abonnements actifs.',
        roomCodeNotFound: 'Code de la salle introuvable.',
        browserAlreadyConnected: 'Le navigateur est déjà connecté.',
        waitForConnection: 'Veuillez attendre une connexion.',
        requiresArchivePath: `Nécessite un chemin d'archive`,
        roomCodeNotAvailable: `Le code de la salle n'est pas disponible. Veuillez contacter le service client.`,
        limitReached: 'Limite atteinte',

        locationIsIncomplete: 'La localisation est incomplète.',
        tagsIsIncomplete: '1 balise est requise',
        languageIsIncomplete: 'La langue est incomplète.',
        cannotInteractWithYourOwnAccount:
          'Vous ne pouvez pas interagir avec votre propre compte.',
        cannotInteractWithBlockedAccount:
          'Vous ne pouvez pas interagir avec un compte bloqué.',
        draftLimit: `Vous avez atteint le nombre maximum de brouillons d'événements.`,
        roomCodeSubscriptionRequired:
          'Un abonnement au code de chambre MyndFull est requis.',
        completeRoomLanguages: 'Veuillez compléter les langues de la salle.',
        cannotOverwriteFile: `Impossible d'écraser le fichier existant`,
        selectionRequired: 'Sélection requise',
        participantDataIncomplete:
          'Les données des participants sont incomplètes.',
        unavailableDemo: 'Non disponible en démo',
        emailAlreadyRegistered: 'E-mail est déjà enregistrée.',
      };
    }

    return translation;
  };
  return {
    ErrorTranslations: ErrorTranslations(),
    SuccessTranslations: SuccessTranslations(),
    WarningTranslations: WarningTranslations(),
  };
};

export default useLanguageApiStatus;
