import { useEffect, useState } from 'react';

//Hooks
import { useApiStatus } from '../../../../../context/ApiStatusProvider';
import { useRegistrationHandlers } from '../../useRegistrationHandlers';

//language
import useLanguageRegistration from '../../../../../language/features/useLanguageRegistration';

//Components
import CodeInput from '../../../../../components/CodeInput/CodeInput';

//Utility
import { axiosLimited } from '../../../../../axios/axios';

const PersonalAccessCode = ({ setPrelaunchPage }) => {
  //Hooks
  const { handleRegistrationSetGenericAccessCode } = useRegistrationHandlers();
  const { handleError } = useApiStatus();
  const { handleRegistrationPageIncrease } = useRegistrationHandlers();

  //language
  const { AccessCode } = useLanguageRegistration();

  //UI state
  const [accessCodeCheckIsLoading, setAccessCodeCheckIsLoading] =
    useState(false);
  const [resetCode, setResetCode] = useState(false);

  useEffect(() => {
    if (resetCode) {
      setResetCode(false);
    }
  }, [resetCode]); //resets code component after attempt

  async function handleCode(accessCode) {
    try {
      setAccessCodeCheckIsLoading(true);
      await axiosLimited.get(
        `/main/register/softlaunch/accessCodeValidation/${accessCode}`,
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );
      setAccessCodeCheckIsLoading(false);
      handleRegistrationSetGenericAccessCode(accessCode);
      handleRegistrationPageIncrease();
      setResetCode(true);
    } catch (error) {
      const newError = {
        message: error.response?.data?.message || error?.message,
        id: Date.now(),
        origin: 'AccessCodeInput.js/handleAccessCodeValidation',
      };
      setAccessCodeCheckIsLoading(false);
      handleError(newError);
      setResetCode(true);
    }
  }

  return (
    <div className="flex-column registration-base-text">
      <h1 className="access-ob access-o6" tabIndex="0">
        {AccessCode.earlyAccessRegistration}
      </h1>
      <p className="mrg-t12 access-ob access-o6" tabIndex="0">
        {AccessCode.earlyAccessDescription}
      </p>

      <button
        className="registration-action-button mrg-t36"
        onClick={() => setPrelaunchPage(1)}
      >
        {AccessCode.signUp}
      </button>

      <div className="full-width divider mrg-tb48" />

      <h1 id="accessCodeLabel">{AccessCode.accessCodeDescription}</h1>

      <div className="h24" />

      <CodeInput
        handleCodeInput={handleCode}
        codeLength={6}
        ariaDescription={AccessCode.instructions}
        isLoadingExternal={accessCodeCheckIsLoading}
        toUpperCase={false}
        resetCode={resetCode}
      />
    </div>
  );
};

export default PersonalAccessCode;
