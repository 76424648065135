const FindEventsToolbarInteractionSummary = ({
  toolbarActiveHighlight,
  side,
  ExploreMyndFullFeatures,
}) => {
  return (
    <div
      className={`demo-toolbar-highlight-description ${
        side === 'left'
          ? 'demo-toolbar-highlight-description--left'
          : 'demo-toolbar-highlight-description--right'
      }`}
    >
      {side === 'left' && toolbarActiveHighlight === 'view' && (
        <div className="flex-column">
          <h4>{ExploreMyndFullFeatures.views}</h4>
          <p>{ExploreMyndFullFeatures.toolTipViews}</p>
        </div>
      )}

      {side === 'right' && toolbarActiveHighlight === 'sorting' && (
        <div className="flex-column">
          <h4>{ExploreMyndFullFeatures.sorting}</h4>
          <p>{ExploreMyndFullFeatures.toolTipSorting}</p>
        </div>
      )}
      {side === 'left' && toolbarActiveHighlight === 'tags' && (
        <div className="flex-column">
          <h4>{ExploreMyndFullFeatures.tags}</h4>
          <p>{ExploreMyndFullFeatures.toolTipTags}</p>
        </div>
      )}
    </div>
  );
};

export default FindEventsToolbarInteractionSummary;
