import { useEffect, useRef, useState } from 'react';

//hooks
import { useSettings } from '../../../context/SettingsProvider';

//language
import useLanguagePublic from '../../../language/features/useLanguagePublic';

//components
import ExploreFeaturesNavBtns from './ExploreFeatures/ExploreFeaturesNavBtns';
import ExploreFeaturesButtons from './ExploreFeatures/ExploreFeaturesButtons';
import ExploreFeaturesFindEvents from './ExploreFeatures/FindEvents/ExploreFeaturesFindEvents';
import ExploreFeaturesSpeaker from './ExploreFeatures/Speaker/ExploreFeaturesSpeaker';
import ExploreFeaturesOrganize from './ExploreFeatures/Organize/ExploreFeaturesOrganize';
import FindEventsToolbar from './ExploreFeatures/FindEvents/FindEventsToolbar';

//utils
import ScrollTo from '../../../utils/UI/ScrollTo';

const ExploreFeatures = () => {
  //hooks
  const { width } = useSettings();

  //language
  const { ExploreMyndFullFeatures } = useLanguagePublic();

  //state
  const [exploreFeature, setExploreFeature] = useState('findEvents');
  const [initialized, setInitialized] = useState(false);
  //UI
  const [currentIndex, setCurrentIndex] = useState(0);

  //ref
  const scrollTimer = useRef();
  const initializationTimer = useRef();

  useEffect(() => {
    initializationTimer.current = setTimeout(() => {
      setInitialized(true);
    }, 1000);

    return () => {
      if (scrollTimer.current) {
        clearTimeout(scrollTimer.current);
        clearTimeout(initializationTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (width <= 900 && initialized) {
      ScrollTo({
        focusId: 'features-wrapper',
        behavior: 'smooth',
        scrollToElementId: 'features-wrapper',
      });
    }
  }, [currentIndex]); //auto scroll for mobile

  function changeExploreTopic(newExploreFeature) {
    if (newExploreFeature === 'findEvents') {
      if (exploreFeature !== 'findEvents') {
        setCurrentIndex(0);
      }
      setExploreFeature('findEvents');
    }

    if (newExploreFeature === 'organize') {
      if (exploreFeature !== 'organize') {
        setCurrentIndex(0);
      }
      setExploreFeature('organize');
    }

    if (newExploreFeature === 'speaker') {
      if (exploreFeature !== 'speaker') {
        setCurrentIndex(0);
      }
      setExploreFeature('speaker');
    }
  }

  return (
    <div className="explore-features-padding-bottom bg-cream">
      <div className="explore-features bg-cream">
        <div className="explore-base-text flex-center">
          <h2
            className="text-center mrg-b48 access-ob access-o6"
            id="exploreFeatures"
            tabIndex="0"
          >
            {ExploreMyndFullFeatures.title}
          </h2>
        </div>
        <ExploreFeaturesButtons
          exploreFeature={exploreFeature}
          changeExploreTopic={changeExploreTopic}
          ExploreMyndFullFeatures={ExploreMyndFullFeatures}
        />
        {width > 900 && (
          <ExploreFeaturesNavBtns
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            exploreFeature={exploreFeature}
          />
        )}

        <div className={`${width <= 900 ? 'mrg-t24' : ''}`}>
          {exploreFeature === 'findEvents' && (
            <ExploreFeaturesFindEvents
              currentIndex={currentIndex}
              ExploreMyndFullFeatures={ExploreMyndFullFeatures}
            />
          )}
          {exploreFeature === 'organize' && (
            <ExploreFeaturesOrganize
              currentIndex={currentIndex}
              ExploreMyndFullFeatures={ExploreMyndFullFeatures}
            />
          )}
          {exploreFeature === 'speaker' && (
            <ExploreFeaturesSpeaker
              currentIndex={currentIndex}
              ExploreMyndFullFeatures={ExploreMyndFullFeatures}
            />
          )}
        </div>

        {width <= 900 &&
          !(exploreFeature === 'findEvents' && currentIndex === 0) && (
            <ExploreFeaturesNavBtns
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              exploreFeature={exploreFeature}
            />
          )}
      </div>

      <div className="full-width bg-cream">
        {exploreFeature === 'findEvents' && currentIndex === 0 && (
          <>
            <FindEventsToolbar
              ExploreMyndFullFeatures={ExploreMyndFullFeatures}
            />
            {width <= 900 && (
              <div className="explore-features-padding-lr">
                <div className="h24" />
                <ExploreFeaturesNavBtns
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  exploreFeature={exploreFeature}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ExploreFeatures;
