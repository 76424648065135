//hooks
import { useGenericModal } from '../../../../../context/GenericModalProvider';

//language
import useLanguageData from '../../../../../language/useLanguageData';
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//components
import DemoEventsViewsSummaryMobile from './DemoComponents/DemoEventsViewsSummaryMobile';
import DemoSearchSummary from './DemoComponents/DemoSearchSummary';
import DemoSettingsDekstop from './DemoSettingsDekstop';
import DemoSettingsMobile from './DemoSettingsMobile';
import DemoViews from './DemoComponents/DemoViews';
import InfoButton from '../../../../../components/InfoButton/InfoButton';
import InfoMobileModal from '../../../../../components/Modal/ModalComponents/InfoMobileModal';

//utils
import {
  calendar,
  direction,
  distance,
  globe,
  globe2,
  img_building3,
  img_social,
  star,
} from '../../../../../assets/icons';
import useLanguageSettings from '../../../../../language/features/useLanguageSettings';

const DemoEventSettings = ({
  ExploreMyndFullFeatures,
  view,
  setView,
  setToolbarActiveHighlight,
  toolbarActiveHighlight,
  searchDirection,
  setSearchDirection,
  searchFormat,
  setSearchFormat,
  searchCategory,
  setSearchCategory,
  settingsListOpened,
  setSettingsListOpened,
  demoBigTags,
  demoTinyTags,
  demoTinyTagGroups,
  demoCommunityFollowing,
  demoCommunityFollowingGroups,
  selectedMyEvents,
  selectedBigTags,
  selectedTinyTags,
  selectedTinyTagGroupIds,
  selectedFollowingIds,
  selectedFollowingGroupIds,
  width,
  selectAllBTBtn,
  removeSelectedBTs,
  addSelectedBTs,
  removeAllSelectedBTs,
  setSelectAllBTBtn,
  selectAllMyEventsBtn,
  setSelectedMyEvents,
  setSelectAllMyEventsBtn,
  selectAllTTBtn,
  removeSelectedTinyTags,
  addSelectedTinyTags,
  removeSelectedTinyTagGroupIds,
  addSelectedTinyTagGroupIds,
  removeAllSelectedTinyTagGroupIds,
  setSelectAllTTBtn,
  removeAllSelectedTinyTags,
  selectAllAccountsFollowingBtn,
  setSelectAllAccountsFollowingBtn,
  removeAllSelectedAccountsFollowingIds,
  removeSelectedAccountsFollowingId,
  addSelectedAccountsFollowingId,
  removeSelectedAccountsFollowingGroupIds,
  addSelectedAccountsFollowingGroupIds,
  removeAllSelectedAccountsFollowingGroupIds,
}) => {
  //hooks
  const { setGenericModalMobileFloat } = useGenericModal();

  //language
  const { BigTagIcon, TinyTagIcon } = useLanguageData();
  const { IconAlts } = useLanguageComponents();
  const { SettingsEventsLanguage } = useLanguageSettings();

  //function
  function handleToolTip(toolTip) {
    const viewsDisplay = (
      <div className="flex-column">
        <p className="fs18">{ExploreMyndFullFeatures.toolTipViews}</p>
        <div className="flex-row align-center  mrg-t24">
          <img
            src={star}
            alt={IconAlts.iconStar}
            style={{ height: '21px' }}
            className="mrg-r12 filter-theme"
          />
          <p>{ExploreMyndFullFeatures.myEvents}</p>
        </div>
        <div className="flex-row align-center mrg-t12">
          <img
            src={BigTagIcon}
            alt={IconAlts.iconBigTag}
            style={{ height: '21px' }}
            className="mrg-r12"
          />
          <p>{ExploreMyndFullFeatures.bigTagEvents}</p>
        </div>
        <div className="flex-row align-center mrg-t12">
          <img
            src={TinyTagIcon}
            alt={IconAlts.iconTinyTag}
            style={{ height: '32px' }}
            className="mrg-r12"
          />
          <p>{ExploreMyndFullFeatures.tinyTagEvents}</p>
        </div>
        <div className="flex-row align-center mrg-t12">
          <img
            src={img_social}
            alt={IconAlts.iconCommunity}
            style={{ height: '21px' }}
            className="filter-theme mrg-r12"
          />
          <p>{ExploreMyndFullFeatures.communityEvents}</p>
        </div>
      </div>
    );

    const sortingFiltersDisplay = (
      <div className="flex-column">
        <p className="fs18">{ExploreMyndFullFeatures.toolTipSorting}</p>
        <div className="flex-row align-center mrg-t24">
          <img
            src={direction}
            alt={SettingsEventsLanguage.ascending}
            style={{ height: '32px', width: '32px' }}
            className="mrg-r12"
          />
          <p>{ExploreMyndFullFeatures.sortDirection}</p>
        </div>
        <div className="flex-row align-center mrg-t12">
          <img
            src={calendar}
            alt={SettingsEventsLanguage.date}
            style={{ height: '21px', width: '32px' }}
            className="mrg-r12"
          />
          <p>{ExploreMyndFullFeatures.sortByDate}</p>
        </div>
        <div className="flex-row align-center mrg-t12">
          <img
            src={distance}
            alt={SettingsEventsLanguage.searchDistance}
            style={{ height: '42px', width: '32px' }}
            className="mrg-r12"
          />
          <p>{ExploreMyndFullFeatures.sortByDistance}</p>
        </div>
        <div className="flex-row align-center mrg-t12">
          <img
            src={img_building3}
            alt={SettingsEventsLanguage.searchFormatInPerson}
            style={{ height: '21px', width: '32px' }}
            className="filter-theme mrg-r12"
          />
          <p>{ExploreMyndFullFeatures.inPersonEventsHybrids}</p>
        </div>
        <div className="flex-row align-center mrg-t12">
          <img
            src={globe2}
            alt={SettingsEventsLanguage.searchFormatOnline}
            style={{ height: '21px', width: '32px' }}
            className="filter-theme mrg-r12"
          />
          <p>{ExploreMyndFullFeatures.virtualEventsHybrids}</p>
        </div>
      </div>
    );

    if (toolTip === 'views') {
      setGenericModalMobileFloat(
        <InfoMobileModal
          headingText={ExploreMyndFullFeatures.views}
          focusId={'view-myevents'}
          displayElement={viewsDisplay}
        />
      );
    }

    if (toolTip === 'sorting') {
      setGenericModalMobileFloat(
        <InfoMobileModal
          headingText={ExploreMyndFullFeatures.sorting}
          focusId={'filter-ascending'}
          displayElement={sortingFiltersDisplay}
        />
      );
    }
    if (toolTip === 'tags') {
      setGenericModalMobileFloat(
        <InfoMobileModal
          headingText={ExploreMyndFullFeatures.tags}
          text={ExploreMyndFullFeatures.toolTipTags}
          focusId={'settings-tags-container'}
        />
      );
    }
  }

  return (
    <>
      {width >= 1285 ? (
        <DemoSettingsDekstop
          view={view}
          setView={setView}
          setToolbarActiveHighlight={setToolbarActiveHighlight}
          searchDirection={searchDirection}
          setSearchDirection={setSearchDirection}
          searchFormat={searchFormat}
          setSearchFormat={setSearchFormat}
          searchCategory={searchCategory}
          setSearchCategory={setSearchCategory}
          settingsListOpened={settingsListOpened}
          setSettingsListOpened={setSettingsListOpened}
          demoBigTags={demoBigTags}
          demoTinyTags={demoTinyTags}
          demoTinyTagGroups={demoTinyTagGroups}
          demoCommunityFollowing={demoCommunityFollowing}
          demoCommunityFollowingGroups={demoCommunityFollowingGroups}
          selectedMyEvents={selectedMyEvents}
          selectedBigTags={selectedBigTags}
          selectedTinyTags={selectedTinyTags}
          selectedTinyTagGroupIds={selectedTinyTagGroupIds}
          selectedFollowingIds={selectedFollowingIds}
          selectedFollowingGroupIds={selectedFollowingGroupIds}
          width={width}
          selectAllBTBtn={selectAllBTBtn}
          removeSelectedBTs={removeSelectedBTs}
          addSelectedBTs={addSelectedBTs}
          removeAllSelectedBTs={removeAllSelectedBTs}
          setSelectAllBTBtn={setSelectAllBTBtn}
          selectAllMyEventsBtn={selectAllMyEventsBtn}
          setSelectedMyEvents={setSelectedMyEvents}
          setSelectAllMyEventsBtn={setSelectAllMyEventsBtn}
          selectAllTTBtn={selectAllTTBtn}
          removeSelectedTinyTags={removeSelectedTinyTags}
          addSelectedTinyTags={addSelectedTinyTags}
          removeSelectedTinyTagGroupIds={removeSelectedTinyTagGroupIds}
          addSelectedTinyTagGroupIds={addSelectedTinyTagGroupIds}
          removeAllSelectedTinyTagGroupIds={removeAllSelectedTinyTagGroupIds}
          setSelectAllTTBtn={setSelectAllTTBtn}
          removeAllSelectedTinyTags={removeAllSelectedTinyTags}
          selectAllAccountsFollowingBtn={selectAllAccountsFollowingBtn}
          setSelectAllAccountsFollowingBtn={setSelectAllAccountsFollowingBtn}
          removeAllSelectedAccountsFollowingIds={
            removeAllSelectedAccountsFollowingIds
          }
          removeSelectedAccountsFollowingId={removeSelectedAccountsFollowingId}
          addSelectedAccountsFollowingId={addSelectedAccountsFollowingId}
          removeSelectedAccountsFollowingGroupIds={
            removeSelectedAccountsFollowingGroupIds
          }
          addSelectedAccountsFollowingGroupIds={
            addSelectedAccountsFollowingGroupIds
          }
          removeAllSelectedAccountsFollowingGroupIds={
            removeAllSelectedAccountsFollowingGroupIds
          }
        />
      ) : (
        <div className="demo-search-sort--mobile no-select mrg-t12">
          <div className={`pos-rel pad-t12 pad-b12`}>
            <DemoViews
              view={view}
              setView={setView}
              setToolbarActiveHighlight={setToolbarActiveHighlight}
              width={width}
              toolbarActiveHighlight={toolbarActiveHighlight}
            />
            {toolbarActiveHighlight === 'view' && (
              <InfoButton
                positionClass={'info-button-demo-views'}
                handler={() => handleToolTip('views')}
                id={'info-btn-views'}
              />
            )}
          </div>
          <div className="pos-rel">
            <DemoEventsViewsSummaryMobile view={view} />
          </div>
          <DemoSearchSummary
            width={width}
            searchCategory={searchCategory}
            searchDirection={searchDirection}
            searchFormat={searchFormat}
            view={view}
          />
          <DemoSettingsMobile
            view={view}
            toolbarActiveHighlight={toolbarActiveHighlight}
            handleToolTip={handleToolTip}
            searchDirection={searchDirection}
            setSearchDirection={setSearchDirection}
            searchFormat={searchFormat}
            setSearchFormat={setSearchFormat}
            searchCategory={searchCategory}
            setSearchCategory={setSearchCategory}
            demoBigTags={demoBigTags}
            demoTinyTags={demoTinyTags}
            demoTinyTagGroups={demoTinyTagGroups}
            demoCommunityFollowing={demoCommunityFollowing}
            demoCommunityFollowingGroups={demoCommunityFollowingGroups}
            selectedMyEvents={selectedMyEvents}
            selectedBigTags={selectedBigTags}
            selectedTinyTags={selectedTinyTags}
            selectedTinyTagGroupIds={selectedTinyTagGroupIds}
            selectedFollowingIds={selectedFollowingIds}
            selectedFollowingGroupIds={selectedFollowingGroupIds}
            selectAllBTBtn={selectAllBTBtn}
            removeSelectedBTs={removeSelectedBTs}
            addSelectedBTs={addSelectedBTs}
            removeAllSelectedBTs={removeAllSelectedBTs}
            setSelectAllBTBtn={setSelectAllBTBtn}
            selectAllMyEventsBtn={selectAllMyEventsBtn}
            setSelectedMyEvents={setSelectedMyEvents}
            setSelectAllMyEventsBtn={setSelectAllMyEventsBtn}
            selectAllTTBtn={selectAllTTBtn}
            removeSelectedTinyTags={removeSelectedTinyTags}
            addSelectedTinyTags={addSelectedTinyTags}
            removeSelectedTinyTagGroupIds={removeSelectedTinyTagGroupIds}
            addSelectedTinyTagGroupIds={addSelectedTinyTagGroupIds}
            removeAllSelectedTinyTagGroupIds={removeAllSelectedTinyTagGroupIds}
            setSelectAllTTBtn={setSelectAllTTBtn}
            removeAllSelectedTinyTags={removeAllSelectedTinyTags}
            selectAllAccountsFollowingBtn={selectAllAccountsFollowingBtn}
            setSelectAllAccountsFollowingBtn={setSelectAllAccountsFollowingBtn}
            removeAllSelectedAccountsFollowingIds={
              removeAllSelectedAccountsFollowingIds
            }
            removeSelectedAccountsFollowingId={
              removeSelectedAccountsFollowingId
            }
            addSelectedAccountsFollowingId={addSelectedAccountsFollowingId}
            removeSelectedAccountsFollowingGroupIds={
              removeSelectedAccountsFollowingGroupIds
            }
            addSelectedAccountsFollowingGroupIds={
              addSelectedAccountsFollowingGroupIds
            }
            removeAllSelectedAccountsFollowingGroupIds={
              removeAllSelectedAccountsFollowingGroupIds
            }
          />
        </div>
      )}
    </>
  );
};

export default DemoEventSettings;
