import { Fragment } from 'react';

export const textUI = (text, customClass, tabOff) => {
  return (
    <p
      tabIndex={tabOff ? '' : '0'}
      className={` ${customClass ? customClass : ''} access-ob access-o6`}
    >
      {text?.split('\n')?.map((line, index) => (
        <Fragment key={`${index}-${Math.random()}`}>
          {line}
          <br />
        </Fragment>
      ))}
    </p>
  );
};
