import { useEffect, useRef } from 'react';
//hooks
import { useRegistrationHandlers } from '../useRegistrationHandlers';

//language
import useLanguageRegistration from '../../../../language/features/useLanguageRegistration';

//components
import ButtonSwitch from '../../../../components/ButtonSwitch/ButtonSwitch';
import NavigationArrows from '../../../../components/NavigationArrows/NavigationArrows';

//utility
const RegistrationSelections = () => {
  const {
    handleRegistrationPageIncrease,
    handleRegistrationPageDecrease,
    registrationTranslationRoomCodesOptIn,
    handleRegistrationSetSelectionsTranslationRoomCodesOptIn,
    registrationAttendEventsOptIn,
    handleRegistrationSetSelectionsAttendEventsOptIn,
    registrationSpeakerEventsOptIn,
    handleRegistrationSetSelectionsSpeakerEventsOptIn,
    registrationOrganizeEventsOptIn,
    handleRegistrationSetSelectionsOrganizeEventsOptIn,
    handleRegistrationSetComponentArr,
    registrationAccountType,
  } = useRegistrationHandlers();

  //language
  const { InitializeRegistrationPersonal } = useLanguageRegistration();

  //variable
  const initializeRegistrationSelectionsEffectRan = useRef(false);

  //initialize
  useEffect(() => {
    if (initializeRegistrationSelectionsEffectRan.current === false) {
      initializeRegistrationSelectionsEffectRan.current = true;
      handleRegistrationSelections();
    }
  }, []);

  useEffect(() => {
    handleRegistrationSelections();
  }, [
    registrationTranslationRoomCodesOptIn,
    registrationAttendEventsOptIn,
    registrationSpeakerEventsOptIn,
    registrationOrganizeEventsOptIn,
  ]);

  //functions
  function handleRegistrationSelections() {
    if (registrationAccountType === 'personal') {
      let newComponentsArr = ['personalAccountInfo'];

      if (registrationAttendEventsOptIn) {
        newComponentsArr.push('personalSearchSettings');
      }

      if (registrationTranslationRoomCodesOptIn) {
        newComponentsArr.push('translationRoomCodeRegistration');
        newComponentsArr.push('billing');
      }

      newComponentsArr.push('emailPreferences');
      handleRegistrationSetComponentArr(newComponentsArr);
    } else if (registrationAccountType === 'institutional') {
      // let newComponentsArr = ['institutionalAccountInfo'];
    }
  }

  return (
    <div className="flex-center--ph flex-column">
      <label
        className="content-label"
        aria-label={`${InitializeRegistrationPersonal.heading}.`}
      >
        <p className="fs21 fwb">{InitializeRegistrationPersonal.heading}</p>
        <p className="fs18 fwn color-black mrg-t12">
          {InitializeRegistrationPersonal.heading2}
        </p>
      </label>
      <div className="divider full-width mrg-t24 mrg-b12" />
      <div className="flex-column mrg-tb24">
        <ButtonSwitch
          id={'translationRoomCodes'}
          title={InitializeRegistrationPersonal.translationRoomCodes}
          handleSwitch={
            handleRegistrationSetSelectionsTranslationRoomCodesOptIn
          }
          noWrap={true}
          loadTrigger={true}
          loadValue={registrationTranslationRoomCodesOptIn}
          horizontal={true}
        />
        <ul className="fs18 fwn mrg-t12 mrg-l24">
          <li>
            {InitializeRegistrationPersonal.translationRoomCodesDescription1}
          </li>
        </ul>

        <div className="h36" />
        <ButtonSwitch
          id={'attendEvents'}
          title={InitializeRegistrationPersonal.attendEvents}
          handleSwitch={handleRegistrationSetSelectionsAttendEventsOptIn}
          noWrap={true}
          loadTrigger={true}
          loadValue={registrationAttendEventsOptIn}
          horizontal={true}
        />
        <ul className="fs18 fwn mrg-tb12 mrg-l24">
          <li>{InitializeRegistrationPersonal.attendEventsDescription1}</li>
          <li className="mrg-t6">
            {InitializeRegistrationPersonal.attendEventsDescription2}
          </li>
          <li className="mrg-t6">
            {InitializeRegistrationPersonal.attendEventsDescription3}
          </li>
        </ul>

        <div className="h36" />
        <div className="opacity-05 disable-select">
          <ButtonSwitch
            id={'speakEvents'}
            title={InitializeRegistrationPersonal.speakerEvents}
            handleSwitch={handleRegistrationSetSelectionsSpeakerEventsOptIn}
            noWrap={true}
            loadTrigger={true}
            loadValue={registrationSpeakerEventsOptIn}
            horizontal={true}
          />
          <ul className="fs18 fwn mrg-tb12 mrg-l24">
            <li>{InitializeRegistrationPersonal.speakerEventsDescription1}</li>
            <li className="mrg-t6">
              {InitializeRegistrationPersonal.speakerEventsDescription2}
            </li>
          </ul>

          <div className="h36" />
          <ButtonSwitch
            id={'organizeEvents'}
            title={InitializeRegistrationPersonal.organizeEvents}
            handleSwitch={handleRegistrationSetSelectionsOrganizeEventsOptIn}
            noWrap={true}
            loadTrigger={true}
            loadValue={registrationOrganizeEventsOptIn}
            horizontal={true}
          />
          <ul className="fs18 fwn mrg-tb12 mrg-l24">
            <li>{InitializeRegistrationPersonal.organizeEventsDescription1}</li>
            <li className="mrg-t6">
              {InitializeRegistrationPersonal.organizeEventsDescription2}
            </li>
          </ul>
        </div>
      </div>

      <NavigationArrows
        continueBtnCheck={true}
        handlePageDecrease={handleRegistrationPageDecrease}
        handlePageIncrease={handleRegistrationPageIncrease}
        customWrapper={'full-width'}
        previousIsExit={true}
      />
    </div>
  );
};

export default RegistrationSelections;
