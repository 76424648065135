import { useEffect, useState } from 'react';

//language
import useLanguageEvents from '../../language/features/useLanguageEvents';
import useLanguageComponents from '../../language/useLanguageComponents';

//Utility
import { arrow_long, img_building3, img_virtual } from '../../assets/icons';

const EventStatusBar = ({
  eventData,
  eventStatus,
  inPerson,
  virtual,
  isParticipant,
  isDraft,
}) => {
  //language
  const { EventCard } = useLanguageEvents();
  const { EventStatusBarSwitch, IconAlts } = useLanguageComponents();

  //Component State
  const [inPersonStatus, setInPersonStatus] = useState();
  const [virtualStatus, setVirtualStatus] = useState();
  const [registrationStatus, setRegistrationStatus] = useState();

  useEffect(() => {
    handleEventBarStatus();
  }, [eventStatus, isParticipant, isDraft]);

  function handleEventBarStatus() {
    let inPersonStatus;
    let virtualStatus;

    //can skip directly to JSX
    if (isParticipant || isDraft) {
      setInPersonStatus();
      setVirtualStatus();
      return setRegistrationStatus();
    }

    //user has some kind of registration
    if (
      eventStatus !== 'neutral' &&
      eventStatus !== 'saved' &&
      eventStatus !== 'ignored'
    ) {
      setInPersonStatus();
      setVirtualStatus();
      return setRegistrationStatus(eventStatus);
    }

    //no registration - check event status ('open, almostfull, full')
    if (eventData.registrationData.registrationsActive && inPerson) {
      if (
        eventData.registrationData?.registeredAttendeesInPerson?.length >=
          eventData.registrationData?.maxRegistrationsInPersonAmount &&
        eventData.registrationData?.maxRegistrationsInPersonEnabled
      ) {
        inPersonStatus = 'full';
      } else if (
        eventData.registrationData?.maxRegistrationsInPersonAmount / 2 <=
          eventData.registrationData?.registeredAttendeesInPerson?.length &&
        eventData.registrationData?.maxRegistrationsInPersonEnabled
      ) {
        inPersonStatus = 'almostFull';
      } else {
        inPersonStatus = 'open';
      }
    }

    if (eventData.registrationData.registrationsActive && virtual) {
      if (
        eventData.registrationData?.registeredAttendeesVirtual?.length >=
          eventData.registrationData?.maxRegistrationsVirtualAmount &&
        eventData.registrationData?.maxRegistrationsVirtualEnabled
      ) {
        virtualStatus = 'full';
      } else if (
        eventData.registrationData?.maxRegistrationsVirtualAmount / 2 <=
          eventData.registrationData?.registeredAttendeesVirtual?.length &&
        eventData.registrationData?.maxRegistrationsVirtualEnabled
      ) {
        virtualStatus = 'almostFull';
      } else {
        virtualStatus = 'open';
      }
    }

    setInPersonStatus(inPersonStatus);
    setVirtualStatus(virtualStatus);
  }

  return (
    <div className="full-width flex-row flex-end">
      {isParticipant && (
        <div
          className="status-bar status-color-registered access-ob access-o6"
          tabIndex="0"
        >
          {EventCard.participant}
        </div>
      )}

      {isDraft && (
        <div
          className="status-bar status-color-draft access-ob access-o6"
          tabIndex="0"
        >
          {EventCard.draft}
        </div>
      )}

      {inPersonStatus === 'full' && virtualStatus === 'full' && (
        <div
          className="status-bar status-color-full access-ob access-o6"
          tabIndex="0"
        >
          <img
            src={img_building3}
            className="filter-registration-full mrg-r4"
            alt={EventCard.inPersonIcon}
            loading="lazy"
          />
          /
          <img
            src={img_virtual}
            className="filter-registration-full mrg-r4"
            alt={EventCard.virtualIcon}
            loading="lazy"
          />
          {EventCard.full}
        </div>
      )}

      {inPersonStatus === 'almostFull' && virtualStatus === 'almostFull' && (
        <div
          className="status-bar status-color-almost-full access-ob access-o6"
          tabIndex="0"
        >
          <img
            src={img_building3}
            className="filter-registration-almost-full mrg-r4"
            alt={EventCard.inPersonIcon}
            loading="lazy"
          />
          <p className="fswb fs18 mrg-r4">/</p>
          <img
            src={img_virtual}
            className="filter-registration-almost-full mrg-r4"
            alt={EventCard.virtualIcon}
            loading="lazy"
          />
          {EventCard.almostFull}
        </div>
      )}

      {inPersonStatus === 'open' && virtualStatus === 'open' && (
        <div
          className="status-bar status-color-registration-open access-ob access-o6"
          tabIndex="0"
        >
          <img
            src={img_building3}
            className="filter-registration-open mrg-r4"
            alt={EventCard.inPersonIcon}
            loading="lazy"
          />
          <p className="fswb fs18 mrg-r4">/</p>
          <img
            src={img_virtual}
            className="filter-registration-open mrg-r4"
            alt={EventCard.virtualIcon}
            loading="lazy"
          />
          {EventCard.open}
        </div>
      )}

      {inPersonStatus === 'open' && virtualStatus !== 'open' && (
        <div
          className="status-bar status-color-registration-open access-ob access-o6"
          tabIndex="0"
        >
          <img
            src={img_building3}
            className="filter-registration-open mrg-r4"
            alt={EventCard.inPersonIcon}
            loading="lazy"
          />
          {EventCard.open}
        </div>
      )}

      {inPersonStatus === 'almostFull' && virtualStatus !== 'almostFull' && (
        <div
          className="status-bar status-color-almost-full access-ob access-o6"
          tabIndex="0"
        >
          <img
            src={img_building3}
            className="filter-registration-almost-full mrg-r4"
            alt={EventCard.inPersonIcon}
            loading="lazy"
          />
          {EventCard.almostFull}
        </div>
      )}

      {inPersonStatus === 'full' && virtualStatus !== 'full' && (
        <div
          className="status-bar status-color-full access-ob access-o6"
          tabIndex="0"
        >
          <img
            src={img_building3}
            className="filter-registration-full mrg-r4"
            alt={EventCard.inPersonIcon}
            loading="lazy"
          />
          {EventCard.full}
        </div>
      )}

      {virtualStatus === 'open' && inPersonStatus !== 'open' && (
        <div
          className="status-bar status-color-registration-open access-ob access-o6"
          tabIndex="0"
        >
          <img
            src={img_virtual}
            className="filter-registration-open mrg-r4"
            alt={EventCard.virtualIcon}
            loading="lazy"
          />
          {EventCard.open}
        </div>
      )}

      {virtualStatus === 'almostFull' && inPersonStatus !== 'almostFull' && (
        <div
          className="status-bar status-color-almost-full access-ob access-o6"
          tabIndex="0"
        >
          <img
            src={img_virtual}
            className="filter-registration-almost-full mrg-r4"
            alt={EventCard.virtualIcon}
            loading="lazy"
          />
          {EventCard.almostFull}
        </div>
      )}

      {virtualStatus === 'full' && inPersonStatus !== 'full' && (
        <div
          className="status-bar status-color-full access-ob access-o6"
          tabIndex="0"
        >
          <img
            src={img_virtual}
            className="filter-registration-full mrg-r4"
            alt={EventCard.virtualIcon}
            loading="lazy"
          />
          {EventCard.full}
        </div>
      )}

      {registrationStatus && (
        <>
          {(registrationStatus === 'pendingApprovalInPerson' ||
            registrationStatus === 'pendingApprovalVirtual') && (
            <div
              className="status-bar status-color-pending access-ob access-o6"
              tabIndex="0"
            >
              <img
                src={inPerson ? img_building3 : img_virtual}
                className="filter-registration-pending mrg-r4"
                alt={inPerson ? EventCard.inPersonIcon : EventCard.virtualIcon}
                loading="lazy"
              />
              {EventCard.pendingApproval}
            </div>
          )}

          {(registrationStatus === 'registeredInPerson' ||
            registrationStatus === 'registeredVirtual') && (
            <div
              className="status-bar status-color-registered access-ob access-o6"
              tabIndex="0"
            >
              <img
                src={inPerson ? img_building3 : img_virtual}
                className="filter-registration-registered mrg-r4"
                alt={inPerson ? EventCard.inPersonIcon : EventCard.virtualIcon}
                loading="lazy"
              />
              {EventCard.registered}
            </div>
          )}

          {(registrationStatus ===
            'pendingApprovalVirtual-registeredInPerson' ||
            registrationStatus ===
              'pendingApprovalInPerson-registeredVirtual') && (
            <div
              className="status-bar status-color-registered access-ob access-o6"
              tabIndex="0"
              aria-label={`${
                registrationStatus ===
                'pendingApprovalVirtual-registeredInPerson'
                  ? EventStatusBarSwitch.inPersonToVirtual
                  : EventStatusBarSwitch.virtualToInPerson
              }`}
              title={`${
                registrationStatus ===
                'pendingApprovalVirtual-registeredInPerson'
                  ? EventStatusBarSwitch.inPersonToVirtual
                  : EventStatusBarSwitch.virtualToInPerson
              }`}
            >
              {registrationStatus ===
              'pendingApprovalVirtual-registeredInPerson' ? (
                <img
                  src={img_building3}
                  alt={IconAlts.iconInPerson}
                  className="filter-registration-registered"
                  loading="lazy"
                />
              ) : (
                <img
                  src={img_virtual}
                  alt={IconAlts.iconVirtual}
                  className="filter-registration-registered"
                  loading="lazy"
                />
              )}

              <img
                src={arrow_long}
                alt={IconAlts.iconArrow}
                className="filter-gray filter-registration-registered rotate-cw90 mrg-lr12"
                loading="lazy"
              />

              {registrationStatus ===
              'pendingApprovalVirtual-registeredInPerson' ? (
                <img
                  src={img_virtual}
                  alt={IconAlts.iconVirtual}
                  className="filter-registration-registered"
                  loading="lazy"
                />
              ) : (
                <img
                  src={img_building3}
                  alt={IconAlts.iconInPerson}
                  className="filter-registration-registered"
                  loading="lazy"
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EventStatusBar;
