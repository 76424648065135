import { useEffect } from 'react';
//components
import ScrollToTop from '../../../components/ScrollToTop/ScrollToTop';

//utils
import ScrollTo from '../../../utils/UI/ScrollTo';
const PublicPrivacy = () => {
  useEffect(() => {
    ScrollTo({ scrollToTop: true, focusId: 'privacyPolicy' });
  }, []); //auto scroll to top

  return (
    <div className="public__wrapper public-font bg-cream">
      <h1 tabIndex="0" className="access-ob access-o6" id="privacyPolicy">
        Privacy Policy
      </h1>

      <p tabIndex="0" className="access-ob access-o6">
        Last updated on: April 3, 2025
      </p>

      <p tabIndex="0" className="access-ob access-o6">
        MyndFull Platforms Inc. (<span className="fwb">“MyndFull”</span>) is
        committed to protecting the privacy and security of your personal
        information obtained through your use of our online services.
      </p>
      <p tabIndex="0" className="access-ob access-o6">
        The MyndFull Privacy Policy (
        <span className="fwb">“Privacy Policy”</span>) explains the types of
        user personal information we collect, how it is used and the measures we
        have implemented to handle that information securely. This Privacy
        Policy applies to personal information acquired by us, through your use
        of our website, online platform and other interactions with you.{' '}
      </p>
      <p tabIndex="0" className="access-ob access-o6">
        We collect information necessary to provide, maintain and enhance your
        experience as a user of our platforms. Please review this Privacy Policy
        for specific details on what information we may collect and how we use
        it.
      </p>
      <p tabIndex="0" className="access-ob access-o6">
        MyndFull is responsible under applicable laws for adopting privacy
        policies to protect your personal information in a manner consistent
        with this Privacy Policy. Our policies and practices have been designed
        to comply with the Personal Information Protection and Electronic
        Documents Act (PIPEDA) and provincial private sector privacy acts. Our
        Privacy Policy may be amended from time to time. We encourage you to
        review the current Privacy Policy from time to time.
      </p>

      <h2 tabIndex="0" className="access-ob access-o6">
        Personal Information
      </h2>

      <p tabIndex="0" className="access-ob access-o6">
        Personal information is information about an identifiable individual.
        Personal information can include your name, e-mail address, phone
        number, date of birth, gender, financial information, as well as your
        opinions, preferences and user patterns.{' '}
      </p>

      <p tabIndex="0" className="access-ob access-o6">
        We collect different categories of personal information depending on how
        you interact with us. Types of personal information we collect:
      </p>

      <ul className="list-reg">
        <li>
          <p tabIndex="0" className="access-ob access-o6 underline">
            Information You Give Us:
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            You may provide us with information through your use of our
            services. This includes, but is not limited to, information you
            provide through the use of our website, desktop or mobile
            applications or the purchase and use of any products or services we
            offer. We may receive and store any information you provide. You can
            choose not to provide certain information to us, but then you might
            not be able to take advantage of all of our services.
          </p>
        </li>

        <li>
          <p tabIndex="0" className="access-ob access-o6 underline">
            Information Collected Automatically By Our Websites or Apps:
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            We automatically collect and store certain types of information
            about your use of our websites and apps, including information about
            your interaction with content and services. We may use "cookies" or
            other unique identifiers to collect this information.
          </p>

          <p tabIndex="0" className="access-ob access-o6">
            We collect the IP addresses of all visitors to our websites and
            other related information such as page requests, browser type,
            operating system and average time spent on our websites. We use this
            information to help us understand our website activity, and to
            monitor and improve our online services.
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            Cookies are small text files that contain a unique identifier which
            is placed on your computer when you visit a website. When you visit
            a website, the website saves the file with a matching identifier.
            When you revisit the site days or weeks later, the site can
            recognize you by matching the cookie on your computer with the
            matching identifier in its database. We use cookies on the pages on
            our website where you are prompted to log in or that are
            customizable. These cookies may let us know who you are and will
            provide us and our service providers with information (such as your
            language choice) that we use to personalize our website in
            accordance with your preferences. You may set your browser to notify
            you when you receive a cookie or to not accept certain cookies.
            However, if you decide not to accept cookies from our websites, you
            may not be able to take advantage of all of the features of our
            websites.
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            We may offer you the opportunity to engage with our content on or
            through third-party social networking websites, plug-ins and
            applications. When you engage with our content on or through
            third-party social networking websites, plug-ins and applications,
            you may allow us to have access to certain information associated
            with your social media account (e.g., name, username, email address,
            profile picture, gender) to deliver the content or as part of the
            operation of the website, plug-in or application. When you provide
            information from your social media account, we may use this
            information to personalize your experience on our websites and on
            the third-party social networking websites, plug-ins and
            applications, and to provide you with other products or services you
            may request.
          </p>
        </li>
        <li>
          <p tabIndex="0" className="access-ob access-o6 underline">
            Information from Other Sources:
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            We may receive information from third parties who provide us with
            services.
          </p>
        </li>
      </ul>

      <h2 tabIndex="0" className="access-ob access-o6">
        Privacy Principles
      </h2>

      <ul className="numbered-list">
        <li>
          <h3 tabIndex="0" className="access-ob access-o6">
            Accountability
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            MyndFull is responsible for all personal information in our
            possession, including any personal information transferred by us to
            third parties for processing, storage, or other purposes. In
            alignment with this principle, MyndFull has developed and
            implemented this Privacy Policy as part of our broader privacy and
            security framework.{' '}
          </p>
        </li>
        <li>
          <h3 tabIndex="0" className="access-ob access-o6">
            Identifying Purposes
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            MyndFull identifies the purpose for which your personal information
            is collected. We do this before or at the time your personal
            information is collected. We may collect, use and disclose your
            personal information in order to:
          </p>
          <ul className="">
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                process and administer your payment for the purchase of our
                services;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                provide a service;
              </p>
            </li>
            <li>
              <p tabIndex="0" className="access-ob access-o6">
                better understand your service needs and to offer relevant
                information and services, including sending you (and others on
                your behalf) communications by way of e-mail, telephone, text
                message, or other type of electronic message;{' '}
              </p>
            </li>

            <li>
              <p tabIndex="0" className="access-ob access-o6">
                support business purposes and programs for you and other users;
              </p>
            </li>

            <li>
              <p tabIndex="0" className="access-ob access-o6">
                solicit feedback to provide product insights, reviews, improve
                our services, and generate content for our website;{' '}
              </p>
            </li>

            <li>
              <p tabIndex="0" className="access-ob access-o6">
                determine your interest and eligibility for, and where
                appropriate, provide you with services;
              </p>
            </li>

            <li>
              <p tabIndex="0" className="access-ob access-o6">
                administer the delivery of services to you;
              </p>
            </li>

            <li>
              <p tabIndex="0" className="access-ob access-o6">
                track and analyze your account and transaction activity, and
                payment history for marketing analysis purposes or tailoring
                promotional offers to you;
              </p>
            </li>

            <li>
              <p tabIndex="0" className="access-ob access-o6">
                track and analyze website use to provide a better user
                experience;
              </p>
            </li>

            <li>
              <p tabIndex="0" className="access-ob access-o6">
                conduct surveys and analysis for research, statistical and
                product development purposes (information will be de-identified
                to the extent possible);
              </p>
            </li>

            <li>
              <p tabIndex="0" className="access-ob access-o6">
                verify your identity and protect against error and fraud;
              </p>
            </li>

            <li>
              <p tabIndex="0" className="access-ob access-o6">
                manage and assess our risk;
              </p>
            </li>

            <li>
              <p tabIndex="0" className="access-ob access-o6">
                process, service, analyze and audit your relationship with us;
              </p>
            </li>

            <li>
              <p tabIndex="0" className="access-ob access-o6">
                support promotions and contest administration;{' '}
              </p>
            </li>

            <li>
              <p tabIndex="0" className="access-ob access-o6">
                perform tests to implement or modify systems;
              </p>
            </li>

            <li>
              <p tabIndex="0" className="access-ob access-o6">
                comply with applicable legal, regulatory and self-regulatory
                requirements;{' '}
              </p>
            </li>

            <li>
              <p tabIndex="0" className="access-ob access-o6">
                respond to your questions, comments or requests to customer
                support; and
              </p>
            </li>

            <li>
              <p tabIndex="0" className="access-ob access-o6">
                achieve other purposes as may, from time to time, be permitted
                or required by law.
              </p>
            </li>
          </ul>
          <p tabIndex="0" className="access-ob access-o6">
            By providing your information to MyndFull, you consent to MyndFull
            using your personal information for the purposes outlined above.
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            Withdrawal of consent to collect, use and disclose your personal
            information may restrict our ability to provide you with our
            services, such as access to our desktop or mobile applications.
          </p>
        </li>

        <li>
          <h3 tabIndex="0" className="access-ob access-o6">
            Consent
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            MyndFull obtains your consent to collect, use or disclose your
            personal information, except as otherwise permitted by law. The
            method of obtaining consent may depend on the circumstances and the
            sensitivity of the information. Consent may be oral or written,
            express or implied. Your express consent (verbal, written or
            electronic agreement) is generally obtained to collect, use or
            disclose sensitive personal information.
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            At any time, you have a right to withdraw your consent by changing
            your communication choices, opting out from our communications or by
            contacting us.
          </p>
        </li>
        <li>
          <h3 tabIndex="0" className="access-ob access-o6">
            Limited Collection
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            MyndFull collects the information required to provide services to
            you and as otherwise outlined in this Privacy Policy. If the
            personal information we require is collected for a reason other than
            as outlined in this Privacy Policy, your consent will be obtained
            before or at the time the information is collected or as otherwise
            permitted by applicable law. MyndFull will collect personal
            information by clear, fair and lawful means.{' '}
          </p>
        </li>

        <li>
          <h3 tabIndex="0" className="access-ob access-o6">
            Limited Use, Disclosure and Retention
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            MyndFull uses and discloses your personal information for the
            purposes for which it was collected, except with your consent or as
            otherwise required or permitted by applicable law. We do not sell or
            rent personal information.{' '}
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            We may transfer your personal information to entities outside
            MyndFull, such as our vendors, suppliers and agents (
            <span className="fwb">“Service Providers”</span>) who require the
            information in order to perform a service for us. When your personal
            information is transferred to a Service Provider, we require them to
            protect the information in a manner that is consistent with this
            Privacy Policy. Our Service Providers may be located outside of
            Canada and may be required to disclose your personal information
            under the laws of their jurisdiction. You may contact us for
            information about our policies and practices regarding Service
            Providers to whom we have transferred your personal information and
            their locations (see Contact Information, below). MyndFull retains
            your personal information as long as it is required for our business
            relationship or as required by law. If you stop using our services
            or if you delete your account with us, we will delete your
            information or store your information in an aggregated and
            anonymized format.
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            In the event that MyndFull or substantially all of our assets are
            acquired by an unrelated third party, your personal information may
            be one of the transferred assets. By providing your personal
            information to us, you agree that we may disclose your personal
            information, on a confidential basis, to any prospective transferee
            and its professional advisors for the purposes of their due
            diligence investigations, the completion of any such transaction and
            the continued operation of the acquired business.
          </p>
          <p tabIndex="0" className="access-ob access-o6">
            MyndFull and Service Providers may provide your personal information
            in response to a search warrant or other legally valid inquiry or
            order, or to an investigative body in the case of a breach of an
            agreement or contravention of law, or as otherwise required or
            permitted by applicable Canadian or other law. We may also disclose
            personal information where necessary for the establishment, exercise
            or defence of legal claims and to investigate or prevent actual or
            suspected loss or harm to persons or property.
          </p>
        </li>
        <li>
          <h3 tabIndex="0" className="access-ob access-o6">
            Accuracy
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            Our objective is to keep your personal information up-to-date,
            accurate and relevant for its intended use. We rely on you to let us
            know if your e-mail address, telephone number or other information
            you provide us changes, so that we may provide you with the best
            possible service.{' '}
          </p>
        </li>
        <li>
          <h3 tabIndex="0" className="access-ob access-o6">
            Safeguards
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            Your privacy and the security of your personal information is
            important to us. We have implemented appropriate physical,
            technical, and administrative safeguards and security measures
            designed to protect your personal information. All of our Service
            Providers are required under their contracts with us to maintain
            your confidentiality and may not use your information for any
            unauthorized purpose. When we are required by law to provide
            information, we take reasonable steps to verify the lawful authority
            for the collection and we disclose only the information that is
            legally required. We review our procedures and security measures
            regularly to ensure that they are properly administered and remain
            effective and appropriate for the sensitivity of the information.
          </p>
        </li>
        <li>
          <h3 tabIndex="0" className="access-ob access-o6">
            Openness
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            We believe it is important that our practices regarding personal
            information are clear, easy to understand, and readily accessible to
            our users.
          </p>
        </li>
        <li>
          <h3 tabIndex="0" className="access-ob access-o6">
            Individual Access
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            You have the right to access, update and correct inaccuracies in
            your personal information in our custody or control. To access your
            personal information, a request must be submitted in writing to us
            (see Contact Information below). We will respond to your request for
            access or information in a reasonable time. There may be times when
            we are unable to fulfill your request - for example, if providing
            access to your personal information would reveal confidential
            commercial or proprietary information or personal information about
            someone else (and we are unable to separate your data), or if we are
            prohibited by law from disclosing the information. We may request
            certain personal information for the purposes of verifying the
            identity of the individual seeking access to their personal
            information records.
          </p>
        </li>
        <li>
          <h3 tabIndex="0" className="access-ob access-o6">
            Responsiveness
          </h3>
          <p tabIndex="0" className="access-ob access-o6">
            MyndFull responds in a reasonable time to your questions, concerns
            and complaints about the privacy or your personal information and
            our privacy policies and procedures. We will investigate and respond
            to any concern you have regarding the handling of your personal
            information. In most cases, an issue can be resolved simply by
            telling us about it and discussing any issues.
          </p>
        </li>
      </ul>

      <h2 tabIndex="0" className="access-ob access-o6">
        Contact Us
      </h2>
      <p tabIndex="0" className="access-ob access-o6">
        If your questions, concerns and complaints have not been resolved to
        your satisfaction or if you have further questions, you can contact
        MyndFull's Chief Privacy Officer by email at privacy@myndfull.com.
      </p>

      <ScrollToTop customWrapper={'mrg-t48 bg-color-white'} />
    </div>
  );
};

export default PublicPrivacy;
