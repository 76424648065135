import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Hooks
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';
import { useSettings } from '../../context/SettingsProvider';

//language
import useLanguageLayout from '../../language/useLanguageLayout';
import useLanguageData from '../../language/useLanguageData';

//Utils
import { dropdown_arrow, linkedin } from '../../assets/icons';

const Footer = ({ layoutIsMounting }) => {
  //Hooks
  const { accountId } = useAccountReduxHandlers();
  const navigate = useNavigate();
  const {
    year,
    windowSettingsSize,
    contentLanguage,
    setContentLanguage,
    handleMobileTap,
  } = useSettings();

  //language
  const { Footer } = useLanguageLayout();
  const { ContentLanguage, LanguageOpts } = useLanguageData();

  //Component state
  const [showLanguageOpts, setShowLanguageOpts] = useState(false);

  //UI state
  const [componentIsMounting, setComponentIsMounting] = useState(true);
  const [highlightTapLanguageOpt, setHighlightTapLanguageOpt] = useState(false);
  //Component variables
  // let languageSelectionMenu;

  //Initialize functions
  useEffect(() => {
    const handleClick = (e) => {
      const allowedIds = [
        'language-selection',
        'language-selection-text',
        'language-selection-img',
      ];

      if (showLanguageOpts && !allowedIds.includes(e.target.id)) {
        setShowLanguageOpts(false);
      }
    };

    // Attach the event listener
    document.addEventListener('click', handleClick);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [showLanguageOpts]); //closes language selection menu if opened

  //UI Transitions
  useEffect(() => {
    if (!layoutIsMounting) {
      const pageAnimationTimer = setTimeout(() => {
        setComponentIsMounting(false);
      }, 100);
      return () => clearTimeout(pageAnimationTimer);
    }
  }, [layoutIsMounting]);

  return (
    <footer
      className={`${
        componentIsMounting ? 'offscreen' : 'footer onscreen-fade-in-100ms'
      }`}
      aria-label={Footer.footer}
    >
      {windowSettingsSize !== 'desktop' &&
        windowSettingsSize !== 'desktop--narrow' &&
        !accountId && (
          <>
            <button
              className="modal-dropdown modal-dropdown--footer no-select fs18 fwsb color-white mrg-t12 access-ob access-o6"
              onClick={() => setShowLanguageOpts(!showLanguageOpts)}
              id="language-selection"
              tabIndex="0"
              aria-expanded={showLanguageOpts}
              aria-label={`${Footer.selectSiteLanguage} ${ContentLanguage}`}
            >
              {ContentLanguage}
              <img
                id="language-selection-img"
                src={dropdown_arrow}
                alt="arrow"
                className={`arrow svg ${
                  showLanguageOpts ? 'reverse-vertical' : ''
                }`}
                aria-hidden="true"
              />
            </button>
            {showLanguageOpts && (
              <ul className="modal-dropdown__button modal-dropdown__button--footer">
                <li>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMobileTap(
                        [
                          () => setHighlightTapLanguageOpt('en-CA'),
                          () => setHighlightTapLanguageOpt(),
                        ],
                        [
                          () => setContentLanguage('en-CA'),
                          () => setShowLanguageOpts(false),
                        ]
                      );
                    }}
                    className={`fs16 fwsb access-ob access-o-6 ${
                      contentLanguage === 'en-CA' ? 'selected' : ''
                    } ${
                      highlightTapLanguageOpt === 'en-CA'
                        ? 'highlight-bg-theme--tap'
                        : ''
                    }`}
                    tabIndex="0"
                  >
                    {LanguageOpts['en-CA']}
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMobileTap(
                        [
                          () => setHighlightTapLanguageOpt('fr-CA'),
                          () => setHighlightTapLanguageOpt(),
                        ],
                        [
                          () => setContentLanguage('fr-CA'),
                          () => setShowLanguageOpts(false),
                        ]
                      );
                    }}
                    className={`fs16 fwsb access-ob access-o-6 ${
                      contentLanguage === 'fr-CA' ? 'selected' : ''
                    } ${
                      highlightTapLanguageOpt === 'fr-CA'
                        ? 'highlight-bg-theme--tap'
                        : ''
                    }`}
                    tabIndex="0"
                  >
                    {LanguageOpts['fr-CA']}
                  </button>
                </li>
              </ul>
            )}
          </>
        )}
      <div className="footer__links fs18 fwn color-white">
        <button
          tabIndex="0"
          className="fwn fs18 fs18--ph color-white access-ob access-o6"
          onClick={() => navigate('/explore')}
        >
          {Footer.about}
        </button>
        <div className="footer-dot" />
        <button
          tabIndex="0"
          className="fwn fs18 fs18--ph color-white access-ob access-o6"
          onClick={() => navigate('/contact')}
        >
          {Footer.contact}
        </button>
        <div className="footer-dot" />
        <button
          tabIndex="0"
          className="fwn fs18 fs18--ph color-white access-ob access-o6"
          onClick={() => navigate('/terms')}
        >
          {Footer.terms}
        </button>
        <div className="footer-dot" />
        <button
          tabIndex="0"
          className="fwn fs18 fs18--ph color-white access-ob access-o6"
          onClick={() => navigate('/privacy')}
        >
          {Footer.privacyPolicy}
        </button>
        <div className="footer-dot" />
        &copy;&nbsp;{`${year} MyndFull`}
        <div className="footer-dot footer-dot--no-mrg-right" />
        <a
          href="https://www.linkedin.com/search/results/all/?heroEntityKey=urn%3Ali%3Aorganization%3A89784148&keywords=myndfull&origin=RICH_QUERY_SUGGESTION&position=0&searchId=353b33b8-d1f3-478a-a051-a844ab85140a&sid=*cc"
          aria-label={Footer.visitOurLinkedInPage}
          className="flex-row align-center access-ob access-o6"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={linkedin}
            className="social-media filter-white"
            alt="linkedin link"
            aria-hidden="true"
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
