//hooks
import { useSettings } from '../../../../../context/SettingsProvider';

//language
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//utils
import { img_social, speaker_dark } from '../../../../../assets/icons';

const ExploreSpeakerS1 = ({ currentIndex, ExploreMyndFullFeatures }) => {
  //hooks
  const { width } = useSettings();

  //language
  const { IconAlts } = useLanguageComponents();

  return (
    <>
      {((width > 1280 && currentIndex === 0) ||
        (width <= 1280 && currentIndex === 0)) && (
        <div className="features__container">
          <div className="flex-row align-center mrg-t24">
            <img
              src={speaker_dark}
              alt={IconAlts.iconSpeaker}
              className="filter-theme mrg-r12"
              style={{ height: '38px' }}
            />
            <h4 className="features-title  access-ob access-o6" tabIndex="0">
              {ExploreMyndFullFeatures.becomeASpeaker}
            </h4>
          </div>
          <p
            className="features-description mrg-tb24  access-ob access-o6"
            tabIndex="0"
          >
            {ExploreMyndFullFeatures.becomeASpeakerDescription}
          </p>
        </div>
      )}

      {((width > 1280 && currentIndex === 1) ||
        (width <= 1280 && currentIndex === 1)) && (
        <div className="features__container">
          <div className="flex-row align-center mrg-t24">
            <img
              src={img_social}
              alt={IconAlts.iconCommunity}
              className="filter-theme mrg-r12"
              style={{ height: '28px' }}
            />
            <h4 className="features-title  access-ob access-o6" tabIndex="0">
              {ExploreMyndFullFeatures.speakerBuildCommunity}
            </h4>
          </div>
          <p
            className="features-description mrg-tb24  access-ob access-o6"
            tabIndex="0"
          >
            {ExploreMyndFullFeatures.speakerBuildCommunityDescription}
          </p>
        </div>
      )}
    </>
  );
};

export default ExploreSpeakerS1;
