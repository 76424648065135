import { useState } from 'react';

//hooks
import { useSettings } from '../../context/SettingsProvider';

const InfoButton = ({ handler, positionClass, id }) => {
  const { handleMobileTap } = useSettings();

  //ui
  const [tapHighlightBtn, setTapHighlightBtn] = useState(false);

  return (
    <button
      tabIndex="0"
      className={`info-button access-ob access-o6 ${
        tapHighlightBtn ? 'info-button-highlight' : ''
      } ${positionClass ? positionClass : ''}`}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleMobileTap(
          [() => setTapHighlightBtn(true), () => setTapHighlightBtn(false)],
          [() => handler()]
        );
      }}
      id={id}
    >
      i
    </button>
  );
};

export default InfoButton;
