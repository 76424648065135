import ExploreSpeakerS1 from './ExploreSpeakerS1';

const ExploreFeaturesSpeaker = ({ currentIndex, ExploreMyndFullFeatures }) => {
  return (
    <div className="feature__wrapper" id="features-wrapper">
      <ExploreSpeakerS1
        currentIndex={currentIndex}
        ExploreMyndFullFeatures={ExploreMyndFullFeatures}
      />
    </div>
  );
};

export default ExploreFeaturesSpeaker;
