import { useEffect, useState } from 'react';

//Utility
// import { retryWrapper } from '../../utils/logic/retryFns';
import {
  account_img_default,
  event_img_default,
  banner_img_default,
} from '../../assets/images/index'; // add direct backups

// setImageLoadConfirmation(true); // use on parent container to toggle 'remove' if imageLoadConfirmation is false

const LoadBucketImage = ({
  imagePath,
  imgClassName,
  partialPath,
  backupPath,
  title,
  index,
  croppedImage,
  containerClassName,
  setImageLoadConfirmation,
  imagePreview,
  altAriaText,
  disableFocus,
  accessFocusClass,
  lazyLoad,
}) => {
  //Component State
  const [displayImage, setDisplayImage] = useState(false);
  const [displayImageURL, setDisplayImageURL] = useState();
  const [backupImage, setBackupImage] = useState();

  useEffect(() => {
    loadImage();
  }, [imagePath, croppedImage, imagePreview]);

  function loadImage() {
    if (!displayImageURL) {
      if (croppedImage?.length) {
        setDisplayImageURL(croppedImage);
        setDisplayImage(true);
      } else if (imagePreview) {
        setDisplayImageURL(imagePreview);
      } else {
        setDisplayImageURL(
          `https://storage.googleapis.com/${partialPath}${
            process.env.REACT_APP_ENV === 'development' ? '_development' : ''
          }/${imagePath || backupPath}`
        );
        // setDisplayImageURL(
        //   'https://storage.googleapis.com/myndfull_error_images/123.png'
        // );//use to test fallback image
      }
    }
  }

  useEffect(() => {
    if (displayImageURL && !croppedImage) {
      try {
        let img = new Image();
        img.src = displayImageURL;

        img.onload = (e) => {
          setDisplayImage(true);
          if (setImageLoadConfirmation) {
            setImageLoadConfirmation(true);
          }
        };
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log('DEV LoadBucketImageError on img.load useEffect.');
        }
      }
    }
  }, [displayImageURL]);

  function handleBackupImage() {
    if (backupPath === 'event_img_default.png') {
      setBackupImage(event_img_default);
      if (setImageLoadConfirmation) {
        setImageLoadConfirmation(true);
      }
    }

    if (backupPath === 'account_img_default.png') {
      setBackupImage(account_img_default);
      if (setImageLoadConfirmation) {
        setImageLoadConfirmation(true);
      }
    }

    if (backupPath === 'banner_img_default.png') {
      setBackupImage(banner_img_default);
      if (setImageLoadConfirmation) {
        setImageLoadConfirmation(true);
      }
    }
  }

  return (
    <div
      className={`flex-row ${containerClassName ? containerClassName : ''} ${
        altAriaText ? 'access-ob' : ''
      } ${accessFocusClass ? accessFocusClass : 'access-o6'}`}
      aria-label={altAriaText || ''}
      tabIndex={altAriaText && !disableFocus ? '0' : '-1'}
    >
      {!displayImage && !backupImage && (
        <div className={`${imgClassName} skeleton`} />
      )}

      {!backupImage ? (
        <img
          src={displayImageURL}
          crossOrigin="https://storage.googleapis.com"
          alt={altAriaText ? altAriaText : 'img...'}
          className={displayImage ? `${imgClassName}` : 'remove'}
          title={title}
          onLoad={() => setDisplayImage(true)}
          onError={handleBackupImage}
          tabIndex="-1"
          key={index ? `display-image-${index}` : null}
          loading={lazyLoad ? 'lazy' : undefined}
        />
      ) : (
        <img
          src={backupImage}
          crossOrigin="https://storage.googleapis.com"
          alt={altAriaText ? altAriaText : 'img...'}
          className={displayImage ? `${imgClassName}` : 'remove'}
          title={title}
          onLoad={() => setDisplayImage(true)}
          onError={handleBackupImage}
          tabIndex="-1"
          key={index ? `display-image-${index}` : null}
          loading={lazyLoad ? 'lazy' : undefined}
        />
      )}
    </div>
  );
};

export default LoadBucketImage;
