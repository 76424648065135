import { useEffect } from 'react';

//hooks
import { useSettings } from '../../../context/SettingsProvider';

//language
import useLanguagePublic from '../../../language/features/useLanguagePublic';

//utils
import GetFlag from '../../../utils/images/GetFlag';
import ScrollTo from '../../../utils/UI/ScrollTo';
import { ryan, dan } from '../../../assets/images/team/index';
import { linkedin_color } from '../../../assets/icons';

const PublicContact = () => {
  //hooks
  const { width } = useSettings();

  //language
  const { PublicContact } = useLanguagePublic();

  //initialize
  useEffect(() => {
    ScrollTo({ scrollToTop: true });
  }, []); //auto scroll to top

  return (
    <div className="public-contact__wrapper  public-font bg-cream">
      <div className="flex-column">
        <h1 tabIndex="0" className="access-ob access-o6">
          {PublicContact.myndfullCofounders}
        </h1>
        <div className="public-contact-profiles__wrapper mrg-t24">
          <div className="public-contact-profiles__container ">
            {width > 650 && (
              <img src={ryan} alt="Dr. Ryan Targa" className="profile-img " />
            )}

            <div className="flex-column">
              <h2>Dr. Ryan Targa</h2>
              {width <= 650 && (
                <img src={ryan} alt="Dr. Ryan Targa" className="profile-img" />
              )}
              <p>{PublicContact.ryanBio}</p>
              <div className="flex-row align-center">
                <a
                  href="https://www.linkedin.com/in/ryan-targa/"
                  aria-label={''}
                  className="flex-row fit-content align-center access-ob access-o6 mrg-t24"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin_color} alt="LinkedIn" />
                </a>
                <p
                  className="fs21 fwsb access-ob access-o6 mrg-l24 "
                  tabIndex="0"
                >
                  ryan.targa@myndfull.com
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="public-contact-profiles__wrapper mrg-t24">
          <div className="public-contact-profiles__container">
            <div className="public-contact-profiles__container">
              {width > 650 && (
                <img src={dan} alt="Daniel Targa" className="profile-img " />
              )}

              <div className="flex-column">
                <h2>Dan Targa</h2>
                {width <= 650 && (
                  <img src={dan} alt="Daniel Targa" className="profile-img" />
                )}
                <p>{PublicContact.danBioPart1}</p>
                <p>{PublicContact.danBioPart2}</p>
                <p className="fs21 fwsb access-ob access-o6" tabIndex="0">
                  dan.targa@myndfull.com
                </p>
              </div>
            </div>
          </div>
        </div>

        {width <= 1300 && (
          <>
            <div className="divider mrg-t24 mrg-lr24" />

            <div className="flex-column  mrg-lr24">
              <h1 tabIndex="0" className="access-ob access-o6">
                {PublicContact.contactEmails}
              </h1>
              <div className="flex-column">
                <h4> {PublicContact.supportInquiries}</h4>
                <p>support@myndfull.com</p>

                <h4>{PublicContact.privacy}</h4>
                <p>privacy@myndfull.com</p>

                <h4>{PublicContact.copyrightClaims}</h4>
                <p>copyright@myndfull.com</p>
              </div>

              <div className="flex-row align-center mrg-t48 mrg-auto-right">
                <h5 className="fwn fs18">{PublicContact.location}</h5>
                <div style={{ height: '18px' }} className="mrg-l12">
                  {GetFlag('CA')}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {width > 1300 && (
        <div className="flex-column text-right">
          <h1 tabIndex="0" className="access-ob access-o6">
            Contact Emails
          </h1>

          <div className="flex-column">
            <h4> {PublicContact.supportInquiries}</h4>
            <p>support@myndfull.com</p>

            <h4> {PublicContact.privacy}</h4>
            <p>privacy@myndfull.com</p>

            <h4>{PublicContact.copyrightClaims}</h4>
            <p>copyright@myndfull.com</p>
            <div className="divider full-width mrg-t24" />

            <div className="flex-row align-center mrg-auto-left mrg-t24">
              <h5 className="fwn fs18">{PublicContact.location}</h5>
              <div style={{ height: '18px' }} className="mrg-l12">
                {GetFlag('CA')}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PublicContact;
