//hooks
import { useSettings } from '../../../context/SettingsProvider';

//language
import useLanguageComponents from '../../../language/useLanguageComponents';
import useLanguagePublic from '../../../language/features/useLanguagePublic';
import useLanguageImages from '../../../language/useLanguageImages';
//utils
import { eventcard_accessibility } from '../../../assets/icons';

const ExploreAccessibility = () => {
  //hooks
  const { width } = useSettings();

  //language
  const { IconAlts } = useLanguageComponents();
  const { ExploreMyndFullAccessibility } = useLanguagePublic();
  const { AccessibilityCard } = useLanguageImages();

  return (
    <div className="explore-myndfull-translations explore-base-text">
      <div className="explore-myndfull-accessibility-title ">
        <img
          src={eventcard_accessibility}
          className="mrg-r12"
          style={{ height: '54px' }}
          alt={IconAlts.iconAccessibility}
        />
        <h2
          className="text-center text-left--ph  access-ob access-o6"
          tabIndex="0"
        >
          {ExploreMyndFullAccessibility.title}
        </h2>
      </div>

      {width > 900 ? (
        <div className="flex-row flex-center mrg-t36 mrg-t24--ph">
          <div className="accessibility-card-demo">
            <img
              src={AccessibilityCard}
              alt={ExploreMyndFullAccessibility.eventCardAlt}
            />
          </div>

          <p className="mrg-l24 access-ob access-o6" tabIndex="0">
            {ExploreMyndFullAccessibility.description}
          </p>
        </div>
      ) : (
        <div className="flex-column flex-center mrg-t24 ">
          <p className="mrg-l24 access-ob access-o6" tabIndex="0">
            {ExploreMyndFullAccessibility.description}
          </p>
          <div className="accessibility-card-demo mrg-t24">
            <img
              src={AccessibilityCard}
              alt={ExploreMyndFullAccessibility.eventCardAlt}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ExploreAccessibility;
