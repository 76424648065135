import { useEffect, useState } from 'react';

//hooks
import { useSettings } from '../../../../../context/SettingsProvider';

//language
import useLanguageData from '../../../../../language/useLanguageData';
import useLanguageSettings from '../../../../../language/features/useLanguageSettings';

//component
import DemoTagsList from './DemoComponents/DemoTagsList';
import InfoButton from '../../../../../components/InfoButton/InfoButton';

//utils
import {
  building,
  date,
  direction,
  distance,
  folder,
  globe,
  social,
} from '../../../../../assets/icons';

const DemoSettingsMobile = ({
  view,
  toolbarActiveHighlight,
  handleToolTip,
  searchDirection,
  setSearchDirection,
  searchFormat,
  setSearchFormat,
  searchCategory,
  setSearchCategory,
  demoBigTags,
  demoTinyTags,
  demoTinyTagGroups,
  demoCommunityFollowing,
  demoCommunityFollowingGroups,
  selectedMyEvents,
  selectedBigTags,
  selectedTinyTags,
  selectedTinyTagGroupIds,
  selectedFollowingIds,
  selectedFollowingGroupIds,
  selectAllBTBtn,
  removeSelectedBTs,
  addSelectedBTs,
  removeAllSelectedBTs,
  setSelectAllBTBtn,
  selectAllMyEventsBtn,
  setSelectedMyEvents,
  setSelectAllMyEventsBtn,
  selectAllTTBtn,
  removeSelectedTinyTags,
  addSelectedTinyTags,
  removeSelectedTinyTagGroupIds,
  addSelectedTinyTagGroupIds,
  removeAllSelectedTinyTagGroupIds,
  setSelectAllTTBtn,
  removeAllSelectedTinyTags,
  selectAllAccountsFollowingBtn,
  setSelectAllAccountsFollowingBtn,
  removeAllSelectedAccountsFollowingIds,
  removeSelectedAccountsFollowingId,
  addSelectedAccountsFollowingId,
  removeSelectedAccountsFollowingGroupIds,
  addSelectedAccountsFollowingGroupIds,
  removeAllSelectedAccountsFollowingGroupIds,
}) => {
  //hooks
  const { handleMobileTap } = useSettings();

  //lanugage
  const { SettingsEventsLanguage } = useLanguageSettings();
  const { BigTagIcon, TinyTagIcon } = useLanguageData();

  //Component state
  const [openSearchSettings, setOpenSearchSettings] = useState(false);
  const [tapHighlightSettingsBorder, setTapHighlightSettingsBorder] =
    useState(false);

  //Component variables
  let searchDirectionExplained;

  //Component functions
  useEffect(() => {
    setOpenSearchSettings(false);
  }, [view]);

  function handleSettingsMenuClick(e) {
    handleMobileTap(
      [
        () => setTapHighlightSettingsBorder(true),
        () => setTapHighlightSettingsBorder(false),
      ],
      [() => handleSearchOptionsMenu(e)]
    );
  }

  function handleSearchOptionsMenu(e) {
    if (e.target.id) {
      setOpenSearchSettings(!openSearchSettings);
    }
  }

  //JSX conditional
  if (searchDirection) {
    if (searchDirection === 'ascending') {
      if (searchCategory === 'date') {
        searchDirectionExplained = `${SettingsEventsLanguage.earliest}`;
      }

      if (searchCategory === 'distance') {
        searchDirectionExplained = `${SettingsEventsLanguage.closest}`;
      }
    }

    if (searchDirection === 'descending') {
      if (searchCategory === 'date') {
        searchDirectionExplained = `${SettingsEventsLanguage.latest}`;
      }
      if (searchCategory === 'distance') {
        searchDirectionExplained = `${SettingsEventsLanguage.furthest}`;
      }
    }
  }

  let tinyTagsAria = `${
    SettingsEventsLanguage.ariaTinyTagsSelectionSummaryFolders +
    selectedTinyTagGroupIds?.length
  }. ${
    SettingsEventsLanguage.ariaTinyTagsSelectionSummaryTags +
    selectedTinyTags?.length
  }.`; //needed for period/pauses

  let communityAria = `${
    SettingsEventsLanguage.ariaCommunitySelectionSummaryFolders +
    selectedFollowingGroupIds?.length
  }. ${
    SettingsEventsLanguage.ariaCommunitySelectionSummary +
    selectedFollowingIds?.length
  }.`; //needed for period/pauses

  return (
    <div
      role="button"
      className={`filters-mobile .demo-filters-mobile-width filters-mobile-border br--standard no-select access-ob access-o6 ${
        tapHighlightSettingsBorder ? 'filters-mobile-border--tap' : ''
      }`}
      onClick={(e) => handleSettingsMenuClick(e)}
      id="view-selections--mobile"
      tabIndex="0"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSettingsMenuClick(e);
        }
      }}
      aria-expanded={openSearchSettings}
      aria-label={`${SettingsEventsLanguage.ariaEventFilters} 
    ${
      view === 'myevents' && selectedMyEvents.includes('saved')
        ? SettingsEventsLanguage.savedEvents
        : ''
    }
    ${
      view === 'myevents' && selectedMyEvents.includes('registered')
        ? SettingsEventsLanguage.registeredEvents
        : ''
    }

   ${
     view === 'myevents' && selectedMyEvents.includes('participationEvents')
       ? SettingsEventsLanguage.participationEvents
       : ''
   }
    ${
      view === 'bigtags'
        ? SettingsEventsLanguage.ariaBigTagsSelectionSummary +
          selectedBigTags?.length
        : ''
    }
    ${view === 'tinytags' ? tinyTagsAria : ''}
    ${view === 'community' ? communityAria : ''}
    `}
    >
      <div
        className="top-btn-bar fs18 fwsb full-width pad-6 disable-select"
        id="top-btn-bar"
      >
        <div className="flex-row flex-center">
          <div
            className={`arrow mrg-l12 ${
              !openSearchSettings ? 'rotate-ccw90' : ''
            }`}
            id="arrow"
          />
          <p id="search-options-title" className="mrg-l12">
            {!openSearchSettings && SettingsEventsLanguage.filters}
          </p>
        </div>
        <div className="icon-summary">
          {view === 'myevents' && (
            <p className="fs21 fwb color-theme mrg-r12">
              {`${selectedMyEvents?.length} / 3`}
            </p>
          )}
          {view === 'bigtags' && (
            <>
              <p className="fs21 fwb color-theme" id="tag-sum">
                {selectedBigTags?.length}
                {selectedBigTags?.length < demoBigTags?.length &&
                  `/${demoBigTags?.length}`}
              </p>
              <img
                id="bT-icon"
                src={BigTagIcon}
                title={SettingsEventsLanguage.numberOfTagsSelected}
                alt={SettingsEventsLanguage.numberOfTagsSelected}
                className="bT-icon svg mrg-lr12"
                style={{ height: '21px' }}
              />
            </>
          )}
          {view === 'tinytags' && (
            <>
              <p className="fs21 fwb color-theme">
                {selectedTinyTagGroupIds?.length}
                {selectedTinyTagGroupIds?.length < demoTinyTagGroups?.length &&
                  `/${demoTinyTagGroups?.length}`}
              </p>
              <img
                id="tG-icon"
                src={folder}
                className="tG-icon filter-theme mrg-lr12 svg"
                title={SettingsEventsLanguage.numberOfTagGroupsSelected}
                alt={SettingsEventsLanguage.numberOfTagGroupsSelected}
              />
              <p className="fs21 fwb color-theme">
                {selectedTinyTags?.length}
                {selectedTinyTags?.length < demoTinyTags?.length &&
                  `/${demoTinyTags?.length}`}
              </p>
              <img
                id="tT-icon"
                src={TinyTagIcon}
                title={SettingsEventsLanguage.numberOfTagsSelected}
                alt={SettingsEventsLanguage.numberOfTagsSelected}
                className="tT-icon svg mrg-lr12"
                style={{ height: '28px' }}
              />
            </>
          )}
          {view === 'community' && (
            <>
              <p className="fs21 fwb color-theme">
                {selectedFollowingGroupIds?.length}
                {selectedFollowingGroupIds?.length <
                  demoCommunityFollowingGroups?.length &&
                  `/${demoCommunityFollowingGroups?.length}`}
              </p>
              <img
                id="tG-icon"
                src={folder}
                className="tG-icon filter-theme mrg-lr12 svg"
                title={SettingsEventsLanguage.numberOfCommunityGroupsSelected}
                alt={SettingsEventsLanguage.numberOfCommunityGroupsSelected}
              />
              <p className="fs21 fwb color-theme">
                {selectedFollowingIds?.length}
                {selectedFollowingIds?.length <
                  demoCommunityFollowing?.length &&
                  `/${demoCommunityFollowing?.length}`}
              </p>
              <img
                id="tT-icon"
                src={social}
                title={SettingsEventsLanguage.numberOfCommunityAccountsSelected}
                alt={SettingsEventsLanguage.numberOfCommunityAccountsSelected}
                className="tT-icon svg"
              />
            </>
          )}
        </div>
      </div>
      {openSearchSettings && (
        <div className="mrg-lr16 mrg-b16" id="filtersMobile-container">
          <div className={`pos-rel`}>
            {view !== 'myevents' && (
              <>
                <h2 className="fs16 mrg-b12 color-black fit-content">
                  {searchFormat === 'inPerson'
                    ? SettingsEventsLanguage.searchFormatInPerson
                    : SettingsEventsLanguage.searchFormatOnline}
                </h2>
                <ul className="filter-category mrg-b12 fit-content access-ob access-o6">
                  <li>
                    <button
                      className="access-ob access-o6"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSearchFormat('inPerson');
                      }}
                      title={SettingsEventsLanguage.searchFormatInPerson}
                      aria-pressed={searchFormat === 'inPerson'}
                    >
                      <img
                        src={building}
                        alt={SettingsEventsLanguage.searchFormatInPerson}
                        className={`svg ${
                          searchFormat === 'inPerson' ? 'selected' : ''
                        }`}
                      />
                    </button>
                  </li>
                  <li>
                    <button
                      className="access-ob access-o6"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSearchFormat('online');
                        setSearchCategory('date');
                      }}
                      title={SettingsEventsLanguage.searchFormatOnline}
                      aria-pressed={searchFormat === 'online'}
                    >
                      <img
                        src={globe}
                        alt={SettingsEventsLanguage.searchFormatOnline}
                        className={`svg ${
                          searchFormat === 'online' ? 'selected' : ''
                        }`}
                      />
                    </button>
                  </li>
                </ul>
              </>
            )}

            <h2 className="fs16 mrg-b12 color-black fit-content">
              {searchDirectionExplained}
            </h2>
            {toolbarActiveHighlight === 'sorting' && (
              <InfoButton
                positionClass={'info-button-demo-filters-tags'}
                handler={() => handleToolTip('sorting')}
                id={'info-btn-sorting'}
              />
            )}
            <ul className="pos-rel filter-category mrg-b12 fit-content access-ob access-o6">
              <li>
                <button
                  className="access-ob access-o6"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSearchDirection('ascending');
                  }}
                  title={SettingsEventsLanguage.searchAscending}
                  aria-pressed={searchDirection === 'ascending'}
                  id="filter-ascending"
                >
                  <img
                    src={direction}
                    alt={SettingsEventsLanguage.ascending}
                    className={`svg ${
                      searchDirection === 'ascending' ? 'selected' : ''
                    } reverse`}
                  />
                </button>
              </li>
              <li>
                <button
                  className="access-ob access-o6"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSearchDirection('descending');
                  }}
                  title={SettingsEventsLanguage.searchDescending}
                  aria-pressed={searchDirection === 'descending'}
                >
                  <img
                    src={direction}
                    alt={SettingsEventsLanguage.descending}
                    className={`svg ${
                      searchDirection === 'descending' ? 'selected' : ''
                    }`}
                  />
                </button>
              </li>
            </ul>
            <h2 className="fs16 mrg-b12 color-black fit-content">
              {searchCategory === 'date'
                ? SettingsEventsLanguage.date
                : SettingsEventsLanguage.distance}
            </h2>
            <ul className="filter-category mrg-b12 fit-content access-ob access-o6">
              <li>
                <button
                  className="access-ob access-o6"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSearchCategory('date');
                  }}
                  title={SettingsEventsLanguage.searchDate}
                  aria-pressed={searchCategory === 'date'}
                >
                  <img
                    src={date}
                    alt={SettingsEventsLanguage.searchDate}
                    className={`svg ${
                      searchCategory === 'date' ? 'selected' : ''
                    }`}
                  />
                </button>
              </li>
              {searchFormat === 'inPerson' && (
                <li>
                  <button
                    className="access-ob access-o6"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSearchCategory('distance');
                    }}
                    title={SettingsEventsLanguage.searchDistance}
                    aria-pressed={searchCategory === 'distance'}
                  >
                    <img
                      src={distance}
                      alt={SettingsEventsLanguage.searchDistance}
                      className={`svg ${
                        searchCategory === 'distance' ? 'selected' : ''
                      }`}
                    />
                  </button>
                </li>
              )}
            </ul>
          </div>

          <div className={`pos-rel`}>
            {toolbarActiveHighlight === 'tags' && (
              <InfoButton
                positionClass={'info-button-demo-filters-tags'}
                handler={() => handleToolTip('tags')}
                id={'info-btn-tags'}
              />
            )}
            <DemoTagsList
              view={view}
              ////
              demoBigTags={demoBigTags}
              demoTinyTags={demoTinyTags}
              demoTinyTagGroups={demoTinyTagGroups}
              demoCommunityFollowing={demoCommunityFollowing}
              demoCommunityFollowingGroups={demoCommunityFollowingGroups}
              //BT
              selectedBigTags={selectedBigTags}
              selectAllBTBtn={selectAllBTBtn}
              removeSelectedBTs={removeSelectedBTs}
              addSelectedBTs={addSelectedBTs}
              removeAllSelectedBTs={removeAllSelectedBTs}
              setSelectAllBTBtn={setSelectAllBTBtn}
              //My Events
              selectedMyEvents={selectedMyEvents}
              selectAllMyEventsBtn={selectAllMyEventsBtn}
              setSelectedMyEvents={setSelectedMyEvents}
              setSelectAllMyEventsBtn={setSelectAllMyEventsBtn}
              // //Tiny Tags
              selectedTinyTags={selectedTinyTags}
              selectedTinyTagGroupIds={selectedTinyTagGroupIds}
              selectAllTTBtn={selectAllTTBtn}
              removeSelectedTinyTags={removeSelectedTinyTags}
              addSelectedTinyTags={addSelectedTinyTags}
              setSelectAllTTBtn={setSelectAllTTBtn}
              removeAllSelectedTinyTags={removeAllSelectedTinyTags}
              removeSelectedTinyTagGroupIds={removeSelectedTinyTagGroupIds}
              addSelectedTinyTagGroupIds={addSelectedTinyTagGroupIds}
              removeAllSelectedTinyTagGroupIds={
                removeAllSelectedTinyTagGroupIds
              }
              //community
              selectedFollowingIds={selectedFollowingIds}
              selectedFollowingGroupIds={selectedFollowingGroupIds}
              selectAllAccountsFollowingBtn={selectAllAccountsFollowingBtn}
              setSelectAllAccountsFollowingBtn={
                setSelectAllAccountsFollowingBtn
              }
              removeAllSelectedAccountsFollowingIds={
                removeAllSelectedAccountsFollowingIds
              }
              removeSelectedAccountsFollowingId={
                removeSelectedAccountsFollowingId
              }
              addSelectedAccountsFollowingId={addSelectedAccountsFollowingId}
              removeSelectedAccountsFollowingGroupIds={
                removeSelectedAccountsFollowingGroupIds
              }
              addSelectedAccountsFollowingGroupIds={
                addSelectedAccountsFollowingGroupIds
              }
              removeAllSelectedAccountsFollowingGroupIds={
                removeAllSelectedAccountsFollowingGroupIds
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DemoSettingsMobile;
