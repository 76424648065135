//language
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//utils
import {
  compass,
  exchange,
  img_find_speaker,
  img_messages2,
  img_plus,
  lock_locked,
  preview,
  save,
} from '../../../../../assets/icons';

const ExploreOrganizeS1 = ({ currentIndex, ExploreMyndFullFeatures }) => {
  //language
  const { IconAlts } = useLanguageComponents();

  return (
    <>
      {currentIndex === 0 && (
        <div className="features__container">
          <div className="flex-row align-center mrg-t24">
            <img
              src={img_plus}
              alt="+"
              className="filter-theme mrg-r12"
              style={{ height: '28px' }}
            />
            <h4 className="features-title access-ob access-o6" tabIndex="0">
              {ExploreMyndFullFeatures.createEvents}
            </h4>
          </div>
          <div className="features-description mrg-tb24">
            <p className="access-ob access-o6" tabIndex="0">
              {ExploreMyndFullFeatures.createEventsDescription}
            </p>

            <ul className="features-checklist">
              <li className="align-center mrg-t24">
                <img
                  src={compass}
                  alt={IconAlts.iconChecklist}
                  className="filter-theme"
                  style={{ height: '28px' }}
                />
                <p className="access-ob access-o6" tabIndex="0">
                  {ExploreMyndFullFeatures.createEvents1}
                </p>
              </li>

              <li className="align-center mrg-t24">
                <img
                  src={save}
                  alt={IconAlts.iconSave}
                  className="filter-theme"
                  style={{ height: '22px' }}
                />
                <p className="access-ob access-o6" tabIndex="0">
                  {ExploreMyndFullFeatures.createEvents2}
                </p>
              </li>
              <li className="align-center mrg-t24">
                <img
                  src={preview}
                  alt={IconAlts.iconView}
                  className="filter-theme"
                  style={{ height: '18px' }}
                />
                <p className="access-ob access-o6" tabIndex="0">
                  {ExploreMyndFullFeatures.createEvents3}
                </p>
              </li>
              <li className="align-center mrg-t24">
                <img
                  src={img_messages2}
                  alt={IconAlts.iconEmail}
                  className="filter-theme"
                  style={{ height: '20px' }}
                />
                <p className="access-ob access-o6" tabIndex="0">
                  {ExploreMyndFullFeatures.createEvents4}
                </p>
              </li>
              <li className="align-center mrg-t24">
                <img
                  src={img_find_speaker}
                  alt={IconAlts.iconSpeaker}
                  className="mrg-r12"
                />
                <p className="access-ob access-o6" tabIndex="0">
                  {ExploreMyndFullFeatures.createEvents5}
                </p>
              </li>
              <li className="align-center mrg-t24">
                <img
                  src={lock_locked}
                  alt={IconAlts.iconLock}
                  className="filter-theme"
                  style={{ height: '24px' }}
                />
                <p className="access-ob access-o6" tabIndex="0">
                  {ExploreMyndFullFeatures.createEvents6}
                </p>
              </li>
              <li className="align-center mrg-t24">
                <img
                  src={exchange}
                  alt={IconAlts.iconArrow}
                  className="filter-theme"
                  style={{ height: '24px' }}
                />
                <p className="access-ob access-o6" tabIndex="0">
                  {ExploreMyndFullFeatures.createEvents7}
                </p>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};
export default ExploreOrganizeS1;
