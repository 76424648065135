//language
import useLanguagePublic from '../../../language/features/useLanguagePublic';

//utils
import { logo_cbg } from '../../../assets/images/explore';

const ExploreMyndFullBanner = () => {
  const { ExploreMyndFullBanner } = useLanguagePublic();
  //language
  return (
    <div className="explore-banner bg-light-purp">
      <div className="explore-banner-heading explore-base-text">
        <img
          src={logo_cbg}
          alt="MyndFull Logo"
          className="explore-logo access-ob access-o6"
          tabIndex="0"
        />
        <h3 tabIndex="0" className="access-ob access-o6">
          {ExploreMyndFullBanner.banner}
        </h3>
      </div>
    </div>
  );
};

export default ExploreMyndFullBanner;
