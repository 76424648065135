import { useState, useEffect } from 'react';

//Hooks
import { useGenericModal } from '../../../context/GenericModalProvider';
import { useSettings } from '../../../context/SettingsProvider';

//utils
import { cancel } from '../../../assets/icons';

const InfoMobileModal = ({
  focusId,
  headingText,
  text,
  customWrapper,
  displayElement,
}) => {
  //Hooks
  const { handleCloseModal, setCloseModalFocusId } = useGenericModal();
  const { handleMobileTap } = useSettings();

  //UI state
  const [tapHighlightButton, setTapHighlightBtn] = useState();

  //Initialize functions
  useEffect(() => {
    if (focusId) {
      setCloseModalFocusId(`${focusId}`);
    }
  }, [focusId]); //set id for focus when modal closes

  useEffect(() => {
    const floatModalElHeader = document.getElementById(
      `info-mobile-modal-header`
    );
    if (floatModalElHeader) {
      floatModalElHeader.focus();
    }
  }, []);

  return (
    <div
      className={`pos-rel float-modal info-modal access-ow access-o12 ${
        customWrapper ? customWrapper : ''
      }`}
      tabIndex="0"
      id={'float-modal'}
    >
      <div className="flex-row full-width">
        <h1
          className="pad-t12 mrg-auto-lr access-ob access-o6"
          tabIndex="0"
          id="info-mobile-modal-header"
        >
          {headingText}
        </h1>

        <button
          className="info-close-btn access-ob access-o6"
          onClick={() =>
            handleMobileTap(
              [() => setTapHighlightBtn(true), () => setTapHighlightBtn(false)],
              [() => handleCloseModal()]
            )
          }
        >
          <img
            src={cancel}
            className={`${
              tapHighlightButton ? 'filter-red' : 'filter-lightgray'
            }`}
            alt="X"
          />
        </button>
      </div>

      <div tabIndex="0" className="fs18 mrg-t24 mrg-b12 access-ob access-o6">
        {displayElement}
        {text}
      </div>
    </div>
  );
};

export default InfoMobileModal;
