import { useSettings } from '../../context/SettingsProvider';

const useLanguagePublic = () => {
  const { contentLanguage } = useSettings();

  const ExploreHeading = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        exploreHeading1: 'Your Platform for Academic',
        exploreHeading2: '& Educational Events',
        signUp: 'Sign Up',
        demoImgMobile: `An image displaying the MyndFull web app on a mobile device.`,
        demoImgDesktop: `An image displaying the MyndFull web app on a dekstop device.`,
        navigateToRegister: 'Click to navigate to the registration page.',
        demoImagesAria:
          'An image of MyndFull on a mobile device and desktop device displaying the find events view.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        exploreHeading1: 'Votre plateforme pour les événements',
        exploreHeading2: 'académiques et éducatifs',
        signUp: "S'inscrire",
        demoImgMobile:
          "Une image affichant l'application web MyndFull sur un appareil mobile.",
        demoImgDesktop:
          "Une image affichant l'application web MyndFull sur un appareil de bureau.",
        navigateToRegister: `Cliquez pour accéder à la page d'inscription.`,
        demoImagesAria: `Une image de MyndFull sur un appareil mobile et un appareil de bureau affichant la vue de recherche d'événements.`,
      };
    }
    return translation;
  };

  const ExploreMyndFullIntroduction = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        whatIsMyndFull: 'What is MyndFull?',
        part1: `MyndFull is your central platform for discovering, hosting, and
        participating in academic and educational events. We bring together the
        best tools and resources to connect educators and learners.`,
        part2: `Currently, these events are dispersed across educational institution websites, email listservs, social media posts, and general-purpose event platforms that lack features tailored for educators.`,
        part3: `This should've changed a long time ago \u2014 now it has with MyndFull.`,
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        whatIsMyndFull: "Qu'est-ce que MyndFull ?",
        part1:
          'MyndFull est votre plateforme centrale pour découvrir, organiser et participer à des événements académiques et éducatifs. Nous réunissons les meilleurs outils et ressources pour connecter les éducateurs et les apprenants.',
        part2: `Actuellement, ces événements sont dispersés sur les sites web d'institutions éducatives, les listes de diffusion par courriel, les publications sur les réseaux sociaux et les plateformes d'événements généralistes qui ne sont pas adaptées aux besoins des éducateurs.`,
        part3: `Cela aurait dû changer depuis longtemps \u2014 c'est maintenant chose faite avec MyndFull.`,
      };
    }
    return translation;
  };

  const ExploreMyndFullCommunity = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        text: `The absence of a dedicated educational event ecosystem holds us back \u2014 not just from sharing great ideas, but from building great communities. When people come together to exchange knowledge and perspectives, they create connections that are meaningful, lasting, and inspiring.`,
        imageAlt:
          'Some friends gathered together to emphasize the communal bonds that can be formed through education.',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        text: `L'absence d'un écosystème dédié aux événements éducatifs nous freine — non seulement dans le partage de bonnes idées, mais aussi dans la construction de communautés solides. Lorsque des personnes se rassemblent pour échanger des connaissances et des perspectives, elles créent des liens qui sont significatifs, durables et inspirants.`,
        imageAlt: `Des amis rassemblés pour illustrer les liens communautaires que l'éducation peut favoriser.`,
      };
    }
    return translation;
  };

  const ExploreMyndFullBanner = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        banner:
          'Education is a great way to connect with others and grow as a person.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        banner: `L'éducation est un excellent moyen de créer des liens et de s'épanouir.`,
      };
    }
    return translation;
  };

  const ExploreMyndFullFeatures = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        title: `Let's dive into some features!`,
        findingEvents: 'Finding Events',
        findingEventsAria: 'Select for viewing finding events features below.',
        organizing: 'Organizing',
        organizingAria: 'Select for viewing organize features below.',
        speaking: 'Speaking',
        speakingAria: 'Select for viewing speaker features below.',

        //Find Events Toolbar
        myndFullEventsToolbar: `MyndFull's Events Toolbar`,
        myndFullEventsToolbarDescription: `Through MyndFull's unique integration of account preferences and filters, it has never been easier to find the events that interest you the most.`,
        interactToLearnMore: 'Interact to learn more!',
        views: 'Views',
        toolTipViews: 'Switch through Views to change your Events List.',
        sorting: 'Filters & Sorting',
        toolTipSorting:
          'Filter and sort your events. In-Person events and Virtual events both includes hybrid events.',
        tags: 'Tags',
        toolTipTags:
          'Add tags to your account and use them to generate your searches. Toggle your tags to quickly filter events and group Tiny Tags and Community Tags for easier management.',
        myEvents: 'My Events',
        bigTagEvents: 'Big Tag Events',
        tinyTagEvents: 'Tiny Tag Events',
        communityEvents: 'Community Events',
        sortDirection: 'Sort Direction',
        sortByDate: 'Sort by Date',
        sortByDistance: 'Sort by Distance',
        inPersonEventsHybrids: 'In-Person Events / Hybrids',
        virtualEventsHybrids: 'Virtual Events / Hybrids',

        //Find Events Event Type
        inPersonEvents: 'In-Person Events',
        inPersonEventsDescription: `In-Person Event searches are based on your account's distance filter.`,
        virtualEvents: 'Virtual Events',
        virtualEventsDescription: `Ignore physical distance by searching for Virtual Events among your selected countries.`,

        //Find Events Tags
        bigTags: 'Big Tags',
        bigTagsDescription:
          'Scholarly categories called Big Tags allow you to easily browse through your general interests.',
        tinyTags: 'Tiny Tags',
        tinyTagsDescription: `Tiny Tags provide you with the ability to match events that align with your more finely-tuned interests. Never miss an event from your favourite topic again!`,
        //Find Events Community & Email
        community: 'Community',
        communityDescription: `Find events with your favourite speakers and event organizers by following them. Your Community Follows are not publicly visible to other users because your community network is your own.`,
        email: 'Events That Come to You',
        emailDescription:
          'Set up personalized email notifications to receive summaries of events matching your interests—bringing the events to you!',

        //Organize S1 Create Events
        createEvents: 'Create Events',
        createEventsDescription: `MyndFull's event creation tool packs powerful features to build academic and educational events while remaining simple and easy to use.`,
        createEvents1: 'Build your event on one easy-to-navigate screen',
        createEvents2: 'Restore, Save and Load Event Drafts',
        createEvents3: 'Preview your event before publishing or saving changes',
        createEvents4:
          'Request profile data from your Event Participants directly through our built-in email service',
        createEvents5:
          'Find speakers with the right expertise for your event using our Find Speakers tool',
        createEvents6:
          'Keep event details private from non-registered attendees',
        createEvents7:
          'Integrate MyndFull Translations into your event to overcome language barriers',

        //Organize S2 Find Speakers
        findSpeakers: 'Find Speakers',
        findSpeakersDescription: `Load your event data or customize search preferences in our Find Speakers tool to generate a list of matching Speakers. Request a Speaker's contact information then invite them to your event.`,
        findSpeakersSearchTool: 'Find Speakers Search Tool',

        //Organize S3 Community/Premium
        buildCommunity: 'Build the Community',
        buildCommunityDescription:
          'Users can follow you and find your events in their Community Events feed.',
        premiumOrganizers: 'Premium Organizers',
        premiumOrganizersDescription:
          'Organizers can subscribe to our premium service to unlock additional tools and scale to their needs. Stay tuned for more details!',

        //speakers
        becomeASpeaker: 'Become a Speaker',
        becomeASpeakerDescription:
          'Create a Speaker Profile and allow Organizers to find your profile based on your location, language and tags. Your contact info remains private until you accept a Contact Request.',
        speakerBuildCommunity: 'Build the Community',
        speakerBuildCommunityDescription:
          'Enable Follow Mode so Attendees can follow you and see the events  that you participate in as a Speaker.',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        title: `Explorons quelques fonctionnalités !`,
        findingEvents: `Trouver des événements`,
        findingEventsAria: `Sélectionner pour voir les fonctionnalités de recherche d'événements ci-dessous.`,
        organizing: `Organisation`,
        organizingAria: `Sélectionner pour voir les fonctionnalités d'organisation ci-dessous.`,
        speaking: `Interventions`,
        speakingAria: `Sélectionner pour voir les fonctionnalités pour les conférenciers ci-dessous.`,
        myndFullEventsToolbar: `Barre d'outils MyndFull pour les événements`,
        myndFullEventsToolbarDescription: `Grâce à l'intégration unique des préférences de compte et des filtres de MyndFull, il n'a jamais été aussi facile de trouver les événements qui vous intéressent le plus.`,
        interactToLearnMore: `Interagissez pour en savoir plus !`,
        views: `Vues`,
        toolTipViews: `Changez de vue pour modifier votre liste d'événements.`,
        sorting: `Filtres et tri`,
        toolTipSorting: `Filtrez et triez vos événements. Les événements en présentiel et virtuels incluent aussi les événements hybrides.`,
        tags: `Balises`,
        toolTipTags: `Ajoutez des balises à votre compte et utilisez-les pour générer vos recherches. Activez ou désactivez vos balises pour filtrer rapidement les événements, et regroupez les petites balises et les balises communautaires pour une gestion plus facile.`,
        myEvents: 'Mes événements',
        bigTagEvents: 'Événements Grandes Balises',
        tinyTagEvents: 'Événements Petites Balises',
        communityEvents: 'Événements Communautaires',
        sortDirection: 'Ordre de tri',
        sortByDate: 'Trier par date',
        sortByDistance: 'Trier par distance',
        inPersonEventsHybrids: 'Événements en présentiel / hybrides',
        virtualEventsHybrids: 'Événements virtuels / hybrides',

        ////
        inPersonEvents: `Événements en présentiel`,
        inPersonEventsDescription: `Les recherches d'événements en présentiel sont basées sur le filtre de distance de votre compte.`,
        virtualEvents: `Événements virtuels`,
        virtualEventsDescription: `Ignorez la distance physique en recherchant des événements virtuels dans les pays que vous avez sélectionnés.`,
        bigTags: `Grandes balises`,
        bigTagsDescription: `Des catégories académiques appelées grandes balises vous permettent d'explorer facilement vos intérêts généraux.`,
        tinyTags: `Petites balises`,
        tinyTagsDescription: `Les petites balises vous permettent de trouver des événements qui correspondent à vos intérêts plus spécifiques. Ne manquez plus jamais un événement sur votre sujet préféré !`,
        community: `Communauté`,
        communityDescription: `Trouvez des événements animés par vos conférenciers et organisateurs préférés en les suivant. Vos abonnés communautaires ne sont pas visibles publiquement par les autres utilisateurs, car votre réseau communautaire est le vôtre.`,
        email: `Des événements qui viennent à vous`,
        emailDescription: `Configurez des notifications par courriel personnalisées pour recevoir des résumés des événements correspondant à vos intérêts — les événements viennent à vous !`,
        createEvents: `Créer des événements`,
        createEventsDescription: `L'outil de création d'événements de MyndFull contient des fonctionnalités puissantes pour créer des événements académiques et éducatifs tout en restant simple et facile à utiliser.`,
        createEvents1: `Créez votre événement sur un seul écran facile à naviguer`,
        createEvents2: `Restaurez, enregistrez et chargez des brouillons d'événements`,
        createEvents3: `Aperçu de votre événement avant publication ou enregistrement`,
        createEvents4: `Demandez les données de profil de vos participants directement via notre service de courriel intégré`,
        createEvents5: `Trouvez des conférenciers ayant l'expertise requise pour votre événement avec notre outil Trouver des conférenciers`,
        createEvents6: `Gardez les détails de l'événement privés pour les participants non inscrits`,
        createEvents7: `Intégrez les Traductions MyndFull à votre événement pour surmonter les barrières linguistiques`,
        findSpeakers: `Trouver des conférenciers`,
        findSpeakersDescription: `Chargez les données de votre événement ou personnalisez vos critères de recherche dans notre outil Trouver des conférenciers pour générer une liste de profils correspondants. Demandez les coordonnées d'un orateur, puis invitez-le à votre événement.`,
        findSpeakersSearchTool: `Outil de recherche de conférenciers`,
        buildCommunity: `Créer une communauté`,
        buildCommunityDescription: `Les utilisateurs peuvent vous suivre et découvrir vos événements dans leur fil d'actualité communautaire.`,
        premiumOrganizers: `Organisateurs premium`,
        premiumOrganizersDescription: `Les organisateurs peuvent s'abonner à notre service premium pour débloquer des outils supplémentaires et répondre à leurs besoins. Détails à venir !`,
        becomeASpeaker: `Devenir conférencier`,
        becomeASpeakerDescription: `Créez un profil de conférencier pour permettre aux organisateurs de vous trouver selon votre emplacement, votre langue et vos balises. Vos coordonnées restent privées jusqu'à ce que vous acceptiez une demande de contact.`,
        speakerBuildCommunity: `Créer une communauté`,
        speakerBuildCommunityDescription: `Activez le mode suivi pour que les participants puissent vous suivre et voir les événements auxquels vous participez en tant que conférencier.`,
      };
    }
    return translation;
  };

  const ExploreMyndFullTranslations = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        title: 'MyndFull Translations',
        translationsImageAlt:
          'A woman standing at a podium and speaking into a microphone.',
        part1: `MyndFull Translations enhances formal, in-person presentations by delivering real-time audio and text translations to audiences.`,
        part2: `Organizers can host a room on MyndFull with their Room Code Subscription, allowing attendees to join seamlessly via their personal device's web browser. Attendees are not required to download an app or set up an account \u2014 joining a room only requires a few easy clicks.`,
        part3: `Upon joining, attendees select their preferred language to receive audio and text translations of the presenter's speech, offering an experience akin to a "United Nations" style of communication. With transparent pricing and a self-serve setup, MyndFull Translations empowers presenters to effectively communicate with diverse audiences, fostering greater understanding and engagement.`,
        tutorial: 'Tutorial',
        comingSoon: '(Coming Soon)',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        title: 'Traductions MyndFull',
        translationsImageAlt:
          'Une femme debout à un podium parlant dans un microphone.',
        part1:
          'Les Traductions MyndFull améliorent les présentations formelles en personne en fournissant des traductions audio et textuelles en temps réel au public.',
        part2:
          "Les organisateurs peuvent créer une salle sur MyndFull avec leur abonnement à un code de salle, permettant aux participants de se joindre facilement via le navigateur web de leur appareil personnel. Aucun téléchargement d'application ou création de compte n'est requis — quelques clics suffisent pour rejoindre une salle.",
        part3:
          'Une fois connectés, les participants choisissent leur langue préférée pour recevoir des traductions audio et textuelles du discours du présentateur, offrant une expérience similaire à celle des communications multilingues à la « Nations Unies ». Avec une tarification transparente et une configuration autonome, les Traductions MyndFull permettent aux présentateurs de communiquer efficacement avec des publics diversifiés, favorisant ainsi une meilleure compréhension et participation.',
        tutorial: 'Tutoriel',
        comingSoon: '(Bientôt disponible)',
      };
    }
    return translation;
  };

  const ExploreMyndFullAccessibility = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        title: 'A Commitment To Accessibility',
        description: `At MyndFull, we're dedicated to making academic and educational events more accessible. From the outset, our web app has featured accessible navigation to accommodate all users. We encourage our users to prioritize accessibility in their events as well. That's why our event creation process includes options to specify available accessibility services. As shown on the Event Card, an Accessibility Icon indicates to viewers that the event has accessibility services available, with further details available on the event page. Often, these crucial details are overlooked in event promotions, and we believe it's time for a change.`,
        eventCardAlt:
          'An event card which has an accessibility icon at the bottom, indicating that the event has accessibility services available.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        title: `Un engagement envers l'accessibilité`,
        description: `Chez MyndFull, nous nous engageons à rendre les événements académiques et éducatifs plus accessibles. Dès le début, notre application web a été conçue avec une navigation accessible pour répondre aux besoins de tous les utilisateurs. Nous encourageons également nos utilisateurs à prioriser l'accessibilité dans leurs événements. C'est pourquoi notre processus de création d'événement inclut des options pour spécifier les services d'accessibilité offerts. Comme indiqué sur la carte de l'événement, une icône d'accessibilité signale aux visiteurs que des services sont disponibles, avec plus de détails accessibles sur la page de l'événement. Trop souvent, ces détails cruciaux sont oubliés dans la promotion des événements — il est temps que ça change.`,
        eventCardAlt: `Une carte d'événement affichant une icône d'accessibilité en bas, indiquant que des services d'accessibilité sont disponibles.`,
      };
    }
    return translation;
  };

  const ExploreMyndFullMore = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        title: 'MyndFull Launch',
        myndFullTranslations: 'MyndFull Translations',
        myndFullEvents: 'MyndFull Events',
        myndFullLaunchAria:
          'MyndFull Translations launch 2025. MyndFull Events Platform launch late 2026.',
        part1:
          'MyndFull is currently in its pre-launch phase. Our plan is to launch MyndFull Translations in 2025 and MyndFull Events in late 2026.',
        part2:
          'For MyndFull Events, our platform will be free for attendees and speakers. Organizers can also sign up and create events at no cost. To support our platform and access enhanced tools and functionalities, organizers will have the option to subscribe to our premium services.',
        part3:
          'To support our work, you can sign up for pre-launch access and help assist our development. Also consider signing up for our Newsletter or following us on LinkedIn for the latest updates, promotions and opportunities.',
        signUp: 'Sign Up',
        joinNewsletter: 'Join our Newsletter',
        joinNewsletterDescription:
          'Receive the latest updates, promotions and opportunities.',
        followLinkedIn: 'Follow us on LinkedIn',
        navigateToRegister: 'Click to navigate to the registration page.',
        submit: 'Submit',
        submitAria: 'Submit email for Newsletter subscription.',
        ariaSubmitUnavailable:
          'Submit Button. Submission requirements are not met.',
        submissionSuccessful: 'Submission Successful',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        title: `Lancement de MyndFull`,
        myndFullTranslations: `Traductions MyndFull`,
        myndFullEvents: `Événements MyndFull`,
        myndFullLaunchAria: `Lancement de Traductions MyndFull en 2025. Lancement de la plateforme Événements MyndFull fin 2026.`,
        part1: `MyndFull est actuellement en phase de pré-lancement. Notre objectif est de lancer MyndFull Translations en 2025 et MyndFull Events à la fin de 2026.`,
        part2: `Pour MyndFull Events, notre plateforme sera gratuite pour les participants et les conférenciers. Les organisateurs pourront également s'inscrire et créer des événements sans frais. Pour soutenir notre plateforme et accéder à des outils et fonctionnalités avancés, les organisateurs auront la possibilité de s'abonner à nos services premium.`,
        part3: `Pour soutenir notre travail, vous pouvez vous inscrire pour un accès en avant-première et ainsi contribuer à notre développement. Pensez aussi à vous abonner à notre infolettre ou à nous suivre sur LinkedIn pour recevoir les dernières nouvelles, promotions et opportunités.`,
        signUp: `S'inscrire`,
        joinNewsletter: `S'abonner à l'infolettre`,
        joinNewsletterDescription: `Recevez les dernières nouvelles, promotions et opportunités.`,
        followLinkedIn: `Suivez-nous sur LinkedIn`,
        navigateToRegister: `Cliquez pour accéder à la page d'inscription.`,
        submit: 'Soumettre',
        submitAria: `Soumettre un e-mail pour l'abonnement à la newsletter.`,
        ariaSubmitUnavailable:
          'Bouton Soumettre. Les conditions de soumission ne sont pas remplies.',
        submissionSuccessful: 'Soumission réussie',
      };
    }
    return translation;
  };

  const PublicContact = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        myndfullCofounders: `MyndFull's Co-founders`,
        ryanBio: `Ryan is a passionate educator, historian, and entrepreneur. He spent over a decade working in education and academia, culminating in the completion of his dissertation in 2022. Since then, he has transitioned into full-stack web development, aiming to bring innovation to the education sector. At MyndFull, Ryan leads the platform's vision, design, and technical implementation.`,
        danBioPart1: `Dan is an experienced financial professional and entrepreneur. Before teaming up with his brother Ryan to start MyndFull, Dan was a Director in the Mergers & Acquisitions group at National Bank Financial Markets in Toronto. Over 11 years at the firm, Dan developed an extensive financial markets background advising senior executives on a variety of complex strategic and corporate finance transactions. This experience contributes to Dan's understanding of crucial matters concerning executive leadership and corporate governance.`,
        danBioPart2: `As Co-Founder and CFO, Dan oversees MyndFull's finances but is also deeply involved with business strategy, operations, product development, and matters of legal and regulatory compliance.`,
        contactEmails: 'Contact Emails',
        supportInquiries: 'Support Inquiries',
        privacy: 'Privacy',
        copyrightClaims: 'Copyright Claims',
        location: 'Based in Toronto, Canada',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        myndfullCofounders: 'Les cofondateurs de MyndFull',
        ryanBio: `Ryan est un éducateur, historien et entrepreneur passionné. Il a travaillé pendant plus de dix ans dans le domaine de l'éducation et du milieu universitaire, ce qui l'a mené à la complétion de sa thèse en 2022. Depuis, il s'est tourné vers le développement web full-stack, avec l'objectif d'innover dans le secteur de l'éducation. Chez MyndFull, Ryan dirige la vision de la plateforme, sa conception et sa mise en œuvre technique.`,
        danBioPart1: `Dan est un professionnel chevronné de la finance et un entrepreneur. Avant de cofonder MyndFull avec son frère Ryan, Dan était directeur au sein du groupe Fusions et acquisitions chez Marchés financiers Banque Nationale à Toronto. Pendant ses 11 années dans l'entreprise, Dan a acquis une solide expérience des marchés financiers, conseillant des cadres dirigeants sur de nombreuses transactions complexes en stratégie d'entreprise et en finance corporative. Cette expérience alimente sa compréhension des enjeux cruciaux liés au leadership exécutif et à la gouvernance d'entreprise.`,
        danBioPart2: `En tant que cofondateur et directeur financier, Dan supervise les finances de MyndFull, tout en étant fortement impliqué dans la stratégie d'entreprise, les opérations, le développement de produits, ainsi que les questions juridiques et réglementaires.`,
        contactEmails: `Adresses courriel de contact`,
        supportInquiries: `Demandes de soutien`,
        privacy: `Vie privée`,
        copyrightClaims: `Réclamations de droits d'auteur`,
        location: `Basé à Toronto, Canada`,
      };
    }
    return translation;
  };
  return {
    ExploreHeading: ExploreHeading(),
    ExploreMyndFullIntroduction: ExploreMyndFullIntroduction(),
    ExploreMyndFullCommunity: ExploreMyndFullCommunity(),
    ExploreMyndFullBanner: ExploreMyndFullBanner(),
    ExploreMyndFullFeatures: ExploreMyndFullFeatures(),
    ExploreMyndFullTranslations: ExploreMyndFullTranslations(),
    ExploreMyndFullAccessibility: ExploreMyndFullAccessibility(),
    ExploreMyndFullMore: ExploreMyndFullMore(),
    PublicContact: PublicContact(),
  };
};

export default useLanguagePublic;
