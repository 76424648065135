//hooks
import { useSettings } from '../../../context/SettingsProvider';
//language
import useLanguagePublic from '../../../language/features/useLanguagePublic';
import useLanguageComponents from '../../../language/useLanguageComponents';

//utils
import { exchange } from '../../../assets/icons';
import { image_podium_speaker } from '../../../assets/images/explore';

const ExploreTranslations = () => {
  //hooks
  const { width } = useSettings();
  //language
  const { IconAlts } = useLanguageComponents();
  const { ExploreMyndFullTranslations } = useLanguagePublic();

  return (
    <div className="explore-myndfull-translations bg-light-blue">
      <div className="br--standard">
        {width <= 900 && (
          <div className="flex-row flex-center mrg-b24 text-center">
            <img
              className=" mrg-r12 filter-darkgray"
              src={exchange}
              alt={IconAlts.iconTranslations}
            />
            <h2 className="access-ob access-o6" tabIndex="0">
              {ExploreMyndFullTranslations.title}
            </h2>
          </div>
        )}
        <div className="flex-row">
          <div className="flex-column explore-myndfull-translations-img__container">
            <img
              src={image_podium_speaker}
              alt={ExploreMyndFullTranslations.translationsImageAlt}
              tabIndex="0"
              className="explore-myndfull-translations-img access-ob access-o6"
            />
            <button
              className="explore-button explore-button--translations mrg-t24 flex-center mrg-auto-lr access-ob access-o6"
              tabIndex="0"
            >
              <div className="flex-column">
                <span>{ExploreMyndFullTranslations.tutorial}</span>
                <span className="fs18 mrg-t6">
                  {ExploreMyndFullTranslations.comingSoon}
                </span>
              </div>
            </button>
          </div>

          {width > 900 && (
            <div className="flex-column mrg-l48">
              <div className="flex-row flex-center  mrg-b24">
                <img
                  className="mrg-r24 filter-darkgray"
                  src={exchange}
                  alt={IconAlts.iconTranslations}
                />
                <h2 className="access-ob access-o6" tabIndex="0">
                  {ExploreMyndFullTranslations.title}
                </h2>
              </div>
              <div className="explore-base-text">
                <p className="access-ob access-o6" tabIndex="0">
                  {ExploreMyndFullTranslations.part1}
                </p>

                <p className="mrg-t24 access-ob access-o6" tabIndex="0">
                  {ExploreMyndFullTranslations.part2}
                </p>

                <p className="mrg-t24 access-ob access-o6" tabIndex="0">
                  {ExploreMyndFullTranslations.part3}
                </p>
              </div>
            </div>
          )}
        </div>

        {width <= 900 && (
          <div className="flex-column explore-base-text mrg-t24">
            <p className="access-ob access-o6" tabIndex="0">
              {ExploreMyndFullTranslations.part1}
            </p>

            <p className="mrg-t24 access-ob access-o6" tabIndex="0">
              {ExploreMyndFullTranslations.part2}
            </p>

            <p className="mrg-t24 access-ob access-o6" tabIndex="0">
              {ExploreMyndFullTranslations.part3}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExploreTranslations;
