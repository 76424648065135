import { organize, search_dark, speaker_dark } from '../../../../assets/icons';
import useLanguageComponents from '../../../../language/useLanguageComponents';

const ExploreFeaturesButtons = ({
  exploreFeature,
  changeExploreTopic,
  ExploreMyndFullFeatures,
}) => {
  //language
  const { IconAlts } = useLanguageComponents();

  return (
    <ul className="explore-features-btns">
      <li>
        <button
          className={`explore-features-button access-ob access-o6 ${
            exploreFeature === 'findEvents'
              ? 'explore-features-button--active'
              : ''
          }`}
          onClick={() => {
            changeExploreTopic('findEvents');
          }}
          aria-label={ExploreMyndFullFeatures.findingEventsAria}
          aria-pressed={exploreFeature === 'findEvents'}
        >
          <img src={search_dark} alt={IconAlts.iconSearch} aria-hidden={true} />
          <p>{ExploreMyndFullFeatures.findingEvents}</p>
        </button>
      </li>

      <li>
        <button
          className={`explore-features-button access-ob access-o6 ${
            exploreFeature === 'organize'
              ? 'explore-features-button--active'
              : ''
          }`}
          onClick={() => changeExploreTopic('organize')}
          aria-pressed={exploreFeature === 'organize'}
          aria-label={ExploreMyndFullFeatures.organizingAria}
        >
          <img src={organize} alt={IconAlts.iconOrganize} aria-hidden={true} />
          <p>{ExploreMyndFullFeatures.organizing}</p>
        </button>
      </li>

      <li>
        <button
          className={`explore-features-button access-ob access-o6 ${
            exploreFeature === 'speaker'
              ? 'explore-features-button--active'
              : ''
          }`}
          onClick={() => changeExploreTopic('speaker')}
          aria-pressed={exploreFeature === 'speaker'}
          aria-label={ExploreMyndFullFeatures.speakingAria}
        >
          <img
            src={speaker_dark}
            alt={ExploreMyndFullFeatures.iconSpeaker}
            aria-hidden={true}
          />
          <p>{ExploreMyndFullFeatures.speaking}</p>
        </button>
      </li>
    </ul>
  );
};

export default ExploreFeaturesButtons;
