import { useEffect, useState } from 'react';
//hooks
import { useSettings } from '../../../../../context/SettingsProvider';
import useReCaptcha from '../../../../../hooks/useReCaptcha';
import { useApiStatus } from '../../../../../context/ApiStatusProvider';

//language
import useLanguageRegistration from '../../../../../language/features/useLanguageRegistration';
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import useLanguageData from '../../../../../language/useLanguageData';
import useLanguageImages from '../../../../../language/useLanguageImages';

//components
import CheckBoxBoolean from '../../../../../components/CheckBoxes/CheckBoxBoolean';
import NavigationArrows from '../../../../../components/NavigationArrows/NavigationArrows';
import SelectDropdown from '../../../../../components/SelectDropdown/SelectDropdown';
import EmailInput from '../../../../../components/TextArea/EmailInput';
import PhoneInput from '../../../../../components/TextArea/PhoneInput';
import TextArea from '../../../../../components/TextArea/TextArea';

//utils
import ScrollTo from '../../../../../utils/UI/ScrollTo';
import { checkmark_green } from '../../../../../assets/icons';
import { axiosLimited } from '../../../../../axios/axios';
import { image_corner_decoration } from '../../../../../assets/images';

const PrelaunchRegistration = () => {
  //hooks
  const { width } = useSettings();
  const { handleError } = useApiStatus();
  const { handleReCaptchaVerify } = useReCaptcha();

  //language
  const { Prelaunch } = useLanguageRegistration();
  const { LanguageAppSettingsOpts } = useLanguageData();
  const { Generic, IconAlts } = useLanguageComponents();
  const { Thanks } = useLanguageImages();

  //state page 1
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [prefix, setPrefix] = useState('');
  const [pronouns, setPronouns] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [contentLanguage, setContentLanguage] = useState('');
  const [affiliation, setAffiliation] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [phoneNumberSaveValid, setPhoneNumberSaveValid] = useState(false);

  //state page 2
  const [preferredContact, setPreferredContact] = useState('');
  const [attendingEvents, setAttendingEvents] = useState(false);
  const [speakingAtEvents, setSpeakingAtEvents] = useState(false);
  const [organizingEvents, setOrganizingEvents] = useState(false);
  const [translations, setTranslations] = useState(false);
  const [allOfTheAbove, setAllOfTheAbove] = useState(false);
  const [perspective, setPerspective] = useState('');
  const [hearOfUs, setHearOfUs] = useState('');

  //page navigation
  const [continueBtnCheck, setContinueBtnCheck] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  //ui
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      email &&
      validEmail &&
      firstName &&
      lastName &&
      (phoneNumberSaveValid || (!countryCode && !phoneNumber)) &&
      contentLanguage
    ) {
      setContinueBtnCheck(true);
    } else {
      setContinueBtnCheck(false);
    }
    /*eslint-disable-next-line*/
  }, [
    email,
    validEmail,
    firstName,
    lastName,
    contentLanguage,
    countryCode,
    phoneNumber,
    phoneNumberSaveValid,
    currentPage,
  ]);
  //UI
  useEffect(() => {
    if (currentPage === 0) {
      ScrollTo({
        focusId: 'titlePageOne',
        scrollToTop: true,
        behavior: 'smooth',
      });
    }

    if (currentPage === 1) {
      ScrollTo({
        focusId: 'titlePageTwo',
        scrollToTop: true,
        behavior: 'smooth',
      });
    }
  }, [currentPage]); //scroll

  //API
  async function earlyAccessRegistration() {
    if (!isLoading) {
      setIsLoading(true);
      const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
      const newEmail = email;
      const emailValidation = EMAIL_REGEX.test(newEmail);

      if (!emailValidation || !newEmail) {
        setIsLoading(false);
        return handleError({
          message: 'invalidData',
          id: Date.now(),
          origin: 'PrelaunchRegistration.js/earlyAccessRegistration',
        });
      }

      const recaptchaVerified = await handleReCaptchaVerify(
        'EARLY_ACCESS_REGISTRATION',
        false
      );
      if (recaptchaVerified !== true) {
        setIsLoading(false);
        return handleError({
          message: 'tryAgain',
          id: Date.now(),
          origin: 'PrelaunchRegistration.js/earlyAccessRegistration',
        });
      }

      try {
        const formData = new FormData();

        //page 1
        formData.append('email', newEmail);
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('contentLanguage', contentLanguage);

        if (prefix?.length > 0) formData.append('prefix', prefix);
        if (pronouns?.length > 0) formData.append('pronouns', pronouns);
        if (affiliation?.length > 0)
          formData.append('affiliation', affiliation);

        if (phoneNumberSaveValid) {
          formData.append('phoneNumber', phoneNumber);
          formData.append('countryCode', countryCode);
        }

        //page 2
        if (preferredContact?.length > 0)
          formData.append('preferredContact', preferredContact);

        const interestTopics = {
          attendingEvents,
          speakingAtEvents,
          organizingEvents,
          translations,
          allOfTheAbove,
        };
        formData.append('interestTopics', JSON.stringify(interestTopics));

        if (perspective?.length > 0)
          formData.append('perspective', perspective);

        if (hearOfUs?.length > 0) formData.append('hearOfUs', hearOfUs);

        await axiosLimited.post(
          `/main/register/earlyAccessRegistration`,
          formData
        );
        setCurrentPage(2);
        setIsLoading(false);
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log('error', error);
        }
        setIsLoading(false);
        handleError({
          ...error,
          id: Date.now(),
          origin: 'PrelaunchRegistration.js/earlyAccessRegistration',
        });
      }
    }
  }

  return (
    <div className="flex-column full-height">
      {currentPage === 0 ? (
        <>
          <h1
            className="content-heading-styled text-gray mrg-auto-lr access-ob access-o6"
            tabIndex="0"
            id="titlePageOne"
          >
            {Prelaunch.earlyAccessRegistration}
          </h1>
          <p className="fs18 mrg-t24 flex-center">
            {Prelaunch.earlyAccessDescription}
          </p>
          <div className="h24" />

          <label
            aria-label={Prelaunch.ariaAccount}
            className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
            id="accountLabel"
            tabIndex="0"
          >
            <div className="flex-row align-center mrg-auto-right">
              <p>{Prelaunch.accountLabel}</p>
            </div>

            <div className="flex-row mrg-auto-right--ph mrg-t12--ph">
              <p className="fs14 mrg-auto-left">{Generic.required}</p>
              {email && validEmail && (
                <img
                  src={checkmark_green}
                  alt={IconAlts.iconCheckmark}
                  className="mrg-l6"
                />
              )}
            </div>
          </label>

          <EmailInput
            asterisk={true}
            handleInput={setEmail}
            id={'email'}
            emailCollectionToCheck={'EarlyAccessRegistration'}
            setSaveValid={setValidEmail}
            checkRanAfterBlurToggle={true}
            allowSaveIfMatchingLoadValue={false}
            customWrapper={'mrg-t24'}
            loadValue={email}
          />
          <div className="h36" />
          <label
            aria-label={Prelaunch.ariaName}
            className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
            id="nameLabel"
            tabIndex="0"
          >
            <div className="flex-row align-center mrg-auto-right">
              <p>{Prelaunch.nameLabel}</p>
            </div>

            <div className="flex-row mrg-auto-right--ph mrg-t12--ph">
              <p className="fs14">{Generic.someRequired}</p>
              {firstName && lastName && (
                <img
                  src={checkmark_green}
                  alt={IconAlts.iconCheckmark}
                  className="mrg-l6"
                />
              )}
            </div>
          </label>
          <div className="flex-row flex-column--ph mrg-t24">
            <TextArea
              customWidth={'108px'}
              disableEnter={true}
              handleChange={setPrefix}
              hideWordCount={true}
              id={'prefix'}
              labelText={Prelaunch.prefix}
              limit={8}
              loadTrigger={true}
              loadValue={prefix}
              customWrapper={width >= 480 ? 'mrg-r24 mrg-b24' : 'mrg-b24'}
            />
            <TextArea
              customWidth={width > 480 ? '240px' : '100%'}
              customWrapper={'mrg-b24'}
              disableEnter={true}
              handleChange={setPronouns}
              hideWordCount={true}
              id={'pronouns'}
              labelText={Prelaunch.pronouns}
              limit={20}
              loadTrigger={true}
              loadValue={pronouns}
              tabIndex="0"
            />
          </div>

          <TextArea
            asterisk={true}
            disableEnter={true}
            handleChange={setFirstName}
            hideWordCount={true}
            id={'firstName'}
            labelText={Prelaunch.firstName}
            limit={50}
            loadTrigger={true}
            loadValue={firstName}
            onBlurHandlerOff={true}
          />
          <TextArea
            asterisk={true}
            disableEnter={true}
            handleChange={setLastName}
            hideWordCount={true}
            id={'lastName'}
            labelText={Prelaunch.lastName}
            limit={50}
            loadTrigger={true}
            loadValue={lastName}
            onBlurHandlerOff={true}
            customWrapper={'mrg-t24'}
          />
          <div className="h36" />

          <label
            aria-label={Prelaunch.ariaAppSettings}
            className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
            id="appSettingsLabel"
            tabIndex="0"
          >
            <div className="flex-row align-center mrg-auto-right">
              <p>{Prelaunch.appSettingsLabel}</p>
            </div>

            <div className="flex-row mrg-auto-right--ph mrg-t12--ph">
              <p className="fs14">{Generic.allRequired}</p>
              {contentLanguage && (
                <img
                  src={checkmark_green}
                  alt={IconAlts.iconCheckmark}
                  className="mrg-l6"
                />
              )}
            </div>
          </label>
          <div className="h24" />

          <SelectDropdown
            asterisk={true}
            defaultKey={contentLanguage ? contentLanguage : null}
            handleSelection={setContentLanguage}
            id={'contentLanguage'}
            internalLabel={Prelaunch.appLanguage}
            list={LanguageAppSettingsOpts}
            fullWidth={true}
          />
          <div className="h24" />

          <label
            aria-label={Prelaunch.ariaContact}
            className="content-heading-styled text-gray"
            id="otherLabel"
            tabIndex="0"
          >
            {Prelaunch.other}
          </label>
          <TextArea
            disableEnter={true}
            handleChange={setAffiliation}
            hideWordCount={true}
            id={'Affiliation'}
            labelText={'Affiliation'}
            limit={100}
            loadTrigger={true}
            loadValue={affiliation}
            onBlurHandlerOff={true}
            customWrapper={'mrg-t24'}
          />
          <div className="h24" />
          <PhoneInput
            id={'accountPhoneInput'}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            setPhoneDataValid={setPhoneNumberSaveValid}
          />
          <div className="h36" />
        </>
      ) : currentPage === 1 ? (
        <>
          <h1
            className="content-heading-styled text-gray mrg-auto-lr access-ob access-o6"
            tabIndex="0"
            id="titlePageTwo"
          >
            {Prelaunch.voluntaryQuestionnaire}
          </h1>
          <p className="fs18 mrg-t24 flex-center">
            {Prelaunch.voluntaryQuestionnaireDescription}
          </p>
          <div className="h24" />

          <label
            aria-label={Prelaunch.ariaAccount}
            className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
            id="preferredContactLabel"
            tabIndex="0"
          >
            <p>{Prelaunch.preferredMethodContact}:</p>
          </label>

          <TextArea
            disableEnter={true}
            handleChange={setPreferredContact}
            hideWordCount={true}
            id={'preferredContact'}
            labelText={Prelaunch.preferredContactEx}
            limit={100}
            loadTrigger={true}
            loadValue={preferredContact}
            onBlurHandlerOff={true}
            customWrapper={'mrg-t24'}
          />
          <div className="h36" />
          <label
            aria-label={Prelaunch.ariaAccount}
            className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
            id="interestLabel"
            tabIndex="0"
          >
            <p>{Prelaunch.selectInterest}</p>
          </label>

          <div className="full-width flex-column mrg-t12">
            <CheckBoxBoolean
              loadValue={attendingEvents}
              label={Prelaunch.attendingEvents}
              handler={setAttendingEvents}
              customWrapper={'mrg-t12 fwn fs18'}
              id={`attendingEvents`}
              loadTrigger={true}
            />
            <CheckBoxBoolean
              loadValue={speakingAtEvents}
              label={Prelaunch.speakingAtEvents}
              handler={setSpeakingAtEvents}
              customWrapper={'mrg-t24 fwn fs18'}
              id={`speakingEvents`}
              loadTrigger={true}
            />
            <CheckBoxBoolean
              loadTrigger={true}
              loadValue={organizingEvents}
              label={Prelaunch.organizingEvents}
              handler={setOrganizingEvents}
              customWrapper={'mrg-t24 fwn fs18'}
              id={`organizingEvents`}
            />
            <CheckBoxBoolean
              loadTrigger={true}
              loadValue={translations}
              label={Prelaunch.translations}
              handler={setTranslations}
              customWrapper={'mrg-t24 fwn fs18'}
              id={`translations`}
            />
            <CheckBoxBoolean
              loadTrigger={true}
              loadValue={allOfTheAbove}
              label={Prelaunch.allOfTheAbove}
              handler={setAllOfTheAbove}
              customWrapper={'mrg-t24 fwn fs18'}
              id={`allOfTheAboveEventsEvents`}
            />
          </div>

          <div className="h36" />
          <label
            aria-label={Prelaunch.ariaAccount}
            className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
            id="perspectiveLabel"
            tabIndex="0"
          >
            <p>{Prelaunch.perspectiveOffered}</p>
          </label>
          <TextArea
            disableEnter={true}
            handleChange={setPerspective}
            hideWordCount={true}
            id={'perspective'}
            labelText={
              width > 480
                ? Prelaunch.perspectiveOfferedExExtended
                : Prelaunch.perspectiveOfferedEx
            }
            limit={100}
            loadTrigger={true}
            loadValue={perspective}
            onBlurHandlerOff={true}
            customWrapper={'mrg-t24'}
          />
          <div className="h36" />

          <div className="h36" />
          <label
            aria-label={Prelaunch.ariaAccount}
            className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
            id="hearOfUsLabel"
            tabIndex="0"
          >
            <p>{Prelaunch.hearOfUs}</p>
          </label>
          <TextArea
            disableEnter={true}
            handleChange={setHearOfUs}
            hideWordCount={true}
            id={'hearOfUs'}
            labelText={Prelaunch.hearOfUsEx}
            limit={100}
            loadTrigger={true}
            loadValue={hearOfUs}
            onBlurHandlerOff={true}
            customWrapper={'mrg-t24'}
          />
          <div className="h36" />
        </>
      ) : (
        currentPage === 2 && (
          <>
            <img
              src={image_corner_decoration}
              alt={IconAlts.iconCornerDecoration}
              aria-hidden={true}
              className="gratitude-card-decoration opacity-07"
              style={{ bottom: 0, left: 0 }}
            />
            <img
              src={image_corner_decoration}
              alt={IconAlts.iconCornerDecoration}
              aria-hidden={true}
              className="gratitude-card-decoration reverse opacity-07"
              style={{ bottom: 0, right: 0 }}
            />
            <img
              src={image_corner_decoration}
              alt={IconAlts.iconCornerDecoration}
              aria-hidden={true}
              className="gratitude-card-decoration reverse-vertical opacity-07"
              style={{ top: 0, left: 0 }}
            />
            <img
              src={image_corner_decoration}
              alt={IconAlts.iconCornerDecoration}
              aria-hidden={true}
              className="gratitude-card-decoration mirror opacity-07"
              style={{ top: 0, right: 0 }}
            />
            <div className="text-center align-center flex-center flex-column">
              <h1
                className="content-heading-styled fs24 ff-inter text-gray mrg-t48 mrg-auto-lr access-ob access-o6"
                tabIndex="0"
                id="titlePageThree"
              >
                {Prelaunch.registrationComplete}
              </h1>
              <div className="full-width gratitude-card-banner mrg-t24" />

              <p
                className="ff-inter fs21 fwsb color-darkgray flex-center access-ob access-o6"
                tabIndex="0"
              >
                {Prelaunch.gratitude}
              </p>
            </div>
            <div className="flex-grow flex-column flex-center mrg-auto-tb">
              <img
                src={Thanks}
                alt={Prelaunch.thanksAlt}
                style={{ height: '150px' }}
              />
            </div>
          </>
        )
      )}
      {currentPage < 2 && (
        <NavigationArrows
          firstArrow={currentPage === 0 ? true : false}
          continueBtnCheck={continueBtnCheck}
          handlePageDecrease={
            currentPage > 0
              ? () => setCurrentPage((prev) => prev - 1)
              : () => function () {}
          }
          handlePageIncrease={
            currentPage < 2
              ? () => setCurrentPage((prev) => prev + 1)
              : () => function () {}
          }
          customWrapper={'full-width'}
          finalContinue={currentPage === 1}
          customFinalContinue={'mrg-auto-left'}
          HOFunction={earlyAccessRegistration}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default PrelaunchRegistration;
