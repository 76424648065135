//hooks
import { useSettings } from '../../../../../context/SettingsProvider';

//language
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//utils
import { img_messages2, img_social } from '../../../../../assets/icons';

const FindEventsCommunityAndEmail = ({
  currentIndex,
  ExploreMyndFullFeatures,
}) => {
  //hooks
  const { width } = useSettings();

  //language
  const { IconAlts } = useLanguageComponents();

  return (
    <>
      {((width > 1280 && currentIndex === 3) ||
        (width <= 1280 && currentIndex === 5)) && (
        <div className="features__container">
          <div className="flex-row align-center mrg-t24">
            <img
              src={img_social}
              alt={IconAlts.iconCommunity}
              className="filter-theme mrg-r12"
              style={{ height: '28px' }}
            />
            <h4 className="features-title access-ob access-o6" tabIndex="0">
              {ExploreMyndFullFeatures.community}
            </h4>
          </div>
          <p
            className="features-description mrg-tb24 access-ob access-o6"
            tabIndex="0"
          >
            {ExploreMyndFullFeatures.communityDescription}
          </p>
        </div>
      )}

      {((width > 1280 && currentIndex === 3) ||
        (width <= 1280 && currentIndex === 6)) && (
        <div className="features__container">
          <div className="pos-rel flex-row align-center mrg-t24">
            <img
              src={img_messages2}
              alt={IconAlts.iconEmail}
              className="filter-theme mrg-r12"
              style={{ height: '28px' }}
            />
            <h4 className="features-title access-ob access-o6" tabIndex="0">
              {ExploreMyndFullFeatures.email}
            </h4>
          </div>
          <p
            className="features-description mrg-tb24 access-ob access-o6"
            tabIndex="0"
          >
            {ExploreMyndFullFeatures.emailDescription}
          </p>
        </div>
      )}
    </>
  );
};

export default FindEventsCommunityAndEmail;
